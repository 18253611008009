import React from 'react';
import styled from 'styled-components/macro'
import { Typo, Icon, Flex } from '..';
import { media } from '../../utils/media';

const FlexStyled = styled(Flex)`
  flex-direction: column;
  max-width:100%;
  
  ${media.greaterThan('tablet')} {
    max-width:300px;
    align-items:flex-start;
  }
`

const InfoPointCard = ({ infoBlock, iconColor }) => {
  return (
    <FlexStyled gap='10' >
      <Icon.Bolt color={iconColor}></Icon.Bolt>
      {infoBlock.title && <Typo.ContentSmallHeader $marginBottom='10px' $textAlign='left'>{infoBlock.title}</Typo.ContentSmallHeader>}
      {infoBlock.text && <Typo.Text >{infoBlock.text}</Typo.Text>}
    </FlexStyled>
  );
};

export default InfoPointCard;
