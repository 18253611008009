import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components'
import { Typo, Button } from '../../../../ui';
import { media } from '../../../../utils/media';
import { selectStoreName } from '../../globalSlice';
import { selectAllItems } from '../../cartSlice';
import CheckoutCart from './CheckoutCart';
import CheckoutInfo from './CheckoutInfo';
import MessageContainer from './MessageContainer';

const ContainerWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  ${media.lessThan('tablet')} {
    flex-direction: column;
  }
`;

const CheckoutDataContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const storeName = useSelector(selectStoreName);
  const cartItems = useSelector(selectAllItems);

  const goToStore = useCallback(() => {
    navigate(`/store/${storeName}`);
  }, [navigate, storeName]);


  return (
    <ContainerWrapper>
      {cartItems.length ?
        <>
          <CheckoutInfo cartItems={cartItems} />
          <CheckoutCart />
        </>
        :
        <MessageContainer>
          <Typo.TextTitle>{t('webshop.cart.cartIsEmpty')}</Typo.TextTitle>
          <Button $variant='primary' onClick={goToStore}>{t('webshop.cart.backToShopping')}</Button>
        </MessageContainer>}
    </ContainerWrapper>
  );
};

export default CheckoutDataContainer;

