import React from 'react';
import styled from 'styled-components/macro'
import Symbols from '../Symbols';
import theme from '../theme';

export const listOfIcons = [
  // { name: 'BarChart', url: '/static/media/Bar-Chart.3491f9c4.svg' },
  // { name: 'Books', url: '/static/media/Books-2.74c00b54.svg' },
  // { name: 'BulletedList', url: '/static/media/Bulleted-List.svg' },
  // { name: 'Calendar', url: '/static/media/Calendar-4.f44f500f.svg' },
  // { name: 'Certificate', url: '/static/media/Certificate-icon.svg' },
  // { name: 'Claps', url: '/static/media/Claps.svg' },
  // { name: 'ClockForward', url: '/static/media/Clock-Forward.svg' },
  // { name: 'Diploma', url: '/static/media/Diploma-2.svg' },
  // { name: 'Download', url: '/static/media/Download.svg' },
  // { name: 'FilePictures', url: '/static/media/File-Pictures.svg' },
  // { name: 'Lections', url: '/static/media/Lections.svg' },
  // { name: 'LetterOpen', url: '/static/media/Letter-Open.svg' },
  // { name: 'Notepad', url: '/static/media/Notepad.svg' },
  // { name: 'Online', url: '/static/media/Online-icons.svg' },
  // { name: 'Professor', url: '/static/media/Professor.svg' },
  // { name: 'Profile', url: '/static/media/Profile.svg' },
  // { name: 'Puzzle', url: '/static/media/Puzzle.svg' },
  // { name: 'Repeat', url: '/static/media/Repeat-2.svg' },
  // { name: 'SpellCheck', url: '/static/media/Spell-Check.svg' },
  // { name: 'Statistic', url: '/static/media/Statistic.svg' },
  // { name: 'StudentHat', url: '/static/media/Student-Hat.svg' },
  // { name: 'TextHighlight', url: '/static/media/Text-HighlightColor.svg' },
  // { name: 'User' },
  // { name: 'VideoFour' },
  // { name: 'VideoFive' },
  // { name: 'Pen' },
  // { name: 'Group' },

  'BarChart',
  'Books',
  'BulletedList',
  'Calendar',
  'Claps',
  'Clock',
  'Check',
  'Diploma',
  'Download',
  'File',
  'Fire',
  'Flash',
  'Food',
  'Group',
  'health',
  'Idea',
  'Image',
  'Kpi',
  'Money',
  'Nature',
  'Notepad',
  'Pen',
  'Professor',
  'Profile',
  'ProsCons',
  'Puzzle',
  'Questions',
  'Settings',
  'Online',
  'SpellCheck',
  'Star',
  'StepByStep',
  'Student',
  'Tree',
  'Video',
]

const sizes = { 'x-small': '18px', 'small': '22px', 'medium': '30px', 'large': '48px' }

// export const findSymbolUrl = (name) => {
//   const symbolObject = listOfIcons.find(element => element.name === name)
//   return symbolObject?.url
// }

const SelectedIcon = styled.div`
  width: ${({ size }) => sizes[size] || '70px'};
  height: ${({ size }) => sizes[size] || '70px'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoIcon = ({ iconName, size }) => {
  // const iconUrl = findSymbolUrl(iconName)
  const Component = Symbols[iconName];

  return (
    <SelectedIcon size={size}>
      {Component && <Component color={theme.colors.NEUTRAL_PRIMARY} size={size || 'large'} />}
    </SelectedIcon>
  );
};

export default InfoIcon;
