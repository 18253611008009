import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typo } from '../../../ui';
import { getPeriodByTime } from '../../../utils/ucpPeriods';


const ShowAccessTime = ({ ucpPeriod }) => {
  const { t } = useTranslation();

  const accessPeriod = t(`course.${getPeriodByTime(ucpPeriod)?.label}`)

  return (
    <Typo.MediumText>{t('course.accessPeriodMessage', { ucp: accessPeriod })}</Typo.MediumText>
  );
}

export default ShowAccessTime;

