import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Textinput, Typo, Button, Flex, SearchSelect, SubPage } from '../../../ui';
import { Checkbox, FormControlLabel } from "@mui/material";
import { fetchEditableCourses, selectCourses } from '../../courses/coursesSlice';
import { useTranslation } from 'react-i18next';
import { sendExport, userExport } from '../exportSlice';
import { CONTENT_SMALL } from '../../../utils/media';

const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const CourseExports = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exportTypes = [
    { name: t('export.personalInfo'), id: 'users-info' },
    { name: t('export.quizQuestion'), id: 5 },
    { name: t('export.taskStatistics'), id: 3 },
    { name: t('export.quizOverview'), id: 2 },
    { name: t('export.progression'), id: 1 },
  ]
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedExport, setSelectedExport] = useState(null);
  const emailRef = useRef(null);
  const [isXlsx, setIsXlsx] = useState(false);
  const courses = useSelector(selectCourses);

  useEffect(() => {
    dispatch(fetchEditableCourses({}));
  }, [dispatch]);

  const clearData = useCallback(() => {
    setSelectedExport(null);
    setSelectedCourse(null);
    setIsXlsx(false);
  }, [])

  const handleSend = useCallback(() => {
    if (selectedExport.id !== 'users-info') {
      const exportData = {
        exportId: selectedExport.id,
        outputFormat: isXlsx ? 'xlsx' : 'csv',
        params: { courseId: selectedCourse.id },
        recipientEmail: emailRef.current.value
      }
      dispatch(sendExport({ exportData })).then(() => {
        clearData()
      })
    }
    else {
      const exportData = {
        courseIds: [selectedCourse.id],
        recipientEmail: emailRef.current.value
      }
      dispatch(userExport({ exportData }));
    }
  }, [clearData, dispatch, isXlsx, selectedCourse, selectedExport]);

  return (
    <SubPage size={CONTENT_SMALL} >
      <Flex width={'100%'} gap={40} direction={'column'}>
        <SearchSelect
          options={courses}
          selectedOptions={selectedCourse}
          label={t('globals.course')}
          setValue={setSelectedCourse}
        />
      </Flex>
      <Flex width={'100%'} gap={5} direction={'column'}>
        <SearchSelect
          options={exportTypes}
          selectedOptions={selectedExport}
          label={t('export.exportType')}
          setValue={setSelectedExport}
        />
        <Typo.MediumText>{t('export.customExport')}</Typo.MediumText>
        <Textinput
          label={t('globals.email')}
          propName={'text'}
          innerRef={ref => emailRef.current = ref}
        />
      </Flex>
      <FormControlLabel
        control={<Checkbox value={isXlsx} onChange={() => setIsXlsx(!isXlsx)} />}
        label={<Typo.MediumText>{t('export.xlsFormat')}</Typo.MediumText>} />
      <Controls>
        <Button onClick={handleSend} disabled={!selectedExport || !selectedCourse}>
          {t('Send')}
        </Button>
      </Controls>
    </SubPage>
  );
}

export default CourseExports;
