import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useNoHeaderModules } from '../../hooks/noHeaderModules'
import { CONTENT_STANDARD, CONTENT_FULL, media } from '../../utils/media'

// const PageWrapper = styled.div`
//   height: ${({ hasStaticElements, elemHeight, noHeader }) =>
//     hasStaticElements ?
//       `calc(100vh - ${elemHeight}px - ${theme.headerSize})` :
//       `${noHeader ? '100vh' : `calc(100vh - ${theme.headerSize})`}`};
//   background-color:${(props) => props.background};
//   overflow:hidden;
// `
const PageWrapper = styled.div`
  height: ${({ $position }) => `calc(100vh - ${$position}px)`};
  background-color: ${(props) => props.$background};
  overflow: hidden;
`

const Header = styled.div`
  margin: auto;
  max-width: ${({ size }) => size !== CONTENT_FULL ? `${size}px` : size};
  padding: 2rem 1rem 0.5rem;
  ${media.lessThan('tablet')} {
    padding: 1rem;
  }
`

const Scrollable = styled.div`
  overflow-y: scroll;
  height: 100%;
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: ${({ $noHeader }) => $noHeader ? '2rem 1rem' : '2rem 1rem 1rem'};
  max-width: ${({ size }) => size !== CONTENT_FULL ? `${size}px` : size};

  gap: ${(props) => props.$gap || 0}px;
  align-items: ${({ align }) => align};
  height:${(props) => props.$fullheight && '100%'};

  ${media.lessThan('tablet')} {
    padding: 1rem;
  }
`

const Page = ({
  children,
  staticElements,
  align,
  gap,
  size,
  backgroundColor,
  fullheight
}) => {

  const [height, setHeight] = useState(0)
  const [position, setPosition] = useState(0)

  const ref = useRef(null)
  const ref2 = useRef(null)
  const noHeader = useNoHeaderModules();

  useEffect(() => {
    setPosition(ref2?.current?.getBoundingClientRect().y)

    setHeight(ref?.current?.clientHeight)
  }, [ref, ref2])

  return (
    <>
      { staticElements && <Header size={size || CONTENT_STANDARD} ref={ref}>
        {staticElements}
      </Header>}
      <PageWrapper ref={ref2} $position={position} $background={backgroundColor} $hasStaticElements={staticElements} $elemHeight={height} $noHeader={noHeader}>
        <Scrollable>
          <PageContent $fullheight={fullheight} align={align || 'center'} $gap={gap} size={size || CONTENT_STANDARD} $noHeader={noHeader}>
            {children}
          </PageContent>
        </Scrollable>
      </PageWrapper>
    </>
  );

};

export default Page;
