import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { discountApi, guestApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------
export const fetchDiscounts = createAsyncThunk('discounts/fetchDiscounts', async ({ courseId, userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'read'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchDiscountById = createAsyncThunk('discounts/fetchDiscountById', async ({ discountId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id: discountId },
    method: 'findById'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const updateDiscount = createAsyncThunk('discounts/updateDiscount', async ({ discountData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: discountData,
    method: 'update'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Discount was successfully updated'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const createDiscount = createAsyncThunk('discounts/createDiscount', async ({ discountData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: discountData,
    method: 'create'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const deleteDiscount = createAsyncThunk('discounts/deleteDiscount', async (discountId, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id: discountId },
    method: 'delete'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Discount was successfully deleted'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const validateDiscount = createAsyncThunk('discounts/validate', async (discountCode, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { code: discountCode },
    method: 'validateDiscount'
  };

  const res = await httpClient.post(guestApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
});


// ----------------- Reducers -----------------------------

const discountsAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  isCodeValid: false,
  entities: {},
  ids: [],
}

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    resetDiscountValidation: state => {
      state.isCodeValid = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDiscounts.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchDiscounts.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDiscounts.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        discountsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchDiscountById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchDiscountById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        discountsAdapter.setOne(state, action.payload);
      })
      .addCase(updateDiscount.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        discountsAdapter.updateOne(state, { id: action.payload.id, changes: { ...action.payload } })
      })
      .addCase(createDiscount.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(createDiscount.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        discountsAdapter.addOne(state, action.payload)
        enqueueSnackbar('Discount was successfully created', { variant: TOAST_SUCCESS })
      })
      .addCase(deleteDiscount.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        discountsAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(validateDiscount.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(validateDiscount.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(validateDiscount.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.isCodeValid = action.payload;
      })
  }

})

export const { resetDiscountValidation } = discountsSlice.actions

export default discountsSlice.reducer

// ----------------- Selectors -----------------------------
export const {
  selectAll: selectDiscounts,
  selectById: selectDiscountById,
} = discountsAdapter.getSelectors((state) => state.discounts);

export const selectIsCodeValid = state => state.discounts.isCodeValid;

// Type: 1 — percentage discount, 2 — absolute discount.
// Discountable objects. [{type, id}, ...]. Types: 1 — course, 2 — package.
