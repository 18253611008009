import React from 'react';
import styled from 'styled-components/macro'
import CtaButton from '../../features/webshop/components/CtaButton';
import ShowAccessTime from '../../features/webshop/components/ShowAccessTime';
import ShowTermsAndConditions from '../../features/webshop/components/ShowTermsAndConditions';
import { Typo, Figure, Flex, ListMarker } from '../../ui';
import { media } from '../../utils/media';

const TextWrapper = styled.div`
  flex: ${({ $showFull }) => $showFull ? '0 1 100%' : '0 1 50%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ $showFull }) => $showFull ? '0' : '0.5rem 0 0 50px'};
  align-items: ${({ $showFull }) => $showFull ? 'center' : 'flex-start'};

  ${media.lessThan('tablet')} {
    padding: 40px 0 0px 0;
    margin-right: 0;
  }
`
const ListWrapper = styled.div`
  width:${({ $showCenter }) => $showCenter ? '' : '100%'};
  padding:20px;
  background-color: ${({ theme }) => theme.colors.NEUTRAL_LIGHT};
  /* Grid Fallback */
  display: ${({ $showGrid }) => !$showGrid ? 'flex' : 'grid'};
  flex-wrap: ${({ $showGrid }) => !$showGrid ? 'nowrap' : 'wrap'};
  grid-template-columns: ${({ $showGrid }) => $showGrid ? 'auto auto' : null};
  grid-gap: ${({ $showGrid }) => $showGrid ? '2rem' : null};
  margin: 3rem 0 0.5rem 0;
  justify-content:flex-start;
  gap: ${({ $showGrid }) => !$showGrid ? '8rem' : null};

  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 1rem;
    justify-content: center;
  }

  ${media.lessThan('phablet')} {
    grid-gap: 2rem 0;
    flex-wrap: wrap;
  }
`;


const SectionWrapper = styled.div`
  display:flex;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: ${({ $hasBottomMargin }) => !!$hasBottomMargin && '2.5rem'};

  ${media.lessThan('tablet')} {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`

const ImageContainer = styled.div`
  flex: 0 1 50%;
  max-width: 560px;
  width: 100%;

  ${media.lessThan('tablet')} {
    max-width: 100%;
  }
`

const HighlightedInfo = ({ info, infoIcons, hasBottomMargin, size, ucpPeriod, courseId }) => {
  if (!Object.keys(info).length && !infoIcons.length) return null

  return (
    <SectionWrapper $hasBottomMargin={hasBottomMargin}>
      {info?.image && <ImageContainer>
        <Figure.Rectangle>
          <img id={info?.image?.id} src={info?.image} alt="courseImage" loading='lazy' />
        </Figure.Rectangle>
      </ImageContainer>}
      <TextWrapper $showFull={!info?.image}>
        <Flex gap={16} direction='column'>
          <Flex width='100%' direction='column' align={!!info?.image ? 'flex-start' : 'center'}>
            <Typo.ContentSmallHeader $textAlign='left'>{info.title}</Typo.ContentSmallHeader>
            <ShowAccessTime ucpPeriod={ucpPeriod} />
          </Flex>
          <Typo.Text>{info.text}</Typo.Text>
        </Flex>
        {!!infoIcons.length && <ListWrapper $showGrid={infoIcons.length > 2} $showCenter={!info?.image}>
          {infoIcons.map((item, i) =>
            <ListMarker key={i}>
              <Typo.TextTitle>{item.title}</Typo.TextTitle>
            </ListMarker>
          )}
        </ListWrapper>}
        <ShowTermsAndConditions />
        <CtaButton courseId={courseId} />
      </TextWrapper>
    </SectionWrapper>
  );
};

export default HighlightedInfo;
