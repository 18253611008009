import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro'
import { fetchCourseById, selectCourse } from './courseSlice';
import { Typo, Button, SubMenu, SubMenuWrapper } from '../../ui';
import { useTranslation } from 'react-i18next';

const CourseTitle = styled(Typo.SectionHeader)`
  align-self:center;
`

const CourseDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const course = useSelector(selectCourse)

  const navigationItems = [
    { label: t('globals.users'), 'value': 'users' },
    { label: t('globals.tasks'), 'value': 'tasks' },
    { label: t('globals.quiz'), 'value': 'quiz' },
    { label: t('global.settings'), 'value': 'settings' }
  ]

  useEffect(() => {
    if (courseId) {
      dispatch(fetchCourseById({ courseId }))
    }
  }, [courseId, dispatch])


  return (
    <>
      <SubMenuWrapper direction='column' align='flex-start'>
        <Button $asText onClick={() => navigate('/courses')} >
          {`< ${t('course.goBackToList')}`}
        </Button>
        <CourseTitle>{course.name}</CourseTitle>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={3} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default CourseDetails;
