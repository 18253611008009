import { combineReducers, configureStore } from '@reduxjs/toolkit';

import cart from './features/webshop/cartSlice';
import course from './features/course/courseSlice';
import global from './features/global/globalSlice';
import fileUploader from './features/fileUploader/fileSlice';
import notes from './features/notes/notesSlice';
import auth from './features/auth/authSlice';
import dashboard from './features/dashboard/dashboardSlice';
import notifications from './features/notifications/notificationsSlice';
import webshopCourse from './features/webshop/courseSlice';
import webshopCourses from './features/webshop/coursesSlice';
import webshopGlobal from './features/webshop/globalSlice';
import webshopHome from './features/webshop/homeSlice';
import payments from './features/webshop/paymentsSlice';
import courses from './features/courses/coursesSlice';
import users from './features/users/usersSlice';
import user from './features/users/userSlice';
import groups from './features/groups/groupsSlice';
import invites from './features/invites/invitesSlice';
import settings from './features/settings/settingsSlice';
import statistics from './features/statistics/statisticsSlice';
import media from './features/media/mediaSlice';
import quizes from './features/quizes/quizesSlice';
import discounts from './features/discounts/discountsSlice';
import sections from './features/sections/sectionsSlice';
import transactions from './features/transactions/transactionsSlice';
import mrWolf from './features/mrWolf/mrWolfSlice';
import exports from './features/exports/exportSlice';
import search from './features/search/searchSlice';
import tasks from './features/tasks/tasksSlice';
import diploma from './features/diploma/diplomaSlice';

const store = configureStore({
  reducer: {
    auth,
    global,
    courses,
    course,
    users,
    user,
    groups,
    fileUploader,
    payments,
    dashboard,
    notes,
    diploma,
    invites,
    settings,
    notifications,
    statistics,
    media,
    quizes,
    tasks,
    discounts,
    sections,
    transactions,
    mrWolf,
    exports,
    search,
    webshop: combineReducers({
      home: webshopHome,
      course: webshopCourse,
      courses: webshopCourses,
      cart,
      global: webshopGlobal,
      payments
    }),
  },
})

export default store;
