import React from 'react';
import { Button, SubMenu, SubMenuWrapper } from '../../../ui';
import { Outlet, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMyStore } from '../../auth/authSlice';

const BuildCourse = ({ isPackage, slug }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const myStore = useSelector(selectMyStore)

  const navigationItems = [
    { label: t('webshop.build.courseSettings'), value: 'settings' },
    { label: t('webshop.build.course'), value: 'content' },
  ]

  return (
    <>
      <SubMenuWrapper direction='column' align='flex-start'>
        <Button $asText onClick={() => navigate(`/store/${myStore}/edit/courses`)} >
          {`< ${t('course.goBackToList')}`}
        </Button>
        {/* <Typo.PageHeader $marginBottom='20px'>{layoutStructure.introduction?.title}</Typo.PageHeader> */}
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={5} />
      </SubMenuWrapper>
      <Outlet context={{ isPackage, slug }} />
    </>
  );
};

export default BuildCourse;
