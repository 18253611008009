import React from 'react';
import styled from 'styled-components/macro';

import { Typo } from '../../../ui';
import { ListItem } from '@mui/material';
import { TASK_TYPE } from '../../../utils/constants';

import AnswerStatusIcon from './AnswerStatusIcon';
import TeacherFeedback from './TeacherFeedback';
import DownloadableAttach from './DownloadableAttach';

const AnswerContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:10px;
  width:100%;
`

const IconWrapper = styled.div`
  display: flex;
  padding-top:10px;
  margin-right:10px;
`

const UserAnswer = ({ handleOpenFeedback, answer, taskType }) => {

  const allowsFeedBack = [TASK_TYPE.OPEN, TASK_TYPE.FILE]

  return (
    <ListItem component='div' alignItems="flex-start">
      <IconWrapper>
        <AnswerStatusIcon grade={answer.grade} taskType={taskType} />
      </IconWrapper>
      <AnswerContainer className='hasBorder' >
        {answer.value.map((item, index) => <Typo.Text key={answer.id + '-reply-' + index}>{item}</Typo.Text>)}
        {answer.created && <Typo.Info>{answer.created}</Typo.Info>}

        {/* only for open task answer */}
        <DownloadableAttach files={answer.files} />
        {allowsFeedBack.includes(taskType) && <TeacherFeedback
          key={answer.id + 'teacher-feedback'}
          feedback={answer.feedback}
          handleOpenFeedback={handleOpenFeedback}
        />}
      </AnswerContainer>
    </ListItem>
  );
};

export default UserAnswer;
