import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import { accessApi, courseApi, userApi } from '../../utils/urls';
//LEGACY
import CourseStore from '../../../../legacy/src/js/stores/Course';
//
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import { ucpPeriods } from '../../utils/ucpPeriods';
import httpClient from '../../services/httpClient';

// ----------------- Thunks -----------------------------

export const fetchCountByCourseId = createAsyncThunk('course/fetchCountByCourseId', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      courseId
    },
    method: 'countByCourseId'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchCourseById = createAsyncThunk('course/fetchCourseById', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: courseId,
    },
    method: 'findById'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchCourseByUserSlag = createAsyncThunk('course/fetchCourseByUserSlag', async ({ username, slugName }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      slug: slugName,
      username,
      countStudents: true,
    },
    method: 'findByUsernameAndSlug'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const createCourse = createAsyncThunk('course/create', async ({ name, coverImage }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      name,
      coverImage
    },
    method: 'create'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course created with success';
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS;
  enqueueSnackbar(message, { variant });

  return res.data;
});

export const updateCourse = createAsyncThunk('course/update', async ({ courseData }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: courseData,
    method: 'update'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course updated with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const deleteCourse = createAsyncThunk('course/deleteCourse', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course deleted with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const toggleCourseDraft = createAsyncThunk('course/toggleCourseDraft', async ({ id }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
      method: 'toggleDraft'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course draft setting updated successfully'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const duplicateCourse = createAsyncThunk('course/duplicateCourse', async (id, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { id },
    method: 'duplicate'
  };

  const res = await httpClient.post(courseApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Course duplicated with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});
export const changeAccessToCourse = createAsyncThunk('course/changeAccessToCourse', async ({ id, expires, userId, mode }, { getState, dispatch, rejectWithValue }) => {
  // mode => Options: 'reader', 'builder'
  const body = {
    data: { id, expires, userId, mode },
    method: 'course'
  };

  const res = await httpClient.post(accessApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Access to course updated'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchCourseByUserSlag.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchCourseByUserSlag.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchCourseByUserSlag.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;

        // LEGACY UPDATE
        const legacyGlobalData = CourseStore.getInitialState();
        legacyGlobalData.course = action.payload;
        legacyGlobalData.page = {};
        CourseStore.triggerForLegacy(legacyGlobalData)
        // FINISH LEGACY UPDATE
        state.data = action.payload;
      })
      .addCase(fetchCourseById.pending, (state, action) => {
        state.status = STATUS_LOADING;
        state.data = {}
      })
      .addCase(fetchCourseById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchCountByCourseId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data.totalUsers = action.payload;
      })
      .addCase(updateCourse.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(toggleCourseDraft.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
  }
})

// export const { } = courseSlice.actions

export default courseSlice.reducer

// ----------------- Selectors --------------------------
export const selectCourse = state => state.course.data;
export const selectCourseId = state => state.course.data.id;
export const selectCourseToc = state => state.course.data.toc;
export const selectCourseQuiz = state => {
  const allQuizes = state.course.data.toc?.filter(element => element.type === 'quiz')
  return allQuizes?.reduce((acc, el) => {
    acc = [...acc, el.pages[0]]
    return acc;
  }, [])
}
export const selectCourseQuizSettings = state => state.course.data.settings?.quiz?.pages
export const selectUsersAmount = state => state.course.data.totalUsers;

const selectHasUnlimitedDuration = (state) => state.settings.noCourseExpire;
const selectShowUnlimited = (state, hideUnlimited) => hideUnlimited
export const selectCourseDurations = createSelector(
  [selectHasUnlimitedDuration, selectShowUnlimited],
  (hasUnlimitedDuration, hideUnlimited) => {
    const allDurations = [...ucpPeriods]
    if (!hasUnlimitedDuration || hideUnlimited) allDurations.shift()
    return allDurations
  }
)

const secondsInAnHour = 60 * 60;

const periodMultipliers = {
  0: 1,
  1: 24,
  2: 30 * 24
};

export const courseAccessTimePeriod = [
  { value: 'hour', text: 'Hours' },
  { value: 'day', text: 'Days' },
  { value: 'month', text: 'Months' }
]

export const secondsToPeriod = seconds => {
  let selectedIndex = 0;
  let amount = 0;
  Object.keys(periodMultipliers)
    .sort((a, b) => b - a)
    .forEach(index => {
      if (
        amount < 1 &&
        seconds % (secondsInAnHour * periodMultipliers[index]) === 0
      ) {
        amount = seconds / (secondsInAnHour * periodMultipliers[index]);
        selectedIndex = parseInt(index, 10);
      }
    });
  return { type: courseAccessTimePeriod[selectedIndex].value, amount };
};

