import React, { useEffect, useState, useCallback } from 'react';

import styled from 'styled-components/macro'
import { filesHostName, s3HostName } from '../../../utils/constants';
import { IconAsButton, Typo, Symbols, Textinput, Figure, VideoPlayer } from '../../../ui';
import theme from '../../../ui/theme';
import { useDispatch, useSelector } from 'react-redux';
import { fileUpload, selectFile } from '../../fileUploader/fileSlice';
import MediaPicker from '../MediaPicker';
import { useTranslation } from 'react-i18next';

const VideoUploadWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
`
const VideoUploadCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  cursor: pointer;
`
const VideoSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  position: relative;

  button {
    position: absolute;
    z-index: 3;
    background: white;
    bottom: 10px;
    right: 10px;
  }
`

const chooseVideoFile = () => {
  document.getElementById('fake-video-input').value = null;
  document.getElementById('fake-video-input').click();
};

const VideoUploader = ({
  saveAction,
  videoToPreview,
  trackDeleted = () => { return null }
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  /* eslint-disable-next-line */
  const [error, setError] = useState(0);
  const [hiddenVideo, setHiddenVideo] = useState(false);
  const [videoIsExternal, setVideoIsExternal] = useState(true);
  const [libraryModal, setLibraryModal] = useState(false);

  const file = useSelector(selectFile);

  const handleLibraryModal = useCallback((e) => {
    e.stopPropagation();
    setLibraryModal(!libraryModal);
  }, [libraryModal]);

  useEffect(() => {
    if (videoToPreview?.url) {
      const findOurHosting = videoToPreview.url.search(s3HostName)
      setVideoIsExternal(findOurHosting < 0)
    }
  }, [videoToPreview])

  useEffect(() => {
    if (file) {
      setSelectedFile({ ...selectedFile, id: file.id })
      saveAction({ id: file.id, url: file.url });
      setHiddenVideo(false);
      setProgress(100);
    }
  }, [file, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFile = useCallback((e) => {
    setProgress(1)
    setSelectedFile(e.target.files[0]);
    dispatch(fileUpload(e.target.files[0]));
  }, [dispatch]);

  const onPasteUrl = useCallback((e) => {
    setHiddenVideo(false);
    saveAction({ url: e.target.value });
  }, [saveAction]);

  const deleteVideo = useCallback(() => {
    setHiddenVideo(true);
    trackDeleted({ id: null, url: null })
  }, []);

  const EditVideo = () =>
    <VideoUploadWrapper >
      <VideoUploadCard onClick={chooseVideoFile} >
        <input accept="video/*" type='file' style={{ display: 'none' }} id='fake-video-input' onChange={onSelectFile} />
        {progress > 0 && progress !== 100 ?
          <>
            <Symbols.Loading />
            <Typo.Text $textAlign='center'>{t('mediaLibrary.videoProcessingWarning')}</Typo.Text>
          </>
          //move message to toast
          // : progress === 100 ?
          //   <Typo.Text $textAlign='center'>Your video was successfully uploaded</Typo.Text>
          :
          <>
            <Symbols.Upload color={theme.colors.NEUTRAL_60} />
            <Typo.Text $textAlign='center'>{t('mediaLibrary.uploadVideo')}</Typo.Text>
          </>
        }
      </VideoUploadCard>
      <>
        <Typo.Text $textAlign='center'>{t('globals.or')}</Typo.Text>
        <Textinput
          label={t('globals.videoUrl')}
          propName='url'
          placeholder={t('mediaLibrary.videoMediaSourcesDescription')}
          onChange={onPasteUrl}
        />
      </>
      {!!error && <Typo.Info color={theme.colors.Error}>{t('globals.error')}: {error}</Typo.Info>}
    </VideoUploadWrapper>

  return (
    videoToPreview?.url && !hiddenVideo ?
      <VideoSectionWrapper>
        <IconAsButton
          iconName='Delete'
          variant='action'
          tooltipLabel={t('globals.remove')}
          color={theme.colors.ACTION}
          clickHandle={deleteVideo}
        />
        <Figure.Video>
          <VideoPlayer previewContent={videoToPreview.url} />
        </Figure.Video>
        {videoIsExternal && <Typo.Info>{videoToPreview.url}</Typo.Info>}
      </VideoSectionWrapper>
      :
      <>
        <EditVideo />
        <MediaPicker
          saveAction={saveAction}
          mediaType={'video'}
          isOpen={libraryModal}
          closeModal={handleLibraryModal}
        />
      </>
  );
};

export default VideoUploader;
