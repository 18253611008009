import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { nanoid } from '@reduxjs/toolkit'
import theme from '../../ui/theme';
import { Flex, Textinput, Typo, IconAsButton, AddItem, SearchSelect } from '../../ui';
import InfoIcon, { listOfIcons } from '../../ui/InfoIconCard/InfoIcon';
import { useTranslation } from 'react-i18next';

const InfoIconWrapper = styled.div`
  margin-bottom: 1rem;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
`
const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  border-radius: 4px;
  padding: 15px;
  text-align: end;
  margin: 10px;
`

const InfoIconGrid = styled.div`
  width:100%;
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-auto-rows: minmax(150px, auto);
  grid-gap: 1.875rem;
`

const BuildInfoIcons = ({
  infoIcons,
  setInfoIcons,
  isMini,
}) => {
  const { t } = useTranslation();

  const deleteInfoIcon = useCallback(id => {
    setInfoIcons(infoIcons.filter((el) => el.id !== id));
  }, [infoIcons, setInfoIcons]);

  const addInfoIcon = useCallback(() => {
    setInfoIcons([...infoIcons, {
      title: '', text: '',
      iconName: listOfIcons[0].name,
      id: nanoid(),
    }]);
  }, [infoIcons, setInfoIcons]);

  const editIconField = useCallback((e, id) => {
    const { value, dataset } = e.target
    const newInfoIcons = [...infoIcons];
    newInfoIcons.map(el => {
      if (el.id === id) {
        el[dataset.propname] = value;
      }
      return el
    });
    setInfoIcons(newInfoIcons);
  }, [infoIcons, setInfoIcons]);

  const handleIconChange = useCallback((icon) => {
    const newInfoIcons = [...infoIcons];
    newInfoIcons.map(el => {
      if (el.id === icon.listId) {
        el.iconName = icon.iconName;
      }
      return el;
    });
    setInfoIcons(newInfoIcons);
  }, [infoIcons, setInfoIcons]);

  return (
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{isMini ? t('webshop.build.infoIconsTitle') : t('webshop.build.infoIconsTitleWithIcons')}</Typo.ContentSmallHeader>
      <InfoIconGrid>
        {infoIcons.map(item =>
          <Wrapper key={item.id}>
            <IconAsButton
              iconName='Delete'
              color={theme.colors.ACTION}
              variant='action'
              tooltipLabel={t('globals.delete')}
              clickHandle={() => deleteInfoIcon(item.id)}
            />
            {!isMini && <InfoIconWrapper>
              <InfoIcon iconName={item.iconName} />
              <SearchSelect
                id='info-icons'
                setValue={handleIconChange}
                selectedOptions={item}
                ownIcon
                options={listOfIcons.map((icon) => ({ iconName: icon, listId: item.id, id: `${item.id}-${icon}` }))}
                label={t('globals.icons')}
                optionLabel='iconName'
              />
            </InfoIconWrapper>}
            <Textinput
              label={t('globals.title')}
              propName={'title'}
              defaultValue={item.title}
              onBlurValue={e => editIconField(e, item.id)}
            />
            {!isMini && <Textinput
              label={t('globals.description')}
              propName={'text'}
              defaultValue={item.text}
              onBlurValue={e => editIconField(e, item.id)}
              isMultiline
            />}
          </Wrapper>
        )}
        <AddItem text='info block' handleClick={addInfoIcon} />
      </InfoIconGrid>
    </Flex>
  );
};

export default BuildInfoIcons;
