import React from 'react';
import styled from 'styled-components/macro';
// import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Typo, Flex, Icon } from '../../ui';

import InfoIconSection from '../../components/InfoIconSection';


const StyledAccordion = styled(Accordion)`
  width:100%;
`

const HowToUpdateStore = ({ handleChange, expanded }) => {

  // const { t } = useTranslation();

  const infoIcons = [
    {
      title: 'Experts in our field',
      text: 'Let your target group know that you are the experts in your field(s)!',
      iconName: 'Professor',
      id: 'Professor'
    },
    {
      title: 'Video & Text',
      text: 'Learn step by step through chapters that consist of videos, reading material and checklists',
      iconName: 'Video',
      id: 'Video'
    },
    {
      title: 'Receive a diploma!',
      text: 'After completed course you will receive a diploma that you can attach to your CV',
      iconName: 'Diploma',
      id: 'Diploma'
    },
    {
      title: '100% all online',
      text: 'You only need internet to login and start!',
      iconName: 'Online',
      id: 'Online'
    },
    {
      title: 'Study anytime!',
      text: 'We give you plenty of time to complete your course. Plan your own time!',
      iconName: 'Clock',
      id: 'Clock'
    },
    {
      title: 'Career Preparation',
      text: 'Designed to make you ready to work right after completed education.',
      iconName: 'Student',
      id: 'Student'
    },
    {
      title: 'Grow your business',
      text: 'Effective and time saving solutions',
      iconName: 'BarChart',
      id: 'BarChart'
    },
    {
      title: 'Build your financial skills',
      text: 'Learn the lingo and to focus on key metrics',
      iconName: 'Money',
      id: 'Money'
    },
    {
      title: 'KPIs',
      text: 'How to choose the right KPIs for your business',
      iconName: 'Kpi',
      id: 'Kpi'
    },
    {
      title: 'Free material',
      text: 'Download free material and keep it even after you finish the course',
      iconName: 'Download',
      id: 'Download'
    },
    {
      title: 'Get inspired!',
      text: 'We have tons of tips and examples for you to be inspired from!',
      iconName: 'Idea',
      id: 'Idea'
    },
    {
      title: 'Solve tasks',
      text: 'Repeat what you just learned by answering checkbox questions',
      iconName: 'Puzzle',
      id: 'Puzzle'
    },

  ]

  return (
    <>

      <StyledAccordion expanded={expanded === 'howToUpdateStore1'} onChange={handleChange('howToUpdateStore1')}>
        <AccordionSummary expandIcon={<Icon.ExpandMore />} >
          <Typo.TextTitle>How to update Storefront page</Typo.TextTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Flex direction='column' gap='20'>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <Typo.Text>Step 1 - Log in. Log in to your account on https://app.coursio.com/</Typo.Text>
              <Typo.Text>Step 2 - Navigate to Edit store - Storefront content</Typo.Text>
              <Typo.Text>Step 3 - Add content</Typo.Text>
            </Flex>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <div>
                <Typo.TextTitle>Set a promotional video (Optional)</Typo.TextTitle>
                <Typo.Text>This will make your store much more attractive and engaging. We highly recommend putting a video here if, or when you have one.</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Add an Image.</Typo.TextTitle>
                <Typo.Text>
                  If you don't add a video, you can add an image instead as a store header.</Typo.Text>
                <Typo.Text>This photo will also be set as a thumbnail if video is added - a thumbnail is what you see before you click the play button.</Typo.Text>
                <Typo.SmallText> Image size: 16:9 (ex: 1920 x 1080)</Typo.SmallText>
              </div>
              <div>
                <Typo.TextTitle>Highlight important information with icons.</Typo.TextTitle>
                <Typo.Text>Add three of these (six works too) by clicking.</Typo.Text>
                <Typo.SmallText>for more info see question 'How can I use the icons?'</Typo.SmallText>
              </div>
              <div>
                <Typo.TextTitle>Main description.</Typo.TextTitle>
                <Typo.Text>Set a headline and describe what your content is all about. (1-3 sentences probably looks best)</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Highlight courses</Typo.TextTitle>
                <Typo.Text>Select courses you want to have in the spotlight</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>All courses will include.</Typo.TextTitle>
                <Typo.Text>Add three of these (or six works too)</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Promote a course.</Typo.TextTitle>
                <Typo.Text>Highlight your best selling course by picking one from the list.</Typo.Text>
              </div>
            </Flex>
          </Flex>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'howToUpdateStore2'} onChange={handleChange('howToUpdateStore2')}>
        <AccordionSummary expandIcon={<Icon.ExpandMore />}>
          <Typo.TextTitle>How to update Course in store page</Typo.TextTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Flex direction='column' gap='20'>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <Typo.Text>Step 1 - Navigate to Edit store</Typo.Text>
              <Typo.Text>Step 2 - Select what course to edit - click pen icon</Typo.Text>
              <Typo.Text>Step 3 - Edit course in store</Typo.Text>
              <Typo.Text>Step 4 - Add content</Typo.Text>
              <Typo.Text>This step is similar to the storefront edit page. But this time you are editing your specific course presentation.</Typo.Text>
            </Flex>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <div>
                <Typo.TextTitle>Set a promotional video (Optional)</Typo.TextTitle>
                <Typo.Text>This will make the course much more attractive and engaging. We highly recommend putting a video trailer here if, or when you have one.</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Add an Image.</Typo.TextTitle>
                <Typo.Text>
                  If you don't add a video, you can add an image instead as a store header.</Typo.Text>
                <Typo.Text>This could also be the thumbnail of your video - a thumbnail is what you see before you click the play button.</Typo.Text>
                <Typo.SmallText> Image size: 16:9 (ex: 1920 x 1080)</Typo.SmallText>
              </div>
              <div>
                <Typo.TextTitle>Main course description.</Typo.TextTitle>
                <Typo.Text>Set a title and describe what your content is all about</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Highlight important information.</Typo.TextTitle>
                <Typo.Text>Add up to 9 bullet points - this section highlights what you get to learn if you choose to take the course. So make sure to choose the most relevant and exciting points about your course.
                </Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Highlight important information with icons</Typo.TextTitle>
                <Typo.Text>Here you can highlight with icons! Make sure to stick with rows of three for a cohesive design layout. See our example on how you can use icons and text together.</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Teacher/coach</Typo.TextTitle>
                <Typo.Text>Add the name of the teacher/instructor/profile and write a short description about them. Important to highlight their credentials, experience and passion for their subject to increase conversion. Don't forget to upload a high quality profile picture of them here too.</Typo.Text>
              </div>

            </Flex>
          </Flex>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'howToUpdateStore3'} onChange={handleChange('howToUpdateStore3')}>
        <AccordionSummary expandIcon={<Icon.ExpandMore />}>
          <Typo.TextTitle>How can I use the icons</Typo.TextTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Flex direction='column' gap='20'>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <Typo.Text>Think about your store's USP (Unique selling points) and choose the best points to highlight.</Typo.Text>
              <Typo.Text>Here are Coursios most common USP for online courses:</Typo.Text>
            </Flex>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <InfoIconSection items={infoIcons} />
              <Typo.Text>Feel free to copy and paste the text examples and make them your own.</Typo.Text>
            </Flex>
          </Flex>
        </AccordionDetails>
      </StyledAccordion>
    </ >
  );
};

export default HowToUpdateStore;
