import React, { useState, useCallback } from 'react';
import { truncate } from '../../../helpers/stringHelpers';
import { Flex, Button, Loading } from '../../../ui';

const DownloadableAttach = ({ files }) => {
  const [loadedValue, setLoadedValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const countLoadedValue = useCallback((loaded, total) => {
    setLoadedValue(((loaded / total) * 100).toFixed());
  }, [isLoading, loadedValue])

  const onFileClick = (file) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', file.url, true);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.onprogress = (event) => {
      if (!isLoading) setIsLoading(true);
      countLoadedValue(event.loaded, event.total)
    };
    xhr.onload = () => {
      setIsLoading(false);
      const blob = new Blob([xhr.response], { type: xhr.type });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';
      a.rel = 'noreferrer';
      a.download = file.name;
      a.click()
    };
  }

  return (
    !!files?.length &&
    <Flex direction={'column'} align={'baseline'}>
      {files.map((file, index) =>
        <Flex align={'center'} gap={10} key={`${file.id}-${index}`}>
          <Button $asText icon='AttachFile' onClick={() => onFileClick(file)}>{truncate(file.name, 50)}</Button>
        </Flex>
      )}
      {isLoading && <Loading type={'page'} value={loadedValue} loadingType={'withProgress'} fullscreen={true} />}
    </Flex>
  );
};
export default DownloadableAttach;
