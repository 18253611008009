import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { SearchBar, Page, Typo, Flex, ListTable, Button, Avatar } from '../../ui';
import { selectIsAdmin, selectIsIncarnated, selectHasRequiredLevel } from '../auth/authSlice';
import { fetchEditableCourses, selectCourses, selectStatus } from './coursesSlice';
import { getPeriodByTime } from '../../utils/ucpPeriods';
import { STATUS_LOADING } from '../../utils/constants';
import Loading from '../../ui/Loading';
import ConfirmDelete from '../../ui/Modal/ConfirmDelete';
import { deleteCourse, duplicateCourse } from '../course/courseSlice';
import CreateCourseModel from '../course/components/CreateCourseModel';
import { useTranslation } from 'react-i18next';
import { media } from '../../utils/media';
import GenerateGiftCodesModal from './components/GenerateGiftCodesModal';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const Controls = styled.div`
  width: 800px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
    margin-bottom: 0;
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const Courses = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchRef = useRef();
  const courses = useSelector(selectCourses);
  const isIncarnated = useSelector(selectIsIncarnated);
  const loadStatus = useSelector(selectStatus);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [localCourses, setLocalCourses] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generateCodesForCourse, setGenerateCodesForCourse] = useState(false);

  const isAdmin = useSelector(selectIsAdmin);
  const isOwner = useSelector((state) => selectHasRequiredLevel(state, 'owner'))

  useEffect(() => {
    dispatch(fetchEditableCourses({ searchText: searchQuery, showUncorrected: true }));
  }, [dispatch, isIncarnated, searchQuery])

  useEffect(() => {
    if (courses?.length) {
      setLocalCourses(courses)
    }
  }, [courses])

  const deleteCourseConfirmed = () => {
    dispatch(deleteCourse(courseToDelete.id)).then(() => setCourseToDelete(null))
  }

  const duplicateCourseHander = (obj) => {
    dispatch(duplicateCourse(obj.id)).then((action) => openSettings(action.payload))
  }

  const openModal = () =>
    setIsModalOpen(true)

  const closeModal = () => {
    setIsModalOpen(false)
    setGenerateCodesForCourse(null)
  }

  const openTasks = useCallback((obj) => {
    navigate(`/courses/${obj.id}/tasks`)
  }, [navigate]);
  const openUsers = useCallback((obj) => {
    navigate(`/courses/${obj.id}/users`)
  }, [navigate]);
  const openQuizes = useCallback((obj) => {
    navigate(`/courses/${obj.id}/quiz`)
  }, [navigate]);
  const openSettings = useCallback((obj) => {
    navigate(`/courses/${obj.id}/settings`)
  }, [navigate]);
  const openCourse = useCallback((obj) => {
    navigate(`/courses/${obj.id}`)
  }, [navigate]);

  const [tableInfo, setTableInfo] = useState([
    {
      label: t('globals.name'), hasSorting: { sortedMethod: 'string', propVal: 'name' }, data: (item) => {
        return <Flex gap='10'>
          <Avatar name={item.name} size='small'>{item.name.substr(0, 1)}</Avatar>
          <div>
            <Typo.Text>{item.name}</Typo.Text>
            <Typo.SmallText>{`course id: ${item.id}`}</Typo.SmallText>
          </div>
        </Flex>
      }
    },
    { label: t('course.createdAt'), hasSorting: { sortedMethod: 'date', propVal: 'created' }, data: (item) => item.created && item.created.split(' ')[0] },
    { label: t('course.activeUsers'), hasSorting: { sortedMethod: 'number', propVal: 'studentCount' }, data: (item) => item.studentCount },
    { label: t('course.accessDuration'), data: (item) => t(`course.${getPeriodByTime(item.ucpPeriod)?.label}`) },
    { label: t('course.uncorrectedTasks'), hasSorting: { sortedMethod: 'number', propVal: 'uncorrectedCount' }, data: (item) => item.uncorrectedCount },
    // { label: t('globals.quiz') },
    // { label: 'Discussions' },
    // { label: 'Pages' },
    // {
    //   label: 'Publish Status', data: (item) => (
    //     <>
    //       <Icon.InConstruction color={theme.colors.NEUTRAL_60} />
    //       <Icon.Published color={theme.colors.NEUTRAL_60} />
    //       If published what type? buy invitation or in store */}
    //       <Icon.Storefront size='small' color={theme.colors.NEUTRAL_60} />
    //       <Icon.ByInvitation size='small' color={theme.colors.NEUTRAL_60} />
    //     </>
    //   )
    // },
    {
      label: 'Actions', menuOptions: () => [
        { label: t('globals.users'), onClick: openUsers },
        { label: t('globals.tasks'), onClick: openTasks },
        { label: t('globals.quiz'), onClick: openQuizes },
        { label: t('globals.settings'), onClick: openSettings },
        { label: t('globals.duplicate'), onClick: duplicateCourseHander },
        isAdmin && { label: 'Create gift codes', onClick: setGenerateCodesForCourse },
        { label: t('globals.delete'), onClick: (item) => setCourseToDelete(item), variant: 'action' }
      ].filter(Boolean)
    },
  ]);

  const handleSearch = debounce((e) => {
    setLocalCourses(courses.filter((el) => el.name.includes(e.target.value)))
    setSearchQuery(e.target.value)
  }, 1000);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
    courses?.length && setLocalCourses(courses);
  }, [setSearchQuery, courses]);

  return (
    <Page staticElements={
      <Header>
        <Flex justify={'space-between'} align={'flex-start'}>
          <Typo.ContentSmallHeader>{t('course.allCourses')}</Typo.ContentSmallHeader>
        </Flex>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('invites.searchPlaceholder')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          {isOwner && <Button icon='Edit' onClick={openModal}>{t('course.createCourse')}</Button>}
        </Controls>
      </Header>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={localCourses}
          onRowClick={openCourse}
          tableName={'courses'}
          // resultSetter={setLocalCourses}
          tableSetter={setTableInfo}
        />}
      <ConfirmDelete
        onClose={setCourseToDelete}
        onAcceptDelete={deleteCourseConfirmed}
        open={!!courseToDelete?.id}
        description={t('course.confirmDeleteCourseDescription', { courseToDelete: courseToDelete?.name })}
      />
      <GenerateGiftCodesModal course={generateCodesForCourse} onClose={closeModal} />
      <CreateCourseModel open={isModalOpen} onClose={closeModal} />
    </Page >
  );
};

export default Courses;
