import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import IconAsButton from "../IconAsButton";
import { useTranslation } from 'react-i18next';

const MoreMenu = ({ options, item }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.currentTarget);
  };
  const handleOptionClick = (e, optionSelected) => {
    e.preventDefault()
    e.stopPropagation()
    optionSelected.onClick(item)
    handleClose(e)
  }
  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <div>
      <IconAsButton
        aria-label="more"
        id="more-button"
        clickHandle={handleClick}
        iconName='More'
        size='small'
        tooltipLabel={t('globals.actions')}
      />
      <Menu
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={`${option.label}-${item.id}`}
            onClick={(e) => handleOptionClick(e, option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )

};

export default MoreMenu;
