import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconAsButton, Typo } from '..'

const DropdownWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
`;

const DropdownToggler = styled.div`
${({ isIcon }) =>
    !isIcon &&
    css`
      background-color: ${(props) => props.theme.colors.NEUTRAL_20};
    `
  }
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
`;

const DropdownList = styled.ul`
  width: 150px;
  position: absolute;
  ${({ position }) =>
    position === "top" &&
    css`
      top: 0;
      transform: translateY(-100%);
    `
  }
  ${({ position }) =>
    position === "bottom" &&
    css` top: 100%;`
  }
  right: 0;
  margin: 0;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  list-style-type: none;
  display: block;
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT};
  transition: all .15s cubic-bezier(1, 0.06, 0.58, 1.2);

  &:hover {
    background-color: ${(props) => props.theme.colors.NEUTRAL_20};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  }

  i {
    margin-right: 5px;
  }
`;

const Dropdown = ({ info, label, iconName, position }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen]);

  return (
    <DropdownWrapper>
      <DropdownToggler isIcon={!!iconName} onClick={handleDropdown}>
        {label && <Typo.Info>{label}</Typo.Info>}
        {iconName && <IconAsButton iconName={iconName} />}
      </DropdownToggler>
      {isOpen &&
        <DropdownList position={position} >
          {info?.map((option) => {
            const SmallIcon = Icon[option.icon];
            return (
              <DropdownItem key={option.label} onClick={option.fnc}>
                {option.icon && <SmallIcon size='small' />}
                <Typo.Info>{option.label}</Typo.Info>
              </DropdownItem>
            )
          })}
        </DropdownList>}
    </DropdownWrapper>
  );
};

export default Dropdown;
