import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Section, SpotlightCourseCard } from '../../ui'
import { selectStoreName, selectUserTheme } from '../../features/webshop/globalSlice';
import { useNavigate } from 'react-router-dom';
import Carousel from '../../ui/Carousel';
import { getUserThemeColor } from '../../ui/theme/colors';
import { useTranslation } from 'react-i18next';

const SpotlightedCourses = ({ courses }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName)
  const userTheme = useSelector(selectUserTheme);

  const goToCourse = useCallback((item) => {
    navigate(`/store/${storeName}/${item.slug}`);
  }, [navigate, storeName])

  if (!courses.length) return null

  return (
    <Section variant={getUserThemeColor(userTheme.color)} title={t('webshop.occasionCourses')}>
      <Carousel
        slidesPerView={1}
        pagination={{ clickable: true }}
        bgColor={userTheme.color}
        themeColor={userTheme.color}
        autoplay={{
          delay: 7500,
          disableOnInteraction: false,
        }}
      >
        {courses?.map((item) =>
          <SpotlightCourseCard
            themeColor={userTheme.color}
            course={item}
            key={item.id}
            goToCoursePage={() => goToCourse(item)}
          />
        )}
      </Carousel>
    </Section>
  );
};

export default SpotlightedCourses;
