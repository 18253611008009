import React from 'react';
import { Helmet } from 'react-helmet-async';
const SEO = ({title, image, name, type}) => {
  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>{title}</title>
      <meta name='image' content={image} />
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content={name} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      { /* End Twitter tags */ }
    </Helmet>
  )
}

export default SEO;
