import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro'
import { Button } from '../../../ui';

const ButtonStyled = styled(Button)`
  align-self:flex-end; 
`

const SupportLink = () => {

  const { t } = useTranslation();

  const lang = localStorage.getItem('i18nextLng')
  const link = lang === 'sv' ? 'https://support.coursio.com/hc/sv' : 'https://support.coursio.com/hc/en-us'

  return <ButtonStyled $asText newTab href={link}>{t('globals.contactSupport')}</ButtonStyled>

};

export default SupportLink;
