import { Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro'
import ImageUploader from '../../media/uploaders/ImageUploader';
import { Button, Flex, SubPage, Typo, Textinput, Figure } from '../../../ui';
import { getUserThemeColor, USER_THEME_COLORS, hexToRgb, rgbToHex, getDarkerThemeColor, getLighterThemeColor } from '../../../ui/theme/colors';
import { CONTENT_SMALL } from '../../../utils/media';
import { selectSettings, updateSettings } from '../settingsSlice';

const ColorBox = styled.div`
  min-width: 64px;
  min-height: 45px;
  background-color: ${({ color }) => color};
  margin: 8px;
  display:flex;
  align-items: center;
  justify-content: center;
  padding:0 1rem;
`
const ColorBox2 = styled(ColorBox)`
  margin-left: 0;
`

const Box = styled.div`
  display:flex;
  cursor: pointer;
  background-color:${(props) => props.selected && props.theme.colors.NEUTRAL_PRIMARY};
  box-shadow:${(props) => props.theme.shadows.BOX_SHADOW};
`

const ColorInput = styled.input`
  margin: 8px;
  max-width: 32px;
  min-height: 32px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  appearance: none;
  padding: 0;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
  }
`

const CustomColorContainer = styled.div`
  display: flex;
  align-items: center;
  && div {
    margin: 0;
  }
`

const RgbTitle = styled.span`
  margin: 0 5px 0 10px;
`

const Branding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customColor, setCustomColor] = useState(false);
  const [colorSelected, setColorSelected] = useState();
  const [customSelected, setCustomSelected] = useState();
  const customColorRef = useRef({ r: 0, g: 0, b: 0 });
  const customSelectedRef = useRef('');
  const settings = useSelector(selectSettings);

  const colorTheme = settings.theme?.color;

  const selectThemeColor = useCallback((e) => {
    setCustomColor(false);
    const color = e.currentTarget.getAttribute('aria-label');
    setColorSelected(color);
  }, []);

  const resetColorThemeSelection = useCallback(() => {
    if (Object.keys(USER_THEME_COLORS).includes(colorTheme)) {
      setColorSelected(colorTheme)
    } else if (colorTheme) {
      setColorSelected(null);
      setCustomColor(true);
      setCustomSelected(colorTheme);
    }
  }, [colorTheme]);

  useEffect(() => {
    resetColorThemeSelection();
  }, [resetColorThemeSelection]);

  const saveBranding = useCallback(() => {
    const neededColor = customColor ? customSelectedRef.current.value : colorSelected;

    if ((neededColor !== colorTheme) || customColor) {
      const settingsData = {
        theme: { ...settings.theme, color: neededColor }
      }
      dispatch(updateSettings({ settingsData }))
    }
  }, [dispatch, colorSelected, settings.theme, colorTheme, customColor]);

  const saveBrandingLogo = useCallback((logo) => {
    if (logo) {
      const settingsData = {
        theme: { ...settings.theme, logoId: logo?.id, logoUrl: logo?.url }
      }
      dispatch(updateSettings({ settingsData }))
    }
  }, [dispatch, settings.theme]);

  const pickColor = useCallback((e) => {
    const color = e?.target?.value || e;
    const rgb = hexToRgb(color);

    for (let key in rgb) {
      customColorRef.current[key].value = rgb[key];
    }
  }, []);

  const applyToPicker = useCallback((color) => {
    const { r, g, b } = customColorRef.current;
    customSelectedRef.current.value = typeof color === 'string' ? color : rgbToHex(r.value, g.value, b.value);
  }, []);

  useEffect(() => {
    if (customSelected && customColor) {
      pickColor(customSelected);
      applyToPicker(customSelected)
    }
  }, [customSelected, pickColor, applyToPicker, customColor]);

  return (
    <SubPage align='flex-start' size={CONTENT_SMALL} gap='40' >
      <Flex gap='30' direction='column' align='flex-start' minWidth='300px'>
        <Typo.TextTitle >{t('settings.logo')}</Typo.TextTitle>
        <ImageUploader
          saveByDelete={true}
          type={'single'}
          id={'companyLogo'}
          saveAction={saveBrandingLogo}
          imageToPreview={settings.theme?.logoUrl}
        >
          {settings.theme?.logoUrl &&
            <Figure.Rectangle $objectfit='contain' >
              <img alt={'imageToPreview'} src={settings.theme?.logoUrl} loading='lazy' />
            </Figure.Rectangle>
          }
        </ImageUploader>
      </Flex>
      <Flex gap='30' direction='column' align='flex-start'>
        <Typo.TextTitle >{t('settings.themeColor')}</Typo.TextTitle>
        <Flex wrap={'wrap'} gap='20'>
          {Object.keys(USER_THEME_COLORS).map((color) => {
            const light = getLighterThemeColor(color)
            const dark = getDarkerThemeColor(color)
            return <Tooltip key={color} title={color} placement="bottom" arrow>
              <div onClick={selectThemeColor}>
                <Typo.SmallText>{color}</Typo.SmallText>
                <Box selected={color === colorSelected}  >
                  <ColorBox color={getUserThemeColor(color)}><Typo.TextTitle $themeColor={color}>{t('settings.text')}</Typo.TextTitle></ColorBox>
                  <div>
                    <ColorBox2 color={dark} />
                    <ColorBox2 color={light} />
                  </div>
                </Box>
              </div>
            </Tooltip>
          }
          )}
        </Flex>

        <Flex gap='30' direction='column' align='flex-start'>
          <Typo.TextTitle>{t('settings.selectBrandColor')}</Typo.TextTitle>

          {<Flex wrap={'wrap'} width={'100%'}>
            {/* <Typo.TextTitle>Selected: </Typo.TextTitle> */}
            <ColorInput type="color" defaultValue={customSelected}
              ref={customSelectedRef} onChange={pickColor}
              onBlur={(e) => setCustomSelected(e.target.value)}
            />
            <CustomColorContainer>
              {['r', 'g', 'b'].map(x => {
                return <React.Fragment key={x}>
                  <RgbTitle>{x}:</RgbTitle>
                  <Textinput onChange={applyToPicker}
                    width={`25%`} type={'number'}
                    InputProps={{ inputProps: { min: 0, max: 255 } }}
                    innerRef={ref => customColorRef.current[x] = ref} />
                </React.Fragment>
              })}
            </CustomColorContainer>
          </Flex>}
          <Tooltip title={'Custom'} placement="bottom" arrow>
            <Box selected={customSelected && !Object.keys(USER_THEME_COLORS).includes(colorSelected)}
              onClick={() => { setCustomColor(true); setColorSelected(null) }
              }>
              <ColorBox color={customSelected || '#808080'}>
                <Typo.TextTitle $themeColor={customSelected || '#808080'}>{t('settings.selectedColor')}</Typo.TextTitle>
              </ColorBox>
              {customSelected && <div>
                <ColorBox2 color={getDarkerThemeColor(customSelected)} />
                <ColorBox2 color={getLighterThemeColor(customSelected)} />
              </div>}
            </Box>
          </Tooltip>
        </Flex>

        <Flex justify='flex-end' gap='10' width='100%'>
          <Button $variant='basic' onClick={resetColorThemeSelection}>{t('globals.cancel')}</Button>
          <Button onClick={saveBranding}>{t('globals.save')}</Button>
        </Flex>
      </Flex>
    </SubPage >
  );
};

export default Branding;
