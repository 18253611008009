import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { mrWolfUserApi, settingsApi, storeApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const fetchSettings = createAsyncThunk('settings/fetchSettings', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'read'
  };

  const res = await httpClient.post(settingsApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const updateSettings = createAsyncThunk('settings/update', async ({ settingsData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: settingsData,
    method: 'update'
  };

  const res = await httpClient.post(settingsApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Settings successfully updated'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return settingsData; // BE SHOULD RETURN UPDATED OBJECT
});

export const updateCurrency = createAsyncThunk('global/updateCurrency', async (currency, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      currency,
    },
    method: 'changeCurrency'
  };

  const res = await httpClient.post(storeApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'currency updated with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
})

export const fetchStoreCurrency = createAsyncThunk('global/fetchStoreCurrency', async (props, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      storeId: props?.storeId
    },
    method: 'getCurrency'
  };

  const res = await httpClient.post(storeApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const updateExVat = createAsyncThunk('global/updateExVat', async ({ exVat }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      exVat
    },
    method: 'setBusinessExtension'
  };

  const res = await httpClient.post(mrWolfUserApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
})

// ----------------- Reducers -----------------------------

const initialState = {
  noCourseExpire: false,
  hideVAT: false,
  features: {
    enterpriseStore: false,
    klarnaCheckout: false,
    showTaskResultOnAnswer: false,
    diplomas: false,
    dripContent: false,
    quiz: false
  }
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFeatures(state, action) {
      state.features = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.fetchStatus = STATUS_LOADING
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.fetchStatus = STATUS_FAILED
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        const { features } = action.payload;
        const newfeatures = { ...initialState.features };

        features && Object.keys(features).forEach(key => {
          newfeatures[key] = features[key]
        });


        return {
          ...action.payload,
          // hideVAT: initialState.hideVAT,
          fetchStatus: STATUS_SUCCEEDED,
          features: newfeatures
        }
      })
      .addCase(updateSettings.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateSettings.rejected, (state) => {
        state.status = STATUS_FAILED;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        return { ...state, ...action.payload, status: STATUS_SUCCEEDED };
      })
  }
})

export const { setFeatures } = settingsSlice.actions

export default settingsSlice.reducer;

// ----------------- Selectors -----------------------------

export const selectNoCourseExpire = state => state.settings.noCourseExpire;
export const selectSettings = state => state.settings;
export const selectCurrency = state => state.settings.currency;
export const selectStoreHideVAT = state => state.settings.hideVAT;
export const selectFeatures = state => state.settings.features;
export const selectFetchStatus = state => state.settings.fetchStatus;
