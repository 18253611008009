import React, { useState, useEffect } from 'react';
import { Page, theme, Typo, PageHeaderLogo, PageBoxContent, PageContainerVCenter } from '../../ui';

import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hashLogin } from './authSlice';

const Auth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [forgotPass, setForgotPass] = useState(false)

  useEffect(() => {
    if (window.location.search.split('welcome%3D')[1]) {
      const c2hash = window.location.search.split('welcome%3D')[1];

      dispatch(hashLogin({c2hash}));
    }
  }, [])

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true} >
      <PageHeaderLogo />
      <PageContainerVCenter >
        <PageBoxContent>
          <Typo.ContentSmallHeader $marginBottom='30px' >{forgotPass ? t('auth.forgotPassword') : t('auth.signIn')}</Typo.ContentSmallHeader>
          {forgotPass ?
            <ForgotPassword onCancel={() => setForgotPass(false)} />
            : <Login onForgotPass={() => setForgotPass(true)} />
          }
        </PageBoxContent>
      </PageContainerVCenter>
    </Page>
  );
};

export default Auth;
