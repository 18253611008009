import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuWrapper } from '../../ui';

const Profile = () => {

  const { t } = useTranslation();

  const navigationItems = [
    { label: t('profile.personalInfo'), 'value': 'info' },
    // { label: t('globals.diplomas'), 'value': 'diplomas' },
    { label: t('globals.password'), 'value': 'password' },
    { label: t('globals.notifications'), 'value': 'notifications' },
    { label: t('globals.gift'), 'value': 'gift' },
  ]

  return (
    <>
      <SubMenuWrapper>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Profile;
