import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { selectUserTheme } from '../../features/webshop/globalSlice';

import { Section, Typo, Figure } from '../../ui';
import { getLighterThemeColor } from '../../ui/theme/colors';
import { CONTENT_SMALL, media } from '../../utils/media';

const TeacherWrapper = styled.div`
  display: flex;
  width:100%;
  position: relative;
  z-index: 1;
  justify-content: space-between;

  ${media.lessThan('tablet')} {
    flex-direction: column;
  }
`
const ImageContainer = styled.div`
  flex: 0 1 50%;
  max-width: 375px;
  width: 100%;
  // border-radius: 22px;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW_MINI};
  margin: 0 auto;
  height:100%;
  
  ${media.lessThan('tablet')} {
    max-width: 300px;
  }

  ${media.lessThan('phablet')} {
    max-width: 186px;
  }
`
const InfoContainer = styled.div`
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 50px;

  ${media.lessThan('tablet')} {
    padding: 30px 0 0 0;
  }
`

const Teacher = ({ teacherInfo }) => {
  const { t } = useTranslation();
  const userTheme = useSelector(selectUserTheme);
  const bgColor = useMemo(() => getLighterThemeColor(userTheme.color), [userTheme]);

  if (!Object.keys(teacherInfo).length) return null

  const {
    avatarUrl,
    name,
    about,
  } = teacherInfo;

  if (!about && !avatarUrl) return null

  return (
    <Section variant={bgColor} size={CONTENT_SMALL}>
      <TeacherWrapper>
        {avatarUrl && <ImageContainer>
          <Figure.Square>
            <img id={name} src={avatarUrl} alt={name} loading='lazy' />
          </Figure.Square>
        </ImageContainer>}
        {about && <InfoContainer>
          {name && <Typo.SectionHeader
            $themeColor={bgColor}
            $textAlign='left'
            $marginBottom='2rem'
          >{t('webshop.course.teacherGreet', { name: name })}
          </Typo.SectionHeader>}
          <Typo.Text fontWeight='400' $themeColor={bgColor}>{about}</Typo.Text>
        </InfoContainer>}
      </TeacherWrapper>
    </Section>
  );
};

export default Teacher;
