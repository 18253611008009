import React from 'react';
import { SubMenu, SubMenuWrapper } from '../../ui';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Users = () => {
  const { t } = useTranslation();

  const navigationItems = [
    { label: t('globals.users'), 'value': 'all' },
    { label: t('globals.groups'), 'value': 'groups' },
  ]

  return (
    <>
      <SubMenuWrapper direction='column' align='flex-start'>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Users;
