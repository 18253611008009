import React from 'react';
import styled from 'styled-components/macro'
import { AccordionSummary } from '@mui/material';
import { Flex, theme, Typo } from '../../ui';

const CourseContentSubject = styled(AccordionSummary)`
  padding: 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s linear !important;
  min-height: 0 !important;
  z-index: 99;

  p {
    transition: all 0.15s linear;
  }

  &:hover, &.active {
    background-color: ${() => theme.colors.NEUTRAL_0};
    border-radius: 12px;

    & > div > div {
      p:first-of-type {
        background-color: ${() => theme.colors.NEUTRAL_PRIMARY};
      }
    }

    p {
      &:first-of-type {
        color: ${() => theme.colors.NEUTRAL_0};
        font-weight: 600;
      }

      &:last-of-type {
        color: ${() => theme.colors.NEUTRAL_PRIMARY};
      }
    }
  }
`;

const CourseContentNumber = styled(Typo.Text)`
  width: 30px;
  height: 28px;
  border-radius: 50%;
  background-color: ${() => theme.colors.NEUTRAL_20};
  text-align: center;
  font-weight: 400;
  margin-right: 10px;
`;

const CourseSubject = ({ handleClick, isActive, number, name, isMobile }) => {

  return (
    <CourseContentSubject
      onClick={handleClick}
      className={isActive}
    >
      <Flex width={'100%'}>
        <CourseContentNumber >{number}</CourseContentNumber>
        <Typo.Text>{name}</Typo.Text>
      </Flex>
      {isMobile && <Typo.Text>{isActive ? '-' : '+'}</Typo.Text>}
    </CourseContentSubject>

  );
};

export default CourseSubject;
