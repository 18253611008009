import { Checkbox, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Flex, Loading, SubPage, Typo } from '../../../ui';
import { CONTENT_SMALL } from '../../../utils/media';
import { selectFeatures, updateSettings, setFeatures, selectFetchStatus } from '../settingsSlice';
import { STATUS_LOADING, STATUS_SUCCEEDED } from '../../../utils/constants';
import { selectIsAdmin, selectIsIncarnated } from '../../auth/authSlice';

const Features = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const features = useSelector(selectFeatures);
  const fetchStatus = useSelector(selectFetchStatus);
  const [originalList, setOriginalList] = useState(null);

  const isIncarnated = useSelector(selectIsIncarnated);
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    if (fetchStatus === STATUS_SUCCEEDED) {
      setOriginalList(features)
    }
  }, [fetchStatus, features])

  const handleToggle = (key) => {
    const newChecked = { ...features }
    newChecked[key] = !newChecked[key]

    dispatch(setFeatures(newChecked))
  };

  const handleCancel = () => dispatch(setFeatures(originalList));

  const handleSave = () => {
    dispatch(updateSettings({ settingsData: { features } }));
    setOriginalList(features);
  }

  return fetchStatus === STATUS_LOADING ? <Loading /> :
    <SubPage gap='20' align='flex-start' size={CONTENT_SMALL}>
      <Flex gap='10' direction='column' align='flex-start'>
        <Typo.TextTitle>{t('settings.feature_title')}</Typo.TextTitle>
        <Typo.MediumText>{t('settings.feature_description')}</Typo.MediumText>
      </Flex>
      <List sx={{ width: '100%', maxWidth: 360 }}>
        {features && Object.keys(features).map((key) => {
          const labelId = `checkbox-list-label-${key}`;
          if (['enterpriseStore', 'klarnaCheckout', 'showTaskResultOnAnswer'].includes(key) && !(isIncarnated || isAdmin)) return null
          else return (
            <ListItem
              key={key}
              disablePadding
            >
              <ListItemButton role={undefined} onClick={() => handleToggle(key)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={features[key]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <Typo.Text>{t(`settings.${key}`)}</Typo.Text>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
      {<Flex direction='row' gap='10' width='100%' justify='flex-end' >
        <Button onClick={handleCancel}>{t('globals.cancel')}</Button>
        <Button onClick={handleSave}>{t('globals.save')}</Button>
      </Flex>}
    </SubPage>
};

export default Features;
