import React, { useEffect, useState, useCallback } from 'react';

import styled from 'styled-components/macro'
import { IconAsButton, Typo, Symbols, Button, Flex, theme } from '../../../ui';
import { useDispatch, useSelector } from 'react-redux';
import { imageUpload, selectImage } from '../../fileUploader/fileSlice';
import { useTranslation } from 'react-i18next';
import MediaPicker from '../MediaPicker';

const ImageUploadWrapper = styled.div`
  width:100%;
  margin: auto;
  display:flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`
const ImageUploadCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  cursor: pointer;
  border: 1px solid ${theme.colors.NEUTRAL_40};
  border-radius:4px;
`
const ImageSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  position: relative;
  flex: 1;

  button {
    position: absolute;
    z-index: 3;
    background: ${(props) => props.theme.colors.NEUTRAL_0};
    bottom: 10px;
    right: 10px;
  }
`

const ImageUploader = ({
  saveAction,
  imageToPreview,
  id,
  type,
  children,
  saveByDelete,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState(0); // eslint-disable-line
  const [hiddenImage, setHiddenImage] = useState(true);
  const [libraryModal, setLibraryModal] = useState(false);

  const file = useSelector(selectImage);

  const chooseImageFile = () => {
    document.getElementById(id).value = null;
    document.getElementById(id).click();
  };

  const handleLibraryModal = useCallback((e) => {
    e.stopPropagation();
    setLibraryModal(!libraryModal);
  }, [libraryModal])

  useEffect(() => {
    if (imageToPreview) {
      setHiddenImage(false);
    }
  }, [imageToPreview]);

  useEffect(() => {
    if (file && file?.mockedType === type) {
      saveAction({ id: file.id, url: file.url, mockedType: file.mockedType });
      dispatch({ type: 'clearImage' })
      setHiddenImage(false);
    }
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function onCompDestroy() {
      saveAction(null);
      dispatch({ type: 'clearImage' });
    }
    onCompDestroy();
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFile = useCallback(e => {
    dispatch(imageUpload({ file: e.target.files[0], mockedType: type }));
  }, [dispatch, type]);

  const deleteImage = useCallback(() => {
    setHiddenImage(true);
    if (saveByDelete) {
      saveAction({ id: -1, url: null, mockedType: null });
    }
    dispatch({ type: 'clearImage' })
  }, [dispatch, saveAction, saveByDelete]);

  const EditImage = () =>
    <ImageUploadWrapper>
      <input accept="image/*" type='file' style={{ display: 'none' }} id={id} onChange={onSelectFile} />
      <Flex gap='15' direction='column'>
        <ImageUploadCard htmlFor={id} onClick={chooseImageFile} >
          <Symbols.Upload color={theme.colors.NEUTRAL_60} />
          <Typo.Text $textAlign='center'>{t('mediaLibrary.uploadImage')}</Typo.Text>
          <Typo.Text $textAlign='center'>{t('mediaLibrary.uploadFromComp')}</Typo.Text>
        </ImageUploadCard>
        <Button $asLink onClick={handleLibraryModal}>{t('mediaLibrary.uploadFromMedia')}</Button>
      </Flex>
      {!!error && <Typo.Info color={theme.colors.Error}>Error: {error}</Typo.Info>}
    </ImageUploadWrapper >;

  return (
    children && !hiddenImage /* && imageToPreview.mockedType === type */ ?
      <ImageSectionWrapper>
        <IconAsButton
          iconName='Delete'
          variant='action'
          tooltipLabel={t('globals.delete')}
          color={theme.colors.ACTION}
          clickHandle={deleteImage}
        />
        {children}
      </ImageSectionWrapper>
      : <>
        <EditImage />
        <MediaPicker
          saveAction={saveAction}
          mediaType='image'
          isOpen={libraryModal}
          closeModal={handleLibraryModal}
        />
      </>
  )
};

export default ImageUploader;
