import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { userApi } from '../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const fetchReaders = createAsyncThunk('users/fetchReaders', async (props, { getState, dispatch, rejectWithValue }) => {
  let body;

  if (props) {
    const { pageLength, searchText, sortBy, sortMode } = props
    body = {
      data: {
        showCourses: true,
        showCircles: true,
        showUncorrected: true,
        searchText,
        sortBy: sortBy || 'name',
        sortMode: sortMode || 'asc',
      },
      pageStart: 0,
      pageLength,
      method: 'findReaders'
    }
  } else {
    body = {
      data: {
        sortBy: 'name',
        sortMode: 'asc',
      },
      method: 'findReaders'
    }
  }
  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const fetchUserByCourseId = createAsyncThunk('user/fetchUserByCourseId', async ({ courseId, token, sortBy, sortMode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      sortBy: sortBy || 'name',
      sortMode: sortMode || 'asc',
      courseId,
      token,
    },
    method: 'findByCourseId'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchUsersByGroupId = createAsyncThunk('user/fetchUsersByGroupId', async ({ circleId, sortBy, sortMode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      circleId,
      sortMode,
      sortBy
    },
    method: 'findByCircleId'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const removeUserFromCourse = createAsyncThunk('user/removeFromCourse', async ({ userId, courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      courseId,
      userId
    },
    method: 'removeFromCourse'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully removed user from the course.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});


export const addUserToGroup = createAsyncThunk('user/addToGroup', async ({ userId, circleId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { userId, circleId },
    method: 'addToCircle'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully added user(s) to group.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const removeUserFromGroup = createAsyncThunk('user/removeFromGroup', async ({ userId, circleId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { userId, circleId },
    method: 'removeFromCircle'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully removed user from group.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});
export const addUserToCourse = createAsyncThunk('user/addUserToCourse', async ({ userId, courseId, role }, { getState, dispatch, rejectWithValue }) => {
  //role can be reader or builder
  const body = {
    data: { userId, courseId, role },
    method: 'addToCourse'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully added user(s) to course.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const removeUserFromStore = createAsyncThunk('user/removeUserFromStore', async ({ userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id: userId },
    method: 'expel'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data
});


// ----------------- Reducers ---------------------------
const usersAdapter = createEntityAdapter()
const initialState = {
  status: 'idle',
  entities: {},
  ids: [],
  group: {
    entities: {},
    ids: [],
  },
  course: {
    entities: {},
    ids: [],
  }
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchReaders.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchReaders.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchReaders.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        usersAdapter.setAll(state, action.payload)
      })
      .addCase(fetchUsersByGroupId.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchUsersByGroupId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        usersAdapter.setAll(state.group, action.payload)
      })
      .addCase(fetchUserByCourseId.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchUserByCourseId.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchUserByCourseId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        usersAdapter.setAll(state.course, action.payload)
      })
      .addCase(removeUserFromStore.rejected, (state, action) => {
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(removeUserFromStore.fulfilled, (state, action) => {
        usersAdapter.removeOne(state, action.meta.arg.userId)
        enqueueSnackbar('Successfully removed user from all your courses and groups.', { variant: TOAST_SUCCESS });
      })

      .addCase(removeUserFromCourse.fulfilled, (state, action) => {
        usersAdapter.removeOne(state.course, action.meta.arg.userId)
      })
      .addCase(removeUserFromGroup.fulfilled, (state, action) => {
        usersAdapter.removeOne(state.group, action.meta.arg.userId)
      })


  }
})

//   export const { } = usersSlice.actions

export default usersSlice.reducer

// ----------------- Selectors --------------------------

export const {
  selectAll: selectUsers,
  selectById: selectUserById,
} = usersAdapter.getSelectors((state) => state.users)

export const {
  selectAll: selectGroupUsers,
  selectById: selectGroupUserById,
} = usersAdapter.getSelectors((state) => state.users.group)
export const {
  selectAll: selectCourseUsers,
  selectById: selectCourseUserById,
} = usersAdapter.getSelectors((state) => state.users.course)

export const selectStatus = state => state.users.status;
