import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import httpClient from '../../services/httpClient';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../utils/constants';
import { guestApi, paymentApi } from '../../utils/urls';
import { addTransactionGa, checkoutGa } from '../../helpers/GATracker';
// ----------------- Thunks -----------------------------


export const startPaymentProcess = createAsyncThunk('payments/startPaymentProcess', async ({ cart, providerId, storeName, gifterEmail, discountCode, country }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'start',
    data: {
      // dataSource redirect: 0 - app.coursio / 1 - beta.coursio
      // dataSource: 1,
      cart: cart,
      providerId: providerId,
      storeId: storeName,
      gifterEmail,
      discountCode,
      country: country
    }
  };

  const res = await httpClient.post(paymentApi(), body, getState, dispatch, rejectWithValue);
  return res.data.payload?.response?.isError ? res.data : { data: res.data, cart, isGift: !!gifterEmail }

});
export const completePaymentProcess = createAsyncThunk('payments/completePaymentProcess', async ({ hash, providerId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'complete',
    data: {
      // dataSource redirect: 0 - app.coursio / 1 - beta.coursio
      // dataSource: 1,
      providerId,
      token: hash
    }
  };

  const res = await httpClient.post(paymentApi(), body, getState, dispatch, rejectWithValue);
  return res.data
});

export const startGuestPaymentProcess = createAsyncThunk('guest/emailBasedCall', async ({ cart, providerId, storeName, gifterEmail, targetEmail, discountCode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'emailBasedCall',
    data: {
      // dataSource redirect: 0 - app.coursio / 1 - beta.coursio
      // dataSource: 1,
      targetMethod: 'payment/start',
      targetEmail,
      cart: cart,
      providerId: providerId,
      storeId: storeName,
      gifterEmail,
      discountCode
    }
  };

  const res = await httpClient.post(guestApi(), body, getState, dispatch, rejectWithValue);
  return res.data.payload?.response?.isError ? res.data : { data: res.data, cart, isGift: !!gifterEmail, providerId }


});

// ----------------- Reducers ---------------------------

const initialState = {
  status: 'idle', // or: 'loading', 'succeeded', 'failed'
  statusComplete: 'idle', // or: 'loading', 'succeeded', 'failed'
  paymentProcessData: {},
  paymentCompleteData: {},
  privacyAcceptance: false,
  userEmail: '',
  isAGift: false,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPrivacyAcceptance: (state, action) => {
      return { ...state, privacyAcceptance: action.payload }
    },
    setPaymentEmail: (state, action) => {
      return { ...state, paymentEmail: action.payload }
    },
    setIsAGift: (state, action) => {
      return { ...state, isAGift: action.payload }
    },
    resetPaymentProcessData: (state, action) => {
      state.paymentProcessData = {};
    }
  },
  extraReducers: builder => {
    builder
      .addCase(startPaymentProcess.pending, (state, action) => {
        state.status = STATUS_LOADING
      })
      .addCase(startPaymentProcess.rejected, (state, action) => {
        state.status = STATUS_FAILED
        state.paymentProcessData = action.payload?.response;
      })
      .addCase(startPaymentProcess.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        state.paymentProcessData = action.payload.data;
        const { data, cart, isGift, providerId } = action.payload
        const transactionProp = providerId === 9 ? data.transactionId : data.sessionId;

        //GA
        checkoutGa({
          id: cart[0].id,
          name: cart[0].name,
          price: cart[0].price.discounted,
          quantity: 1
        });
        localStorage.setItem('storeCartTransaction', JSON.stringify({ [transactionProp]: { cart: cart, transactionId: data.transactionId, isGift } }))
      })
      .addCase(startGuestPaymentProcess.pending, (state, action) => {
        state.status = STATUS_LOADING
      })
      .addCase(startGuestPaymentProcess.rejected, (state, action) => {
        state.status = STATUS_FAILED
        state.paymentProcessData = action.payload?.response;
      })
      .addCase(startGuestPaymentProcess.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED
        state.paymentProcessData = action.payload.data;
        const { data, cart, isGift, providerId } = action.payload
        const transactionProp = providerId === 9 ? data.transactionId : data.sessionId;

        //GA
        checkoutGa({
          id: cart[0].id,
          name: cart[0].name,
          price: cart[0].price.discounted,
          quantity: 1
        });

        localStorage.setItem('storeCartTransaction', JSON.stringify({ [transactionProp]: { cart: cart, transactionId: transactionProp, isGift } }))
      })
      .addCase(completePaymentProcess.pending, (state, action) => {
        state.statusComplete = STATUS_LOADING
      })
      .addCase(completePaymentProcess.rejected, (state, action) => {
        state.statusComplete = STATUS_FAILED
        state.paymentCompleteData = action.payload.response;
      })
      .addCase(completePaymentProcess.fulfilled, (state, action) => {
        state.statusComplete = STATUS_SUCCEEDED
        state.paymentCompleteData = action.payload;
        const LSPaymentData = JSON.parse(localStorage.getItem('storeCartTransaction'));
        const GaData = {
          transaction_id: action.payload.paymentId,
        }
        for (let key in LSPaymentData) {
          const dataKey = LSPaymentData[key].cart[0];
          GaData.currency = dataKey.currency;
          GaData.items = [
            {
              id: dataKey.id,
              name: dataKey.name,
              quantity: 1,
              price: dataKey.price.discounted
            }
          ]
        }
        addTransactionGa(GaData)
      })
  }
});

export const { setPrivacyAcceptance, setPaymentEmail, setIsAGift, resetPaymentProcessData } = paymentsSlice.actions;

export default paymentsSlice.reducer;

// ----------------- Selectors --------------------------

export const selectPaymentProcessData = state => state.payments.paymentProcessData;
export const selectPaymentCompleteData = state => state.payments.paymentCompleteData;
export const selectPrivacyAcceptance = state => state.payments.privacyAcceptance;
export const selectPaymentEmail = state => state.payments.paymentEmail;
export const selectIsAGift = state => state.payments.isAGift;
export const selectPaymentStatus = state => state.payments.status;
export const selectPaymentCompleteStatus = state => state.payments.statusComplete;

