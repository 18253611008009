import React from 'react';
import { Icon, theme } from '../../../ui';
import { TASK_TYPE } from '../../../utils/constants';

const AnswerStatusIcon = ({ taskType, grade }) => {

  const CorrectResponse = taskType === TASK_TYPE.OPEN ? Icon['Valid'] : Icon['Valid']
  const IncorrectResponse = taskType === TASK_TYPE.OPEN ? Icon['Wrong'] : Icon['Wrong']
  const UnreadResponse = taskType === TASK_TYPE.OPEN ? Icon['AnswerUnread'] : Icon['NotValid']

  return (
    <>
      {grade === 1 ?
        <CorrectResponse size='small' color='green' /> : grade === 0 ?
          <IncorrectResponse size='small' color='red' /> : <UnreadResponse size='small' color={theme.colors.NEUTRAL_PRIMARY} />
      }
    </>
  );
};

export default AnswerStatusIcon;
