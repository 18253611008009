import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Textinput, Button, Flex } from '../../../ui';
import { requestNewPassword, selectAuthStatus } from '../authSlice';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const SuccessMsg = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow:0 0 15px 0 #ccc;
  background-color: #a8f0c6;
`

const ForgotPassword = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailRef = useRef(null);
  const status = useSelector(selectAuthStatus);

  const handleReset = useCallback(() => {
    dispatch(requestNewPassword({ email: emailRef.current.value }));
  }, [dispatch]);

  return (
    <Flex direction='column' gap='30'>
      <Textinput
        autoFocus
        margin='dense'
        id='email'
        label={t('globals.email')}
        type='email'
        fullWidth
        inputRef={emailRef}
      />
      {status === 'passRequestSucceed' &&
        <SuccessMsg className="alert success-alert">{t('auth.forgotPassNextStepLabel')}</SuccessMsg>
      }
      <Flex gap='30'>
        <Button onClick={onCancel}>{t('globals.cancel')}</Button>
        <Button onClick={handleReset}>{t('auth.resetPassword')}</Button>
      </Flex>
    </Flex>

  );
};

export default ForgotPassword;
