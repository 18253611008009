import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro'

import { Typo, Textinput, SectionSeparation } from '../../../../ui';
import { MenuItem, Select, Switch } from '@mui/material';
import { courseAccessTimePeriod, secondsToPeriod, updateCourse } from '../../courseSlice';
import { updateSection } from '../../../sections/sectionsSlice';
import { useTranslation } from 'react-i18next';

const DripContentSection = styled.div`
  margin: 1rem 0 1.5rem 0;

`
const GatedAccessWrapper = styled.div`
  margin: 1rem 0;

`
const DripContentSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

`

const DripContentDelay = styled.div`
  display: flex;
  align-items: baseline;
`
const TextinputStyled = styled(Textinput)`
  margin-right:0.5rem;
  max-width: 50px;
`
const SelectStyled = styled(Select)`
  margin-right:0.5rem;
  min-width: 100px;
`
const DripContentWrapper = styled.div`
  max-width: 70%;
  min-width: 300px;

`
const FlexContainer = styled.div` 
  display:flex; 
  align-items: center;
`

const Seconds = {
  hour: 3600,
  day: 86400,
  month: 2592000
}

const CourseDripContentEdit = ({ course, courseToc, }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState({});
  const [gateAccess, setGateAccess] = useState(false);
  const [delayAccess, setDelayAccess] = useState(false);

  useEffect(() => {
    setDelayAccess(course.settings?.dripContent?.delayedAccess || false);
    setGateAccess(course.settings?.dripContent?.gatedAccess || false);

    if (courseToc && courseToc.length) {
      const tocData = {};
      courseToc.map((section) => {
        const period = secondsToPeriod(section.accessDelay)
        return tocData[section.id] = { type: period.type, value: period.amount }
      });
      setSelectedTimePeriod(tocData);
    }
  }, [course, courseToc]);

  const handleChangeAccess = useCallback((event, prop) => {
    const courseData = {
      id: course.id,
      settings: {
        ...course.settings,
        dripContent: {
          ...course.settings?.dripContent,
          [prop]: event.target.checked
        }
      }
    };

    dispatch(updateCourse({ courseData }));
  }, [dispatch, course]);

  const handleDelayChange = useCallback((event, targetId, property) => {
    let accessDelay;
    setSelectedTimePeriod({ ...selectedTimePeriod, [targetId]: { ...selectedTimePeriod[targetId], [property]: event.target.value } });

    if (property === 'value') {
      accessDelay = event.target.value * Seconds[selectedTimePeriod[targetId].type];
    } else {
      accessDelay = selectedTimePeriod[targetId].value * Seconds[event.target.value];
    }

    const sectionData = {
      id: targetId,
      accessDelay,
    };

    dispatch(updateSection({ sectionData }));
  }, [dispatch, selectedTimePeriod]);

  return (
    <DripContentWrapper>
      <Typo.ContentBigHeader $textAlign='left'>{t('course.dripContent')}</Typo.ContentBigHeader>
      <GatedAccessWrapper>
        <DripContentSwitch>
          <Typo.Text>{t('course.enableGateAccess')}</Typo.Text>
          <FlexContainer>
            <Switch
              size="small"
              checked={gateAccess}
              onChange={e => handleChangeAccess(e, 'gatedAccess')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typo.Info>{gateAccess ? t('globals.enabled') : t('globals.disabled')}</Typo.Info>
          </FlexContainer>
        </DripContentSwitch>
        <Typo.Info >{t('course.gateAccessDescription')}</Typo.Info>
      </GatedAccessWrapper>
      <SectionSeparation />
      <GatedAccessWrapper>
        <DripContentSwitch>
          <Typo.Text>{t('course.delayedAccess')}</Typo.Text>
          <FlexContainer>
            <Switch
              size="small"
              checked={delayAccess}
              onChange={e => handleChangeAccess(e, 'delayedAccess')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typo.Info>{delayAccess ? t('globals.enabled') : t('globals.disabled')}</Typo.Info>
          </FlexContainer>
        </DripContentSwitch>
        <Typo.Info >{t('course.delayedAccessDescription')}</Typo.Info>
        {delayAccess && courseToc?.map((section) =>
          <DripContentSection key={section.id}>
            <Typo.ContentSmallHeader $textAlign='left'>{section.name}</Typo.ContentSmallHeader>
            <Typo.Info >{t('course.makeItAvailableAfter')}</Typo.Info>
            <DripContentDelay>
              <TextinputStyled
                propName={'timeAmount'}
                defaultValue={(selectedTimePeriod && selectedTimePeriod[section.id]?.value) || '0'}
                onBlurValue={e => handleDelayChange(e, section.id, 'value')}
                variant='standard'
                type='number'
              />
              <SelectStyled
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant='standard'
                value={(selectedTimePeriod && selectedTimePeriod[section.id]?.type) || ''}
                onChange={(e) => handleDelayChange(e, section.id, 'type')}
              >
                <MenuItem value='' />
                {courseAccessTimePeriod.map((period) =>
                  <MenuItem key={period.value} value={period.value}>{period.text}</MenuItem>
                )}
              </SelectStyled>
            </DripContentDelay>
          </DripContentSection>
        )}
      </GatedAccessWrapper>
    </DripContentWrapper>

  );
};

export default CourseDripContentEdit;
