import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, PageHeaderLogo } from '../../../ui';
import { selectLanguage } from '../../auth/authSlice';
import { selectDocs } from '../globalSlice';
import { TAC } from "./CopyForTaC";

const ShowTermsAndConditions = () => {
  const { t } = useTranslation();

  const docs = useSelector(selectDocs)
  const [openTAC, setOpenTAC] = useState(false);

  const { termsAndConditions } = docs;
  const language = useSelector(selectLanguage)
  const pageContent = TAC[language] || TAC['en_US'];

  const handleToggleTAC = useCallback(() => {
    setOpenTAC(!openTAC)
  }, [openTAC])

  return (<>
    <Button $asText onClick={handleToggleTAC}>{t('globals.termsAndConditions')}</Button>
    <Modal
      onClose={handleToggleTAC}
      open={openTAC}
      titleChildren={
        <PageHeaderLogo lightBackground />
      }
    >
      {!termsAndConditions ? <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
        : <div>{pageContent}</div>}

    </Modal>
  </>

  );
}

export default ShowTermsAndConditions;

