import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect, Flex, Typo } from '../../ui';

const BuildSpotlightedCourses = ({
  allCourses,
  highlightedCourses,
  setHighlightedCourses
}) => {
  const { t } = useTranslation();
  return (
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.spotlightedCoursesTitle')}</Typo.ContentSmallHeader>
      <Typo.Text>{t('webshop.build.spotlightedCoursesText')}</Typo.Text>
      <SearchSelect
        multiple
        id="checkboxes-highlighted-courses"
        options={allCourses}
        selectedOptions={highlightedCourses}
        label={t('globals.courses')}
        style={{ width: 500 }}
        setValue={setHighlightedCourses}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Flex>
  );
};

export default BuildSpotlightedCourses;
