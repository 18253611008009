import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubPage, Loading, Typo, ListTable, Filter, Flex } from '../../../ui';
import { fetchTasks, selectStatus, selectTasks } from '../../tasks/tasksSlice';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TASK_TYPE } from '../../../utils/constants';
import { useCourseId } from '../../../hooks/readFromUrls';
import TaskResults from '../../tasks/components/TaskResults';
import { useParams } from 'react-router';

const Controls = styled(Flex)` 
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`

const CourseTasks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { taskId, userId } = useParams();

  const courseId = useCourseId();
  const taskStatus = useSelector(selectStatus);
  const allTasks = useSelector(selectTasks)
  const [localTasks, setLocalTasks] = useState(null);
  const [filter, setFilter] = useState('needsAttention');
  const [modalContent, setModalContent] = useState();

  const tasksFilters = [
    { value: '0', label: t('tasks.notCorrectedResponses'), property: 'needsAttention' },
    { value: '1', label: t('tasks.allResponses'), property: '' },
  ]

  useEffect(() => {
    setModalContent(taskId)
  }, [taskId])

  useEffect(() => {
    if (courseId) {
      dispatch(fetchTasks({ courseId }))
    }
  }, [courseId, dispatch])

  useEffect(() => {
    const filteredTasks = !!filter.length ?
      allTasks.filter(task => {
        return task[filter]?.length > 0
      }) : allTasks

    setLocalTasks(filteredTasks)
  }, [allTasks, filter])


  const [tableInfo, setTableInfo] = useState([
    { label: t('globals.tasks'), data: (item) => item.question },
    {
      label: t('globals.type'),
      hasSorting: { sortedMethod: 'number', propVal: 'type' },
      data: (item) => item.type === TASK_TYPE.MULTIPLE ? t('tasks.singleOption') :
        item.type === TASK_TYPE.SINGLE ? t('tasks.multipleOption') :
          item.type === TASK_TYPE.OPEN ? t('tasks.openQuestion') : t(`tasks.taskType_${item.type}`)
    },
    { label: t('tasks.totalResponses'), data: (item) => item.usersCount },
    { label: t('tasks.incorrectResponses'), data: (item) => item.incorrect.length },
    {
      label: t('tasks.notCorrectedResponses'),
      hasSorting: { sortedMethod: 'length', propVal: 'needsAttention' }, data: (item) => item.needsAttention.length
    },
  ])

  const openDetails = (task) => {
    setModalContent(task.id)
  }

  const onCloseDetails = () => {
    setModalContent()
  }

  const handleSelectedFilter = useCallback((value) => {
    const selectedFilter = tasksFilters.find((item) => item.value === value)
    setFilter(selectedFilter.property)
  }, [dispatch]);

  const selectedFilter = useMemo(() => tasksFilters.find((item) => item.property === filter), [tasksFilters, filter]);

  return (
    <SubPage align='flex-start'>
      {taskStatus === STATUS_LOADING && localTasks && localTasks[0]?.courseId !== courseId ?
        <Loading />
        : taskStatus === STATUS_FAILED ?
          <Typo.ContentSmallHeader>{t('globals.genericError')}</Typo.ContentSmallHeader>
          : taskStatus === STATUS_SUCCEEDED && !allTasks.length ?
            <Typo.ContentSmallHeader>{t('course.emptyTaskList')}</Typo.ContentSmallHeader>
            : allTasks.length && <Flex gap='40' direction='column' width='100%'>
              <Controls>
                <Filter info={tasksFilters} handleFilter={handleSelectedFilter} selected={selectedFilter.value} />
              </Controls>
              {!!localTasks?.length ?
                <ListTable
                  tableInfo={tableInfo}
                  data={localTasks}
                  onRowClick={openDetails}
                  tableName={'task-results'}
                  tableSetter={setTableInfo}
                /> : <Typo.ContentSmallHeader>{t('tasks.emptyResults')}</Typo.ContentSmallHeader>}
              <TaskResults userId={userId} taskId={modalContent} onClose={onCloseDetails} uncorrectedOnly={filter === 'needsAttention'} />
            </Flex>
      }
    </SubPage >
  );
};

export default CourseTasks;
