import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Icon, ListTable, Typo } from '../../../ui';
import Loading from '../../../ui/Loading';
import { STATUS_LOADING } from '../../../utils/constants';
import { selectCourseStatistics, selectUserGroups, selectStatus } from '../userSlice';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  svg {
    margin-right: 10px;
  }
`;

const Groups = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const GroupsGrid = styled.div`  
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

const GroupItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  padding: 10px 40px 10px 20px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Courses = styled.div`
  margin: 20px 0;
  width: 100%;
`;

// const Delete = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   cursor: pointer;
// `;

const User = ({ user }) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const [localStatistics, setLocalStatistics] = useState(null);
  const statistics = useSelector(selectCourseStatistics);
  const loadStatus = useSelector(selectStatus);
  const groups = useSelector(selectUserGroups)

  const [tableInfo, setTableInfo] = useState([
    { label: t('globals.name'), hasSorting: { sortedMethod: 'string', propVal: 'name' }, data: (item) => item.name },
    { label: t('users.progress'), hasSorting: { sortedMethod: 'number', propVal: 'progress' }, data: (item) => `${Math.ceil(item.progress * 100)}%` },
    { label: t('users.tasks'), hasSorting: { sortedMethod: 'number', propVal: 'tasksCorrect' }, data: (item) => `${item.tasksCorrect}/${item.taskTotal}` },
    { label: t('globals.quiz') },
    { label: t('users.pages'), hasSorting: { sortedMethod: 'number', propVal: 'pageViewed' }, data: (item) => item.pageViewed },
    { label: t('course.access'), data: (item) => item.ucpInfo.expires === '0000-00-00' ? t('course.forever') : item.ucpInfo.expires }
  ])

  // const removeUserFromCourse = useCallback((course) => {
  //   dispatch(removeFromCourse({ courseId: course.id, userId:usr.id }))
  // }, [dispatch, user])

  useEffect(() => {
    if (statistics.length) {
      setLocalStatistics(statistics)
    }
  }, [statistics])


  return (
    <>
      {!user ? <div>{t('globals.loading')}</div> :
        <>
          <InfoWrapper>
            <InfoRow>
              <Icon.Email />
              <Typo.Info>{user.email}</Typo.Info>
            </InfoRow>
            <InfoRow>
              <Icon.Time />
              <Typo.Info>{t('users.lastActive')}: {user.lastActive}</Typo.Info>
            </InfoRow>
          </InfoWrapper>

          <Groups>
            <Typo.ContentSmallHeader $textAlign='left' $marginBottom='10px'>{t('globals.groups')}</Typo.ContentSmallHeader>
            <GroupsGrid>
              {groups?.map((group) =>
                <GroupItem key={group.id}>
                  {group.name}
                </GroupItem>
              )}
            </GroupsGrid>
          </Groups>
          <Courses>
            <Typo.ContentSmallHeader $textAlign='left'>{t('globals.courses')}</Typo.ContentSmallHeader>
            {loadStatus === STATUS_LOADING ? <Loading /> :
              <ListTable
                tableInfo={tableInfo}
                data={localStatistics}
                tableName={'userCoursesStatistics'}
                // resultSetter={setLocalStatistics}
                tableSetter={setTableInfo}
                defaultRowPerPage={5}
              />}
          </Courses>

        </>
      }
    </>
  );
};

export default User;
