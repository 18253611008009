import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Figure, Icon } from '../../../ui';
import theme from '../../../ui/theme';
import coursioDefault from '../../../ui/assets/no_image.png';


export const ItemTypes = {
  All: 'all',
}

const IconContainer = styled.div`
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`

const FileThumbnail = ({ file, ...props }) => {

  const thumbnail = useMemo(() => {
    if (file.type === 'video') {
      const noExtensionUrl = file?.url?.substring(0, file?.url.lastIndexOf('.'))
      return `${noExtensionUrl}/frame_0001.jpg`
    }

    return null
  }, [file])

  const imageOnError = (event) => {
    event.currentTarget.src = coursioDefault;
  };

  return (<div {...props}>
    { file.type === 'image' && <Figure.Square $objectfit='cover'><img alt={'file'} id={file.id} src={file.url} loading='lazy' /></Figure.Square>}
    { file.type === 'audio' && <Figure.Square $objectfit='cover'><IconContainer width='100%'><Icon.AudioFile color={theme.colors.NEUTRAL_60} size='big' /></IconContainer></Figure.Square>}
    {/* this does not work with all of videos. why? some videos are actually sound */}
    {
      file.type === 'video' && <Figure.Square $objectfit='cover'><img alt={'file'} id={file.id}
        src={thumbnail} loading='lazy'
        onError={imageOnError}
      /></Figure.Square>
    }
    { file.type === 'other' && <Figure.Square $objectfit='cover'><IconContainer width='100%'><Icon.File color={theme.colors.NEUTRAL_60} size='big' /></IconContainer></Figure.Square>}
  </div >)
}

export default FileThumbnail;
