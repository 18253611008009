import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import da_DK from './i18n/da_DK.json';
import en_US from './i18n/en_US.json';
import fr_FR from './i18n/fr_FR.json';
import de_DE from './i18n/de_DE.json';
import fi_FI from './i18n/fi_FI.json';
import nb_NO from './i18n/nb_NO.json';
import pl_PL from './i18n/pl_PL.json';
import sv_SE from './i18n/sv_SE.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      'da_DK': da_DK,
      'en_US': en_US,
      'fr_FR': fr_FR,
      'de_DE': de_DE,
      'fi_FI': fi_FI,
      'nb_NO': nb_NO,
      'pl_PL': pl_PL,
      'sv_SE': sv_SE,
    },
    fallbackLng: 'en_US',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    nsSeparator: '.',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    }
  });
export default i18n;
