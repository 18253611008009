import React, { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Button, Flex, Textinput, Typo, SubPage } from '../../../ui';
import { CONTENT_MINI } from '../../../utils/media';
import { updateSettings } from '../../settings/settingsSlice';

const ProfilePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const passwordRef = useRef({});

  const savePassword = useCallback(() => {
    const { currentPassword, newPassword, confirmNewPass } = passwordRef.current;
    const settingsData = {
      newPassword: newPassword.value,
      currentPassword: currentPassword.value,
      passwordConfirm: confirmNewPass.value,
    }

    dispatch(updateSettings({ settingsData }));
  }, [dispatch])

  return (
    <SubPage size={CONTENT_MINI} align='flex-start' >
      <Typo.ContentSmallHeader $marginBottom='30px' $textAlign='left' >{t('profile.changePass')}</Typo.ContentSmallHeader>
      <Flex align='flex-start' width='80%' direction='column' >
        <Flex width='100%' gap='10'>
          <Textinput
            innerRef={el => passwordRef.current['currentPassword'] = el}
            label={t('profile.currentPassword')}
          />
        </Flex>
        <Flex width='100%' gap='10'>
          <Textinput
            innerRef={el => passwordRef.current['newPassword'] = el}
            label={t('profile.newPassword')}
          />
        </Flex>
        <Flex width='100%' gap='10'>
          <Textinput
            innerRef={el => passwordRef.current['confirmNewPass'] = el}
            label={t('profile.confirmNewPass')}
          />
        </Flex>
      </Flex>
      <Flex align='flex-end' gap='30' width='100%' direction='column'>
        <Typo.Text $textAlign='right' >{t('auth.forgotPassword')}</Typo.Text>
        <Button onClick={savePassword}>{t('globals.save')}</Button>
      </Flex>
    </SubPage >
  );
};

export default ProfilePassword;
