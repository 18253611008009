import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Flex, IconAsButton, ListTable, Modal, SearchBar, SubPage, Typo } from '../../../ui';
import debounce from '../../../helpers/debounce';
import { useNavigate } from 'react-router-dom';
import { changeAccessToCourse, fetchCountByCourseId, selectCourse } from '../courseSlice';
import { setInviteModalIsOpen } from '../../invites/invitesSlice';
import UserModal from '../../users/components/UserModal';
import { fetchUserByCourseId, removeUserFromCourse, selectCourseUsers, selectStatus } from "../../users/usersSlice";
import { selectSendStatus } from "../../invites/invitesSlice";
import { STATUS_LOADING } from '../../../utils/constants';
import Loading from '../../../ui/Loading';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { useTranslation } from 'react-i18next';
import { selectLocalization } from '../../auth/authSlice';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Controls = styled.div`
  width: 800px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;
const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;



const CourseUsers = ({ modal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef = useRef();

  const locale = useSelector(selectLocalization)
  const course = useSelector(selectCourse);
  const users = useSelector(selectCourseUsers);
  const loadStatus = useSelector(selectStatus);
  const sendInvitationStatus = useSelector(selectSendStatus);
  // const usersAmount = useSelector(selectUsersAmount);
  // const [accessLevelToEdit, setAccessLevelToEdit] = useState(null);
  // const userToEdit = useSelector((state) => selectCourseUserById(state, accessLevelToEdit?.userId));
  const [searchQuery, setSearchQuery] = useState('');
  const [userToRemove, setUserToRemove] = useState(null);
  const [endDate, setEndDate] = useState(dayjs());

  const [userToEditExpiration, setUserToEditExpiration] = useState(null);

  useEffect(() => {
    !!locale && dayjs.locale(locale)
  }, [locale])

  useEffect(() => {
    const user = userToEditExpiration;
    const date = user?.expires === '0000-00-00' ? dayjs() : dayjs(user?.expires)
    setEndDate(date)

  }, [userToEditExpiration])

  const openUser = useCallback((obj) => {
    navigate(`${obj.id}`)
  }, [navigate]);

  const removeFromCourse = useCallback(() => {
    dispatch(removeUserFromCourse({ courseId: course.id, userId: userToRemove.id })).then(() => setUserToRemove(null))
  }, [dispatch, course, userToRemove])

  const isNeverExpire = (date) => date === '2099-12-31' || date === '0000-00-00'

  const saveAccessPeriodEndDate = () => {
    dispatch(changeAccessToCourse({ expires: endDate.toISOString().split('T')[0], userId: userToEditExpiration.id, id: course.id }))
      .then(() => {
        dispatch(fetchUserByCourseId({ courseId: course.id, token: searchQuery }))
        setUserToEditExpiration(null)
      })
  }

  const tableInfo = [
    { label: t('globals.name'), data: (user) => user.name },
    { label: t('course.accessUntil'), data: (user) => isNeverExpire(user.expires) ? t('globals.forever') : user.expires },
    // { label: 'Is Manager' },
    // { label: t('globals.tasks') },
    // { label: t('globals.quizes') },
    // { label: 'Pages viewed' },
    // { label: 'Groups' },
    // { label: 'Progress' },
    {
      label: '', menuOptions: () => [
        { label: t('course.userDetails'), onClick: openUser },
        { label: t('course.editAccessExpire'), onClick: (user) => setUserToEditExpiration(user) },
        { label: t('course.removeUser'), onClick: (user) => setUserToRemove(user), variant: 'action' },
      ]
    },
  ];

  useEffect(() => {
    course.id && sendInvitationStatus !== STATUS_LOADING && dispatch(fetchUserByCourseId({ courseId: course.id, token: searchQuery }));
  }, [searchQuery, course.id, dispatch, sendInvitationStatus]);

  useEffect(() => {
    course.id && dispatch(fetchCountByCourseId({ courseId: course.id }));
  }, [course.id, dispatch]);

  const handleSearch = debounce((e) => {
    setSearchQuery(e.target.value);
  }, 1000);

  const clearSearch = useCallback(() => {
    searchRef.current.value = '';
    setSearchQuery('');
  }, [setSearchQuery]);

  const openInviteDialog = () => {
    dispatch(setInviteModalIsOpen({ course }))
  }

  return (
    <SubPage staticElements={
      <Header>
        <Flex justify={'space-between'} align={'flex-start'} >
          <Typo.ContentSmallHeader>{t('course.allUsers')}</Typo.ContentSmallHeader>
        </Flex>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('course.searchUser')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          <IconAsButton
            iconName='Invite'
            tooltipLabel={t('course.inviteUser')}
            clickHandle={openInviteDialog} />
        </Controls>
      </Header>
    }
    >
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={users}
          tableName={'courseUsers'}
          onRowClick={openUser}
        />}
      <UserModal open={!!modal} />

      <Modal
        titleHasLine
        open={!!userToEditExpiration}
        onClose={() => setUserToEditExpiration(null)}
        showActions={true}
        onAccept={saveAccessPeriodEndDate}
        onCancel={() => setUserToEditExpiration(null)}
        titleChildren={
          <Flex direction='column' align='flex-start' >
            <Typo.ContentSmallHeader>{t('course.editAccessExpire')}</Typo.ContentSmallHeader>
            <Typo.Text>{userToEditExpiration?.name}</Typo.Text>
          </Flex>
        }
      >
        {userToEditExpiration?.expires === '0000-00-00' ?
          <Typo.Text>{t('course.cantEditForever')}</Typo.Text>
          : <Flex direction='column' gap='10' align='flex-start'>
            <Typo.Text>{t('course.editExpireDate')}</Typo.Text>
            <Flex>
              {locale && <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DatePicker
                  format="LL"
                  label={t('globals.endDate')}
                  value={dayjs(endDate)}
                  onChange={(newValue) => {
                    const dateObj = new Date(newValue);
                    const dateFormat = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000);
                    setEndDate(dateFormat)
                  }}
                />
              </LocalizationProvider>}
            </Flex>
          </Flex>
        }
      </Modal>
      <ConfirmDelete
        onClose={setUserToRemove}
        onAcceptDelete={removeFromCourse}
        open={!!userToRemove}
        title={t('course.confirmRemoval')}
        description={t('course.confirmRemovalDescription', { userToRemove: userToRemove?.name, course: course?.name })}
      />
    </SubPage >
  );
};

export default CourseUsers;
