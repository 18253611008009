import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Icon, Flex, Typo, Avatar, Loading } from '../../../ui';
import { saveTaskFeedback, selectTaskById } from '../../tasks/tasksSlice';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem } from '@mui/material';
import EditFeedback from '../../tasks/components/EditFeedback';
import UserAnswer from '../../tasks/components/UserAnswer';
import AnswerStatusIcon from './AnswerStatusIcon';

const ListWrapper = styled.div` 
flex-direction:column;
  gap:30px; 
  align:flex-start; 
  width:100%;

  .CourseTasksTesultsList{
    > :first-child{
      z-index:2 !important;
    }
  }
  & .MuiAccordionSummary{
    gap:15px;
  }
  & li {
    border: 1px solid lightgray;
    padding: 10px 0;
    margin-bottom: 10px;
  }
`
const TaskResults = ({ taskId, userId, onClose, uncorrectedOnly }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const taskInfo = useSelector((state) => selectTaskById(state, taskId));
  const [modalContent, setModalContent] = useState({});
  const [expanded, setExpanded] = useState(userId);

  const handleOpenFeedback = (options) => {
    setModalContent({ ...options })
  }

  const onCloseEditFeedback = () => {
    setModalContent({})
  }

  const saveFeedback = feedbackData => {
    dispatch(saveTaskFeedback({ feedbackData, courseId: taskInfo.courseId }))
    onCloseEditFeedback()
  };

  const expandContent = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  let count = 0

  // MuiListItem-root

  return (
    <Modal
      id='TaskResult'
      open={!!taskInfo}
      onClose={onClose}
      title={
        <Flex gap='10' direction='column' align='flex-start' >
          <Typo.TextTitle>{taskInfo?.question}</Typo.TextTitle>
        </Flex>
      }
      compactOnMobile={true}

    >
      <ListWrapper >
        {!taskInfo ? <Loading /> :
          <>
            {!taskInfo.users?.length ? <Typo.ContentSmallHeader>{t('tasks.noUsersResults')}</Typo.ContentSmallHeader> :
              taskInfo.users?.map((user) => {
                if (!uncorrectedOnly || (uncorrectedOnly && user.lastAnswerGrade === undefined)) {
                  count += 1
                  return <Accordion
                    key={user.id + 'accordion'}
                    onChange={expandContent(user.id)}
                    expanded={+expanded === +user.id}
                  >
                    <AccordionSummary expandIcon={<Icon.ExpandMore />}>
                      <Flex gap='30' width='100%'>
                        <Avatar size='small' name={user.name} url={user.avatar} hasBadge>
                          <AnswerStatusIcon grade={user.lastAnswerGrade} taskType={taskInfo.type} />
                        </Avatar>
                        <Flex direction='column' align='flex-start'>
                          <Typo.TextTitle>{user.name}</Typo.TextTitle>
                          <Typo.Info>{`${user.lastUpdate.type === 'response' ? 'response submited' : 'feedback given'}: ${user.lastUpdate.date}`}</Typo.Info>
                        </Flex>
                      </Flex>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List sx={{ width: '100%' }}>
                        {Object.values(user?.answers).map((answer) => {
                          return <ListItem
                            key={user.id + '-' + answer.id}
                            disablePadding
                          >
                            <UserAnswer
                              key={answer.id}
                              handleOpenFeedback={() => handleOpenFeedback({ answer, userName: user.name })}
                              answer={answer}
                              taskType={user.taskType}
                            />
                          </ListItem>
                        }
                        )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                } else return null
              })}
            {count === 0 && !!taskInfo?.users?.length && <Typo.ContentSmallHeader>{t('tasks.emptyResults')}</Typo.ContentSmallHeader>}
          </>
        }
      </ListWrapper>
      <EditFeedback
        open={!!modalContent.response}
        onClose={onCloseEditFeedback}
        content={modalContent}
        onAccept={saveFeedback}
      />
    </Modal >
  );
};

export default TaskResults;
