import React from 'react';
import paymentImage from '../../ui/assets/payment_status.png';
import styled from 'styled-components/macro'
import { Section, SectionSeparation, Typo } from '../../ui';
import { CONTENT_SMALL } from '../../utils/media';
import Button from '../../ui/Button';
import {useTranslation} from "react-i18next";


const PaymentStatusContainer = styled.div`
  background: ${(props) => props.theme.colors.NEUTRAL_LIGHT};
  padding-top: 95px;
  padding-bottom: 130px;

  section {
    background-color: transparent;

    & > div {
      background-color: white;
      position: relative;
    }
  }
`;

const PaymentStatusHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background-color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};

  p:first-of-type {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.NEUTRAL_20};
    text-align: center;
    color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
    font-weight: 500;
    line-height: 30px;
  }

  p:last-of-type {
    color: ${(props) => props.theme.colors.NEUTRAL_20};
    font-weight: 400;
  }
`;

const PaymentStatusInfo = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 60px;
  }

  button {
    margin: 30px 0 60px 0;
  }
`;

const PaymentStatus = ({ paymentStatusData }) => {
  const { t } = useTranslation();

  return (
    <PaymentStatusContainer>
      <Section size={CONTENT_SMALL}>
        <PaymentStatusHeader>
          {/*what is that??*/}
          <Typo.Text>1</Typo.Text>
          <Typo.Text>Status</Typo.Text>
          {/* ?? */}
        </PaymentStatusHeader>
        <PaymentStatusInfo>
          {paymentImage && <img src={paymentImage} alt="payment-status" loading='lazy' />}
          <Typo.ContentBigHeader>{t('paymentStatus.thankYou')}!</Typo.ContentBigHeader>
          <Typo.Text>{t('paymentStatus.purchaseStatus', {courseName: paymentStatusData.course})}</Typo.Text>
          <Button>{t('paymentStatus.startCourse')}</Button>
          <SectionSeparation />
        </PaymentStatusInfo>
      </Section>
    </PaymentStatusContainer>
  );
};

export default PaymentStatus;
