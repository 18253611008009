

import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { salesExport } from '../exportSlice';
import { Flex, SubPage, Textinput, Loading, Typo, Button } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CONTENT_SMALL } from '../../../utils/media';
import { selectIsAdmin, selectLocalization } from '../../auth/authSlice';
import { CSVLink } from '../../createCsv';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';


const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const SalesExport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportData, setExportData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [customStoreId, setCustomStoreId] = useState(null)
  const locale = useSelector(selectLocalization);
  const storeIdRef = useRef(null);
  const isAmin = useSelector(selectIsAdmin)

  const generateReport = useCallback(() => {

    if (startDate && endDate) {
      setLoading(true)
      dispatch(salesExport({ customStoreId, startDate, endDate })).then((res) => {
        setLoading(false)
        setExportData(res.payload)
      })
    }
  }, [startDate, endDate, customStoreId, setLoading, dispatch, setExportData])

  const data = exportData?.map(item => ({
    '#': item.id,
    'Course id': item.object_id,
    'Created': item.created,
    'Type': item.type,
    'Description': item.description,
    'Price': item.amount,
    'TAX': item.tax,
    'Coursio cut': item.children.map(cut => cut.type === 'coursio_cut' && cut.amount),
  }))

  const handleStoreIdChange = useCallback(debounce(() => {
    const { current: { value } } = storeIdRef;

    if (value.length < 1)
      setCustomStoreId(null);
    else setCustomStoreId(value);

  }, 1000), [dispatch, setCustomStoreId]);
  return (
    <SubPage size={CONTENT_SMALL} >
      <Flex width={'100%'} gap={40} direction={'column'}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          {isAmin && <Textinput
            autoFocus
            margin='dense'
            id='storeId'
            label={'store id (option only available for admins)'}
            type='text'
            fullWidth
            inputRef={storeIdRef}
            onChange={handleStoreIdChange}
          />}
          <Flex gap='20'>
            <DatePicker
              label={t('globals.startDate')}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
            />
            <DatePicker
              label={t('globals.endDate')}
              value={endDate}
              onChange={newValue => setEndDate(newValue)}
            />
            <Button icon='Sync' disabled={!(startDate && endDate)} onClick={generateReport}>{t('export.generateReport')}</Button>
          </Flex>

        </LocalizationProvider>
      </Flex>
      <Controls>
        {loading ? <Loading /> :
          exportData?.length ? <CSVLink
            data={data}
            filename={`sales_${storeIdRef.current ? storeIdRef.current?.value : 'myStore'}_${dayjs(startDate).format('YYYY-MM-DD')}_${dayjs(endDate).format('YYYY-MM-DD')}.csv`}
          >
            {t('export.downloadReport')}
          </CSVLink> : <Typo.Text>{!!exportData ? t('export.noDataForDates') : t('export.selectDatesForReport')}</Typo.Text>}
      </Controls>
    </SubPage>

  );
}

export default SalesExport;

