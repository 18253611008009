import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsBundle } from '../../hooks/readFromUrls';
import { useParams, useNavigate } from 'react-router-dom';

import { fetchCourse, selectLayoutId } from './courseSlice';
import Loading from '../../ui/Loading';

const Purchase = () => {
  const navigate = useNavigate();
  const { courseSlug, name } = useParams();
  const dispatch = useDispatch();
  const isBundle = useIsBundle();
  const courseId = useSelector(selectLayoutId);

  useEffect(() => {
    dispatch(fetchCourse({ storeName: name, slugName: courseSlug, type: isBundle ? 2 : 1 }))
  }, []);

  useEffect(() => {
    if (courseId) {
      navigate(`/store/${name}/checkout?cart=[${courseId}]`);
    }
  }, [courseId]);

  return <Loading />;
};

export default Purchase;
