import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListCourses from '../../components/ListCourses';
import {
  fetchDashboard,
  selectFilterOptions,
  selectMyCoursesFiltered,
  setDashboardFilter,
  selectFilter,
  selectDashboardStatus
} from './dashboardSlice';
import styled from 'styled-components';
import debounce from '../../helpers/debounce';
import { Filter, Flex, SearchBar, Page, Toggler, Button, Loading } from '../../ui';
import { selectHasRequiredLevel, selectIsIncarnated } from '../auth/authSlice';
import { breakpoints, CONTENT_SMALL, media } from '../../utils/media';
import { useModule } from '../../hooks/readFromUrls';
import DashboardCourseCard from '../../ui/CourseCard/DashboardCourseCard';
import CreateCourseModel from '../course/components/CreateCourseModel';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const FilterTools = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 1rem;
  flex-wrap: wrap;
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:10px;
    flex-wrap: wrap;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const Dashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const myCourses = useSelector(selectMyCoursesFiltered);
  const dashboardStatus = useSelector(selectDashboardStatus);
  const module = useModule();
  const [sortMode, setSortMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const searchRef = useRef();
  const isIncarnated = useSelector(selectIsIncarnated);
  const filters = useSelector(selectFilterOptions);
  const selectedFilter = useSelector(selectFilter);

  const isEditor = useSelector((state) => selectHasRequiredLevel(state, 'editor'))

  const openModal = () =>
    setIsModalOpen(true)

  const closeModal = () =>
    setIsModalOpen(false)

  const handleSortMode = useCallback((e) => {
    setSortMode(e.target.checked)
  }, []);

  const handleSearch = debounce((e) => {
    setSearchQuery(e.target.value)
  }, 1000);

  const clearSearch = useCallback(() => {
    setSearchQuery('')
    searchRef.current.value = '';
  }, [setSearchQuery]);

  const handleOptions = useCallback((value) => {
    dispatch(setDashboardFilter(+value));
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchDashboard({ sortBy: sortMode ? 'created' : 'lastVisit', filters: [{ mode: 'name', value: searchQuery }] }));
  }, [dispatch, sortMode, searchQuery, isIncarnated])

  const fullScreen = window.innerWidth <= breakpoints.phablet;

  return (
    <Page size={CONTENT_SMALL} staticElements={
      <FilterTools>
        <FilterGroup>
          <Flex width={fullScreen ? '100%' : '70%'}>
            <Filter info={filters} handleFilter={handleOptions} selected={selectedFilter} />
          </Flex>
          <Flex width={fullScreen ? '100%' : '30%'}>
            <Toggler leftText={t('dashboard.lastVisited')} rightText={t('dashboard.lastCreated')} onClick={handleSortMode} />
          </Flex>
        </FilterGroup>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('dashboard.searchPlaceholder')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          {isEditor && <Button icon='Edit' onClick={openModal}>{t('course.createCourse')}</Button>}
        </Controls>
      </FilterTools>
    }>
      {
        dashboardStatus === STATUS_LOADING ? <Loading /> :
          <ListCourses showProgress={true} showButtons={false} module={module} items={myCourses} CardComponent={DashboardCourseCard} />
      }
      <CreateCourseModel open={isModalOpen} onClose={closeModal} />
    </Page>
  );
};

export default Dashboard;
