import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next';

import { ButtonGroup, MenuList, MenuItem, Paper, Grow, Popper, ClickAwayListener } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '..';


const SelectButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 45px;
    padding: 8px;
`

const LabelButton = styled(Button)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`

const SplitButton = ({ options, onSubmit, preselectedIndex }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useState(() => {
    preselectedIndex >= 0 ? setSelectedIndex(preselectedIndex) : setSelectedIndex(0)
  }, [preselectedIndex, setSelectedIndex])

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]?.label}`);
    onSubmit(options[selectedIndex])
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };


  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" disableElevation>
        <LabelButton onClick={handleClick} $variant='customized' $bgcolor={options[selectedIndex]?.color} >{options[selectedIndex]?.label}</LabelButton>
        <SelectButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select option to submit"
          aria-haspopup="menu"
          onClick={handleToggle}
          $variant='customized'
        >
          <ArrowDropDownIcon />
        </SelectButton>

      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>

  );
};

export default SplitButton;
