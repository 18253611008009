import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro'
import { selectAllItems, selectCartItemsSum, selectIsValidDiscount } from '../../cartSlice';
import { fetchStore, selectCurrentStoreHideVat, selectDiscountCode, setDiscountCode } from '../../globalSlice';
import { Typo, Flex } from '../../../../ui';
import { media } from '../../../../utils/media';
import Discount from './Discount';
import { useTranslation } from 'react-i18next';
import { useWebshopName } from "../../../../hooks/readFromUrls";
import BuyAsGift from './BuyAsGift';
import CartItemsList from './CartItemsList';
import { selectIsAGift } from '../../paymentsSlice';

const CheckoutCartWrapper = styled.div`
  width: 40%;
  padding: 0 2rem;

  &.locked {
    pointer-events: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  ${media.lessThan('tablet')} {
    width: 100%;
    padding: 0;
  }
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  margin: 0 0 1rem;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.3rem 0;
  width:50%;
  
  &.discount {
    margin: 0;
    p { color: ${(props) => props.theme.colors.ACTION}; }  
  }
`;

const RemoveWrapper = styled(Typo.SmallText)`
  cursor: pointer;
  padding-bottom: 1rem;
  :hover {
    text-decoration: underline;
  }
`;

const CheckoutCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeHideVat = useSelector(selectCurrentStoreHideVat);
  const cartItems = useSelector(selectAllItems);
  const discountCode = useSelector(selectDiscountCode);
  const isValidDiscount = useSelector(selectIsValidDiscount);
  const cartItemsGrossSum = useSelector((state) => selectCartItemsSum(state, 'gross'));
  const cartItemsNetSum = useSelector((state) => selectCartItemsSum(state, 'net'));
  const cartItemsDiscountedSum = useSelector((state) => selectCartItemsSum(state, 'discounted'));
  const webshop = useWebshopName();
  const isAGift = useSelector(selectIsAGift);

  const [storeCurrency, setStoreCurrency] = useState('');

  useEffect(() => {
    !!cartItems?.length && !storeCurrency && setStoreCurrency(cartItems[0]?.currency)
  }, [cartItems, storeCurrency])

  const clearDiscount = useCallback(() => {
    dispatch(setDiscountCode(null));
    dispatch(fetchStore({ store: webshop }))
  }, [dispatch, webshop])

  return (
    <CheckoutCartWrapper>
      <CartItemsList cartItems={cartItems} storeHideVat={storeHideVat} />

      <Summary>
        {isValidDiscount &&
          <Flex width='100%' direction='column' align='flex-end'>
            <Typo.TextTitle>{cartItemsGrossSum.toLocaleString()} {storeCurrency}</Typo.TextTitle>
            <Flex width='100%' justify='space-between' align='flex-end'>
              <Typo.Text>{t('webshop.checkout.discountCode')}: {discountCode}</Typo.Text>
              <Typo.Text $textAlign='right'>-{(cartItemsGrossSum - cartItemsDiscountedSum).toLocaleString()} {storeCurrency}</Typo.Text>
            </Flex>
            <RemoveWrapper onClick={clearDiscount}>
              {t('webshop.checkout.removeDiscount')}
            </RemoveWrapper>
          </Flex>
        }
        <Flex align='flex-end' direction='column'>
          <PriceWrapper>
            <Typo.TextTitle>{t('globals.total')}</Typo.TextTitle>
            <Typo.TextTitle>{cartItemsDiscountedSum.toLocaleString()} {storeCurrency}</Typo.TextTitle>
          </PriceWrapper>
          {cartItemsDiscountedSum > 0 && <Flex justify='space-between' width='50%'>
            <Typo.SmallText>{t('globals.vatIncluded')} </Typo.SmallText>
            <Typo.SmallText>{(cartItemsGrossSum - cartItemsNetSum).toLocaleString()} {storeCurrency}</Typo.SmallText>
          </Flex>}
        </Flex>
      </Summary>
      <BuyAsGift isAGift={isAGift} />

      { !isValidDiscount && <Discount clearDiscount={clearDiscount} />}

    </CheckoutCartWrapper >
  );
};

export default CheckoutCart;
