import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import VideoPlayer from '../../../ui/VideoPlayer';
import { Button, Figure, Typo, Modal, Flex } from '../../../ui';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { media } from '../../../utils/media';
import { deleteFile, selectFileById } from '../mediaSlice';
import { useTranslation } from 'react-i18next';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Image = styled(Figure.Landscape)`
  background: repeating-conic-gradient(${props => props.theme.colors.NEUTRAL_LIGHT} 0% 25%, transparent 0% 50%) 0% / 25px 25px;
  img { object-fit: contain; }
`;

const MyDocViewer = styled(DocViewer)`
  min-height:600px;
  ${media.lessThan('phablet')} {
    max-height:440px;
  }
`;

const FileContent = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:10px;
    align-items: center;
  }
`;

const FileDetails = ({ fileId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const urlSplit = location.pathname.split('/')
  const id = urlSplit[3];
  const file = useSelector((state) => selectFileById(state, id));

  const closeModal = useCallback(() => {
    navigate(-1)
  }, [navigate]);

  const handleDeleteDialog = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen]);

  const onDeleteFile = useCallback(() => {
    dispatch(deleteFile({ id: file.id })).then((res) => {
      res.payload && closeModal()
    })
    setDialogOpen(!dialogOpen)
  }, [dispatch, file, setDialogOpen, dialogOpen, closeModal]);

  const downloadFile = useCallback(() => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', file.url, true);
    xhr.responseType = 'blob';
    xhr.send();
    xhr.onload = () => {
      const blob = new Blob([xhr.response], { type: xhr.type });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';
      a.rel = 'noreferrer';
      a.download = file.name;
      a.click()
    };
  }, [file]);

  return (
    <Modal
      open={!!fileId}
      width='1100px'
      maxWidth='lg'
      onClose={closeModal}
      dialogActionsChildren={<>
        <FileContent>
          <Flex direction={'column'} align={'flex-start'}>
            <Typo.Text><strong>{t('globals.name')}:</strong> {file?.name}</Typo.Text>
            <Typo.Text><strong>{t('globals.created')}:</strong> {file?.created}</Typo.Text>
            <Typo.Text><strong>{t('globals.size')}:</strong> {(file?.size / 1000000).toFixed(4)}MB</Typo.Text>
          </Flex>
          <Flex gap='20'>
            <Button icon='Download' onClick={downloadFile}>{t('globals.download')}</Button>
            <Button icon='Delete' onClick={handleDeleteDialog}>{t('globals.delete')}</Button>
          </Flex>
        </FileContent>

        <ConfirmDelete
          open={dialogOpen}
          title={t('mediaLibrary.deleteConfirmationTitle')}
          description={t('mediaLibrary.deleteConfirmationDescription')}
          onClose={handleDeleteDialog}
          onAcceptDelete={onDeleteFile}
        /></>}
    >
      {file?.type === 'video' && <Figure.Video> <VideoPlayer previewContent={file?.url} /> </Figure.Video>}
      {file?.type === 'audio' && <VideoPlayer width='100%' height='100px' previewContent={file?.url} />}
      {file?.type === 'image' && <Image> <img alt={'file'} id={file?.id} src={file?.url} loading='lazy' /> </Image>}
      {file?.type === 'other' &&
        <MyDocViewer
          documents={[{ uri: file.url, fileType: file.mime }]}
          prefetchMethod={'GET'}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
          pluginRenderers={DocViewerRenderers}
        />
      }
    </Modal >
  )
}

export default FileDetails;
