import React from 'react';
import styled from 'styled-components'
import { media } from '../../../../utils/media';

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
  gap:30px;
  width:100%;
  margin:1rem;

  ${media.lessThan('tablet')} {
    padding: 3rem 0;
  }
`

const MessageContainer = ({ children }) => {
  return (
    <Wrapper >
      {children}
    </Wrapper>
  );
};

export default MessageContainer;

