import React from 'react';
import styled from 'styled-components/macro';

import { Flex, Typo, Icon, Button, theme } from '../../../ui';
import DownloadableAttach from './DownloadableAttach';
import { useTranslation } from 'react-i18next';

const FeedbackContainer = styled.div`
  display:flex;
  margin-top:16px;
  align-items:flex-start;
  gap:15px;
  background-color: ${theme.colors.NEUTRAL_LIGHT};
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;
`
const IconWrapper = styled.div`
  display: flex;
  padding-top:10px;

`

const StyledButton = styled(Button)`
align-self: flex-end;
`

const TeacherFeedback = ({ feedback, handleOpenFeedback }) => {
  const { t } = useTranslation();

  return (
    feedback?.created ?
      <>
        <FeedbackContainer>
          <IconWrapper>
            <Icon.Feedback size='small' color={theme.colors.NEUTRAL_60} />
          </IconWrapper>
          <Flex gap='10' direction='column' align='flex-start' >
            <Flex direction='column' align='flex-start' gap='10'>
              <Typo.Text>{feedback?.text}</Typo.Text>
              <Typo.Info>{feedback?.created}</Typo.Info>
              <DownloadableAttach files={feedback.files} />
            </Flex>
          </Flex>

        </FeedbackContainer>
        <StyledButton onClick={handleOpenFeedback}>{t('tasks.editFeedback')}</StyledButton>
      </>
      : <StyledButton onClick={handleOpenFeedback}>{t('tasks.giveFeedback')}</StyledButton>

  );
};

export default TeacherFeedback;
