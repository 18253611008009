import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import { diplomaApi, encodedApi, plainApi } from '../../utils/urls';
import { selectAuthToken } from '../auth/authSlice';
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/constants";
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

// check
export const imageUpload = createAsyncThunk('file/plain', async ({ file, mockedType }, { getState }) => {
  const fd = new FormData();
  fd.append('file', file, file.name);

  const options = {
    method: 'POST',
    headers: {
      token: selectAuthToken(getState()),
    },
    body: fd,
  };
  const url = plainApi();
  const response = await fetch(url, options);
  const res = await response.json();

  if (res.isError) {
    enqueueSnackbar(res.errMsg, { variant: TOAST_ERROR });
  }

  return { ...res.data[0].file, mockedType };
})

export const fileUpload = createAsyncThunk('file/fileUpload', async (file, { getState, dispatch, rejectWithValue }) => {
  const fd = new FormData();
  fd.append('file', file, file.name);

  const res = await httpClient.post(encodedApi(), fd, getState, dispatch, rejectWithValue);

  return res.data;
})

export const diplomaPreview = createAsyncThunk('diploma/diplomaPreview', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { courseId },
    method: 'mail'
  };

  const res = await httpClient.post(diplomaApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Diploma will be generated and mailed to your email address.';
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS;
  enqueueSnackbar(message, { variant });

  return res.data;
});

// ----------------- Reducers -----------------------------

const initialState = {
  file: null,
  image: null,
  mockedTypes: ['single', 'highlightedInfo'],
  status: 'none'
}

const fileSlice = createSlice({
  name: 'fileUploader',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      // ---- FILE UPLOAD -----
      .addCase(fileUpload.pending, (state, action) => {
        state.status = 'fileLoading'
      })
      .addCase(fileUpload.rejected, (state, action) => {
        state.status = 'failed';
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(fileUpload.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.file = action.payload[0].file;
        enqueueSnackbar('File was successfully uploaded', { variant: TOAST_SUCCESS });
      })
      .addCase('clearFile', (state) => {
        state.file = null
      })

      // ---- IMAGE UPLOAD -----
      .addCase(imageUpload.pending, (state, action) => {
        // state.status = 'loading'
      })
      .addCase(imageUpload.rejected, (state, action) => {
        // state.status = 'failed'
      })
      .addCase(imageUpload.fulfilled, (state, action) => {
        console.log('IMAGE UPLOAD - SUCCEEDED')
        state.image = action.payload;
      })
      .addCase('clearImage', (state) => {
        state.image = null
      })

      // ---- DIPLOMA -----
      .addCase(diplomaPreview.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(diplomaPreview.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(diplomaPreview.fulfilled, (state) => {
        state.status = 'succeeded';
        console.log('DIPLOMA SENT - SUCCEEDED')
      })
  }
})

export default fileSlice.reducer

// ----------------- Selectors -----------------------------

export const selectFile = state => state.fileUploader.file;
export const selectImage = state => state.fileUploader.image;
export const selectFileStatus = state => state.fileUploader.status;

