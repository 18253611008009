import React from 'react';
import { Typo, Flex, Figure } from '../../../ui';
import VideoUploader from '../../media/uploaders/VideoUploader';
import { useTranslation } from 'react-i18next';
import ImageUploader from '../../media/uploaders/ImageUploader';

const CoverMediaLayout = ({ coverImage, promotionVideo, setPromotionVideo, saveVideo, saveImage }) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' width='100%' align='flex-start' gap='40'>
      <Typo.SectionHeader $marginBottom='10'>{t('webshop.build.coverVideoTitle')}</Typo.SectionHeader>
      <Flex direction='column' gap='10' align='flex-start'>
        <Typo.Text>{t('webshop.build.coverVideoDescription_1')}</Typo.Text>
        <Typo.Info>{t('webshop.build.coverVideoDescription_2')}</Typo.Info>
        <Typo.Info>{t('webshop.build.coverVideoDescription_3')}</Typo.Info>
        <VideoUploader
          saveAction={saveVideo}
          videoToPreview={promotionVideo}
          trackDeleted={setPromotionVideo}
        />
      </Flex>
      <Flex direction='column' gap='10' align='flex-start'>
        <Typo.MediumText>{t('webshop.build.coverImageTitle')}</Typo.MediumText>
        <ImageUploader
          saveByDelete
          type={'single'}
          id={'poster-image'}
          saveAction={saveImage}
          imageToPreview={coverImage?.url}
        >
          {coverImage?.url && <Figure.Rectangle><img alt={'imageToPreview'} src={coverImage.url} loading='lazy' /></Figure.Rectangle>}
        </ImageUploader>
      </Flex>
    </Flex>
  );
};

export default CoverMediaLayout;
