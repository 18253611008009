import React from 'react';
import PaymentStatus from '../../components/PaymentStatus';
import styled from 'styled-components/macro'

const StatusContainer = styled.div`
  height: 100%;
`;

const paymentStatusData = {
  status: 'success',
  course: 'Snabbt smal med L7-dieten'
}

const PaymentStatusContainer = () => {

  return (
    <StatusContainer>
      <PaymentStatus paymentStatusData={paymentStatusData} />
    </StatusContainer>
  );
};

export default PaymentStatusContainer;
