import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Flex, Loading, Typo } from '../../ui';
import styled from 'styled-components'
import { completePaymentProcess, selectPaymentCompleteData, selectPaymentCompleteStatus } from './paymentsSlice';
import { guestRegister, selectAuthState } from '../auth/authSlice';
import { STATUS_LOADING } from '../../utils/constants';
import CartItemsList from './components/Checkout/CartItemsList';
import MessageContainer from './components/Checkout/MessageContainer';
import BuyAsGift from './components/Checkout/BuyAsGift';
import { useTranslation } from 'react-i18next';
import { media } from '../../utils/media';
import RegistrationForm from '../../components/RegistrationForm';


const DescriptionContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  gap:30px;
  ${media.lessThan('laptop')} {
    padding: 0 1.5rem;
  }
`

const FormWrapper = styled(Flex)`
  margin-bottom:3rem;
`

const PaymentConfirmed = ({ providerId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { hash } = useParams();
  const authState = useSelector(selectAuthState);
  const klarnaRef = useRef(null);
  const paymentCompleteData = useSelector(selectPaymentCompleteData);
  const paymentStatus = useSelector(selectPaymentCompleteStatus);

  const [cartItems, setCartItems] = useState([]);
  const [isForGift, setIsforGift] = useState(false);

  // 19049d34-36b0-44ca-94e5-6800bd941119

  useEffect(() => {
    if (authState.loggedIn) {
      const userInfo = authState.userInfo?.user
      if (userInfo.email === paymentCompleteData?.email)
        navigate('/dashboard')
    }
  }, [authState, paymentCompleteData])

  useEffect(() => {
    dispatch(completePaymentProcess({ hash, providerId }))
  }, [hash, dispatch, providerId])

  useEffect(() => {
    let LST = JSON.parse(localStorage.getItem('storeCartTransaction'));
    if (providerId === 9) {
      LST = LST[hash];
    } else {
      LST = LST[paymentCompleteData.sessionId]
    }
    LST?.cart && setCartItems(LST?.cart);
    setIsforGift(LST?.isGift)
  }, [setCartItems, hash, paymentCompleteData.sessionId])

  useEffect(() => {
    if (paymentCompleteData?.snippet?.length > 0 && providerId === 9) {
      const { snippet } = paymentCompleteData;
      const node = klarnaRef.current;
      node.innerHTML = snippet;
      const klarnaCode = node.getElementsByTagName('script')[0].text; //node.children[0].children[0].text;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      try {
        script.appendChild(document.createTextNode(klarnaCode));
      } catch (e) {
        script.text = klarnaCode;
      }
      node.appendChild(script);
    }
  }, [paymentCompleteData, providerId]);

  return (
    paymentStatus === STATUS_LOADING ? <Loading /> :
      paymentCompleteData?.isError ?
        <MessageContainer>
          <Typo.TextTitle>{t('webshop.checkout.completePaymentError')}</Typo.TextTitle>
          <Typo.TextTitle>{paymentCompleteData.errMsg}</Typo.TextTitle>
        </MessageContainer>
        :
        <>
          {klarnaRef && <div ref={el => klarnaRef.current = el} />}
          {!!cartItems.length && <DescriptionContainer>
            <Typo.TextTitle>{t('webshop.checkout.confirmPaymentCourses')}</Typo.TextTitle>
            <CartItemsList cartItems={cartItems} noDelete={true} />

            <FormWrapper direction='column' align='flex-start' gap='30'>
              {isForGift ? <BuyAsGift isAGift={isForGift} afterPayment />
                : <>
                  <Typo.TextTitle>{t('webshop.checkout.confirmPaymentEmail')}</Typo.TextTitle>
                  {!authState.loggedIn &&
                    <RegistrationForm email={paymentCompleteData?.email} handleLogin={() => navigate('/login')} createAccountCall={guestRegister} />
                  }
                </>
              }
            </FormWrapper>
          </DescriptionContainer>}
        </>
  );
};

export default PaymentConfirmed;
