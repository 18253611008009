import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { Textinput, Typo, SubPage, Flex, Button } from '../../../ui';
import BuildInfoIcons from '../../../components/InfoIconSection/BuildInfoIcons';
import BuildInfoPoints from '../../../components/InfoPointsSection/BuildInfoPoints';
import BuildSuggestedCourse from '../../../components/SuggestedCourse/BuildSuggestedCourse';
import BuildSpotlightedCourses from '../../../components/SpotlightedCourses/BuildSpotlightedCourses';
// import BuildTestimonies from '../../../components/Testimonies/BuildTestimonies';
import { saveLayout, selectLayout } from '../homeSlice';
import { selectStoreName } from '../globalSlice';
import { selectCourseById, selectCourses, selectHighlightedCoursesInfo } from '../../webshop/coursesSlice';
import { useTranslation } from 'react-i18next';
import { CONTENT_SMALL } from '../../../utils/media';
import { selectMyStore } from '../../auth/authSlice';
import { useNavigate } from 'react-router';
import CoverMediaLayout from './CoverMediaLayout';

export const cleanHighlightedCourses = (data) => {
  return data.map((item) => {
    return item.id
  })
}

const EditStoreFront = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const myStore = useSelector(selectMyStore)
  const layoutStructure = useSelector(selectLayout);
  const courses = useSelector(selectCourses);
  const HighlightedCoursesInfo = useSelector(selectHighlightedCoursesInfo);
  const storeName = useSelector(selectStoreName);
  const [introInfo, setIntroInfo] = useState({});
  const [suggestedLocal, setSuggested] = useState('');
  const [infoIcons, setInfoIcons] = useState([]);
  const [infoPoints, setInfoPoints] = useState([]);
  const [highlightedCourses, setHighlightedCourses] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [promotionVideo, setPromotionVideo] = useState({ id: null, url: null });
  const [needToBeSaved, setNeedToBeSaved] = useState(false);
  // const [testimonies, setTestimonies] = useState([]);

  const suggestedCourse = useSelector(state => selectCourseById(state, layoutStructure.suggestedCourse))

  useEffect(() => {
    if (layoutStructure) {
      const tempLayoutStructure = cloneDeep(layoutStructure)
      setIntroInfo(tempLayoutStructure.introduction);
      setInfoIcons(tempLayoutStructure.infoIcons);
      setSuggested(suggestedCourse);
      setInfoPoints(tempLayoutStructure.infoPoints);
      setHighlightedCourses(cloneDeep(HighlightedCoursesInfo));
      setCoverImage(tempLayoutStructure.coverMedia?.image)
      setPromotionVideo(tempLayoutStructure.coverMedia.video)
      // setTestimonies(tempLayoutStructure.testimonies)
    }
  }, [layoutStructure, courses, HighlightedCoursesInfo, suggestedCourse]);

  useEffect(() => {
    let needSave = false
    if (JSON.stringify(introInfo) !== JSON.stringify(layoutStructure.introduction) ||
      JSON.stringify(infoIcons) !== JSON.stringify(layoutStructure.infoIcons) ||
      JSON.stringify(suggestedLocal) !== JSON.stringify(suggestedCourse) ||
      JSON.stringify(infoPoints) !== JSON.stringify(layoutStructure.infoPoints) ||
      JSON.stringify(highlightedCourses) !== JSON.stringify(HighlightedCoursesInfo) ||
      JSON.stringify(coverImage) !== JSON.stringify(layoutStructure.coverMedia?.image) ||
      JSON.stringify(promotionVideo) !== JSON.stringify(layoutStructure.coverMedia.video)
    ) needSave = true

    setNeedToBeSaved(needSave)
  }, [layoutStructure, courses, HighlightedCoursesInfo, suggestedCourse, introInfo, infoIcons, suggestedLocal, infoPoints, highlightedCourses, coverImage, promotionVideo])


  const editIntroInfo = useCallback(e => {
    setIntroInfo({ ...introInfo, [e.target.dataset.propname]: e.target.value });
  }, [introInfo]);

  const removeMockedIds = useCallback((data) => {
    return data.map(el => {
      if (el.hasOwnProperty('id')) {
        delete el.id;
      }
      return el;
    })
  }, [])

  const saveStructure = useCallback(() => {
    let newLayout = {
      coverMedia: { video: promotionVideo, image: coverImage },
      introduction: introInfo,
      infoPoints: removeMockedIds(cloneDeep(infoPoints)),
      infoIcons: removeMockedIds(cloneDeep(infoIcons)),
      suggestedCourse: suggestedLocal?.id,
      highlightedCourses: cleanHighlightedCourses(highlightedCourses),
      // testimonies: testimonies
    }
    dispatch(saveLayout({ storeName, layoutData: newLayout }));
  }, [dispatch, storeName, introInfo, infoPoints, suggestedLocal, highlightedCourses, infoIcons, coverImage, removeMockedIds, promotionVideo]);

  const saveVideo = useCallback((video) => {
    setPromotionVideo(video)
  }, [])

  const saveImageCoverToLayout = useCallback((image) => {
    if (image?.id === -1)
      setCoverImage(null)
    else setCoverImage(image)
  }, [])

  return (
    <SubPage align='flex-start' gap='50' size={CONTENT_SMALL} staticElements={
      <Flex justify='flex-end' width='100%' gap='30'>
        <Button icon='Save' onClick={saveStructure} disabled={!needToBeSaved} >{t('globals.save')}</Button>
        <Button icon='Visible' onClick={() => navigate(`/store/${myStore}`)} disabled={needToBeSaved} >{t('webshop.viewStore')}</Button>
      </Flex>
    }>
      <CoverMediaLayout
        coverImage={coverImage}
        promotionVideo={promotionVideo}
        saveVideo={saveVideo}
        saveImage={saveImageCoverToLayout}
        setPromotionVideo={setPromotionVideo}
      />
      <BuildInfoIcons infoIcons={infoIcons} setInfoIcons={setInfoIcons} />

      <Flex direction='column' width='100%' align='flex-start'>
        <Typo.SectionHeader $marginBottom='10'>{t('webshop.build.introductionTitle')}</Typo.SectionHeader>
        <Textinput
          label={t('globals.title')}
          propName={'title'}
          defaultValue={layoutStructure.introduction?.title}
          onBlurValue={editIntroInfo}
        />
        <Textinput
          label={t('globals.description')}
          propName={'text'}
          defaultValue={layoutStructure.introduction?.text}
          onBlurValue={editIntroInfo}
          isMultiline
        />
      </Flex>


      <BuildSpotlightedCourses allCourses={courses} highlightedCourses={highlightedCourses} setHighlightedCourses={setHighlightedCourses} />

      <BuildInfoPoints setInfoPoints={setInfoPoints} infoPoints={infoPoints} />

      {/* <BuildTestimonies setTestimonies={setTestimonies} testimonies={testimonies} /> */}
      <BuildSuggestedCourse allCourses={courses} setSuggested={setSuggested} suggestedLocal={suggestedLocal} />


    </SubPage>
  );
};

export default EditStoreFront;
