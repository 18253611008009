import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro'

import { Typo, Icon, Button, Textinput, Flex } from '../../../../ui';
import { media } from '../../../../utils/media';
import { diplomaPreview } from '../../../fileUploader/fileSlice';
import FileUploader from '../../../media/uploaders/FileUploader';
import { updateCourse } from '../../courseSlice';

const ButtonsWrapper = styled.div`
  margin-top:1rem;
  display: flex;
  width:100%;
  justify-content: flex-end;
  
  button {
    margin-left:0.5rem;
  }
`
const DiplomaFile = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  width:100%;
  ${media.lessThan('laptop')} {
    flex-direction:column;
    gap:10px;
  }
`

const FlexContainer = styled.div` 
  display: flex; 
  align-items: center;
`

const CourseDiplomaEdit = ({ course }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const customEmail = searchParams.get('recipientEmail');
  const [diploma, setDiploma] = useState(null);

  useEffect(() => {
    if (course?.settings?.diploma?.hasOwnProperty('fileId') && course.settings.diploma.fileId) {
      setDiploma(course.settings?.diploma)
    } else {
      setDiploma(null);
    }
  }, [course]);

  const onSaveDiploma = useCallback((diploma) => {
    const { fileId, passPercentage } = diploma;

    const courseData = {
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          fileId,
          passPercentage
        }
      }
    };

    dispatch(updateCourse({ courseData }));
  }, [course, dispatch]);

  const onRemoveDiploma = useCallback(() => {
    const courseData = {
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          fileId: null
        }
      }
    };

    dispatch(updateCourse({ courseData }));
  }, [course, dispatch]);

  const onSavePassPercentage = useCallback((e) => {
    const courseData = {
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          ...course.settings.diploma,
          passPercentage: e.target.value
        }
      }
    };

    dispatch(updateCourse({ courseData }))
  }, [course, dispatch])

  const sendTestDiploma = useCallback(() => {
    dispatch(diplomaPreview({ courseId: course.id, recipientEmail: customEmail }));
  }, [dispatch, course, customEmail])

  const saveDiploma = useCallback((file) => {
    file && onSaveDiploma({ fileId: file.id, passPercentage: 80 })
  }, [onSaveDiploma])

  return (
    <Flex width='100%' direction='column' align='flex-start' gap='10'>
      <Typo.ContentBigHeader $textAlign='left'>{t('globals.diploma')}</Typo.ContentBigHeader>
      {diploma?.fileId ?
        <>
          <DiplomaFile>
            <FlexContainer>
              <Icon.Certificate />
              <Typo.Text>{t('globals.diploma')}</Typo.Text>
            </FlexContainer>
            <div>
              <Typo.Text>{t('course.percentageForDiploma')}</Typo.Text>
              <Textinput
                defaultValue={diploma.passPercentage}
                onBlur={onSavePassPercentage}
              />
            </div>
          </DiplomaFile>
          <ButtonsWrapper>
            <Button onClick={onRemoveDiploma}>{t('course.removeDiploma')}</Button>
            <Button onClick={sendTestDiploma}>{t('course.previewDiploma')}</Button>
          </ButtonsWrapper>
        </>
        : <>
          <FileUploader id={'diplomaTemplate'} saveAction={saveDiploma} fileType='other' />
        </>
      }
    </Flex>
  );
};

export default CourseDiplomaEdit;
