import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import "swiper/swiper-bundle.min.css";
import { getUserThemeColor, getUserThemeContrastColor } from '../theme/colors';
import theme from '../theme';

const CarouselWrapper = styled(Swiper)`
  width: 100%;
  padding-bottom: 35px;

  .swiper-pagination-bullet {
    border-radius: 50px;
    width: 4px;
    height: 4px;
    transition: all 0.25s cubic-bezier(1, 0.06, 0.58, 1.2);
    opacity: ${(props) => props.$opacity};
    background: ${(props) => props.$constrast};
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    width: 24px;
    background: ${(props) => props.$background};
  }
`;

const Carousel = ({ children, themeColor, bgColor, ...props }) => {
  return (
    <CarouselWrapper
      $background={themeColor !== bgColor ? getUserThemeColor(themeColor) : getUserThemeContrastColor(themeColor)}
      $constrast={themeColor === bgColor ? getUserThemeContrastColor(themeColor) : theme.colors.NEUTRAL_60}
      $opacity={themeColor !== bgColor ? 1 : 0.5}
      modules={[Pagination, Autoplay]}
      {...props}
    >
      {/* Wrap every child in a swiperslide */}
      {children.map((child, i) => <SwiperSlide key={i}>{child}</SwiperSlide>)}
    </CarouselWrapper>
  );
}

export default Carousel;
