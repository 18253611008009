import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro'

import { useSelector, useDispatch } from 'react-redux';
// import { fetchMyAPI } from './globalSlice';
import {
  fetchStore,
  setStoreName,
  // selectStoreName,
  // fetchProviders,
  selectDiscountCode,
} from '../webshop/globalSlice';
import { selectAuthState, setIsIncarnated } from '../auth/authSlice';
import MenuSlider from '../../components/MenuSlider';
import IncarnateModal from '../../components/IncarnateModal';
// import LoginModal from '../../components/LoginModal';
import HeaderContainer from './HeaderContainer';
import FooterContainer from './Footer';
import { fetchUnreadNotifications } from '../../features/notifications/notificationsSlice';
import ScrollToTop from '../../utils/browserScroll';
// import Cart from '../webshop/components/Cart';
import SendInvite from '../invites/components/SendInvite';
import { fetchSettings, fetchStoreCurrency } from '../settings/settingsSlice';
import { useModule, useWebshopName } from '../../hooks/readFromUrls';
// import { selectStatus } from '../webshop/courseSlice';
import LoginModal from '../auth/components/LoginModal';
import { useNoHeaderModules } from '../../hooks/noHeaderModules';
import ManageCookiesBanner from '../manageCookiesBanner';
import WarningMessages from './WarningMessages';

const ContentWrapp = styled.div`
  min-height: ${(props) => props.$noHeader ? '100vh' : `calc(100vh - ${props.theme.headerSize})`} ;
  padding-top: ${(props) => props.$noHeader ? '0' : props.theme.headerSize};
`

const WrappContainer = ({ children }) => {

  const dispatch = useDispatch();
  const [incarnated, setIncarnated] = useState(false)
  // const isIncarnated = useSelector(selectIsIncarnated);

  // const storeName = useSelector(selectStoreName)
  const authState = useSelector(selectAuthState);
  const module = useModule();
  const noHeader = useNoHeaderModules();
  const webshop = useWebshopName()
  // const courseStatus = useSelector(selectStatus)
  const discountCode = useSelector(selectDiscountCode);

  useEffect(() => {
    if (authState.loggedIn) {
      dispatch(fetchStoreCurrency())
      dispatch(fetchUnreadNotifications())
      dispatch(fetchSettings())
    }
  }, [dispatch, authState]);

  useEffect(() => {
    if (module === 'store' || module === 'store_edit') {
      dispatch(setStoreName(webshop))
      dispatch(fetchStore({ store: webshop, discountCode }))
    }
  }, [webshop, discountCode, dispatch, module])

  useEffect(() => {
    const isIncarnated = JSON.parse(localStorage.getItem('user'))?.incarnated
    if (isIncarnated && !incarnated) {
      setIncarnated(true)
      dispatch(setIsIncarnated(true))
    }
  }, [dispatch, incarnated])

  return (
    <div>
      <MenuSlider />
      <IncarnateModal />
      <SendInvite />
      <LoginModal />

      <WarningMessages show={false && !(noHeader || module === 'store')} />
      <HeaderContainer noHeader={noHeader} />
      <ScrollToTop />
      {/* <Cart /> */}
      <ContentWrapp $noHeader={noHeader} >
        {children}
      </ContentWrapp>
      {module === 'store' && <FooterContainer />}
      <ManageCookiesBanner />
    </div>
  );
};

export default WrappContainer;
