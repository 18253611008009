import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsBundle, useSlugName, } from '../../hooks/readFromUrls';

import BuildCourse from './components/BuildCourse'
import ViewCourse from './components/ViewCourse'
import { CoverMedia } from '../../ui';
import { fetchCourse, selectCourseCoverMedia, selectStatus } from './courseSlice';
import { selectStoreName, selectUserTheme } from './globalSlice';
import ViewCoursePackage from './components/ViewCoursePackage';
import { selectIsLoggedIn } from '../auth/authSlice';
import { STATUS_LOADING } from '../../utils/constants';
import Loading from '../../ui/Loading';
import SEO from "../../components/SEO/SEO";

const Course = ({ edit, isPackage }) => {
  // const location = useLocation();
  const dispatch = useDispatch();

  const readerMode = !edit;
  const coverMedia = useSelector(selectCourseCoverMedia);
  const loadCoursesStatus = useSelector(state => state.courses.status);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userTheme = useSelector(selectUserTheme);
  const loadStatus = useSelector(selectStatus)

  const slugName = useSlugName()
  const isBundle = useIsBundle()

  const storeName = useSelector(selectStoreName)
  // const course = useSelector(selectLayout)

  useEffect(() => {
    storeName && slugName && dispatch(fetchCourse({ storeName, slugName, type: isBundle ? 2 : 1 }))
  }, [slugName, storeName, dispatch, isBundle, isLoggedIn]);

  // useEffect(() => {
  //   course.id && dispatch(fetchCourseToc(course.id))
  // }, [course.id, dispatch, isLoggedIn]);

  if (loadCoursesStatus !== 'succeeded') return null

  return (
    loadStatus === STATUS_LOADING ? <Loading /> :
      <>
        <div className="course-main-section-container">
          {readerMode && <CoverMedia
            videoPreview={coverMedia?.video?.url}
            themeColor={userTheme.color}
            thumbnailImage={coverMedia?.image?.url}
          />}
          <div className="mid-section-container">
            {readerMode ? isPackage ? <ViewCoursePackage /> : <ViewCourse /> : <BuildCourse isPackage={isPackage} slug={slugName} />}
          </div>
        </div>
        <SEO title={storeName} image={coverMedia?.image?.url} name={storeName} type={'summary_large_image'} />
      </>
  );
};

export default Course;
