import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro'
import { IconAsButton, Typo, Symbols, theme, Button, Loading } from '../../../ui';
import { useDispatch, useSelector } from 'react-redux';
import { fileUpload, selectFile, selectFileStatus } from '../../fileUploader/fileSlice';
import MediaPicker from '../MediaPicker';
import { useTranslation } from 'react-i18next';

const FileUploadWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  `
const FileUploadCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  cursor: pointer;
  `

const FileLoadingWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
`

const FileSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  position: relative;
  flex: 1;

  button {
    position: absolute;
    z-index: 3;
    background: ${(props) => props.theme.colors.NEUTRAL_0};
    bottom: 10px;
    right: 10px;
  }
`

const FileUploader = ({
  saveAction,
  fileToPreview,
  id,
  type,
  accept,
  fileType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hiddenFile, setHiddenFile] = useState(true);
  const file = useSelector(selectFile);
  const fileStatus = useSelector(selectFileStatus);
  const [libraryModal, setLibraryModal] = useState(false);

  const handleLibraryModal = useCallback((e) => {
    e.stopPropagation();
    setLibraryModal(!libraryModal);
  }, [libraryModal]);

  const chooseFile = () => {
    document.getElementById(id).value = null;
    document.getElementById(id).click();
  };

  useEffect(() => {
    if (fileToPreview) {
      setHiddenFile(false);
    }
  }, [fileToPreview]);

  useEffect(() => {
    if (file) {
      saveAction({ id: file.id, url: file.url, name: file.name });
      dispatch({ type: 'clearFile' });
      setHiddenFile(false);
    }
  }, [file])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function onCompDestroy() {
      saveAction(null);
      dispatch({ type: 'clearFile' });
    }
    onCompDestroy();
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFile = useCallback(e => {
    const selectedFile = e.target.files[0];

    dispatch(fileUpload(selectedFile));
  }, [dispatch, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteFile = useCallback(() => {
    setHiddenFile(true);
    dispatch({ type: 'clearFile' })
  }, [dispatch]);

  const LoadingWrapper = () =>
    <FileLoadingWrapper>
      <Loading />
    </FileLoadingWrapper>;

  const EditFile = () =>
    <FileUploadWrapper>
      <input accept={accept} type='file' style={{ display: 'none' }} id={id} onChange={onSelectFile} />
      <FileUploadCard htmlFor={id} onClick={chooseFile} >
        <Symbols.Upload color={theme.colors.NEUTRAL_60} />
        <Typo.Text $textAlign='center'>{t('mediaLibrary.uploadFiles')}</Typo.Text>
        <Typo.Text $textAlign='center'>{t('mediaLibrary.uploadFromComp')}</Typo.Text>
      </FileUploadCard>
      <Button $asLink onClick={handleLibraryModal}>{t('mediaLibrary.uploadFromMedia')}</Button>
    </FileUploadWrapper>;

  return (
    fileStatus === 'fileLoading' ?
      <LoadingWrapper />
      :
      fileToPreview && !hiddenFile ?
        <FileSectionWrapper>
          <IconAsButton
            iconName='Delete'
            variant='action'
            tooltipLabel={t('globals.delete')}
            color={theme.colors.ACTION}
            clickHandle={deleteFile}
          />
          {fileToPreview.name}
        </FileSectionWrapper>
        :
        <>
          <EditFile />
          <MediaPicker
            saveAction={saveAction}
            mediaType={fileType || 'all'}
            isOpen={libraryModal}
            closeModal={handleLibraryModal}
          />
        </>
  )
};

export default FileUploader;
