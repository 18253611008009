import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuWrapper } from '../../ui';

const Exports = () => {

  const { t } = useTranslation();

  const navigationItems = [
    { label: t('export.courseExport'), 'value': 'course' },
    { label: t('export.userExport'), 'value': 'user' },
    { label: t('export.groupExport'), 'value': 'group' },
    // { label: t('export.sales'), 'value': 'sales' }, - hidden for now
  ]

  return (
    <>
      <SubMenuWrapper>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Exports;
