import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div` 
  position: relative;
  padding: 0 0 0 24px;
  p::before {
    content: " ";
    background: ${({ theme, $color }) => !$color ? theme.colors.PRIMARY : $color};
    padding: 0 6px;
    // margin-right: 1rem;
    position: absolute;
    left: 0;
    top: 6px;
    height: 12px;
    display: grid;
    place-items: center;
    border-radius: 12px;
  }
`

const ListMarker = ({ children, $color }) => {

  return (
    <Wrapper $color={$color}>
      {children}
    </Wrapper >
  );
}

export default ListMarker;
