import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Filter, SearchBar, Page, Typo, Flex } from '../../ui';
import {
  fetchNotifications,
  markNotification,
  selectNotificationFilter,
  selectNotificationsFiltered,
  selectStatus,
  selectUnreadNotifications,
  setNotificationFilter
} from './notificationsSlice';
import styled from 'styled-components';
import { selectIsIncarnated } from '../auth/authSlice';
import NotificationItem from './NotificationItem';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation, Trans } from 'react-i18next';

const NotificationsList = styled.ul`  
  margin: 0 0 2rem 0;
  width: 100%;
  padding: 0;
`;
const ControlsWrapper = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  width: 100%;
  gap:40px;
  margin-bottom:20px;
`;

const SearchWrapper = styled.div`
max-width: 450px;
width: 100%;
`;

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notifications = useSelector(selectNotificationsFiltered);
  const [length, setLength] = useState(25)
  const filters = useSelector(selectNotificationFilter);
  const isIncarnated = useSelector(selectIsIncarnated);
  const [notificationType, setNotificationType] = useState('0');
  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef();
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const notificationsStatus = useSelector(selectStatus)

  const handleSelected = useCallback((item, e) => {
    dispatch(markNotification({ method: item.isRead === 0 ? 'markRead' : 'markUnread', ids: [item.id] })).then(() =>
      dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }))
    )

  }, [dispatch, length, notificationType]);

  const archiveNotifications = useCallback((method) => {
    dispatch(markNotification({ method, markAll: true })).then(() =>
      dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }))
    )
  }, [notificationType, length, dispatch])

  //Filter for notifications
  const handleNotificationType = useCallback((value) => {
    setNotificationType(value)
    dispatch(setNotificationFilter(value))
  }, [dispatch]);

  //Add 25 more items to api call
  const loadMore = useCallback(() => {
    setLength((prevLength) => prevLength + 25)
  }, [setLength]);

  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value)
  }, [setSearchQuery])

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
  }, [setSearchQuery]);

  const filteredItems = useMemo(() => {
    return notifications?.filter((item) => item.message.toLowerCase().search(searchQuery.toLowerCase()) !== -1)
  }, [notifications, searchQuery]);

  useEffect(() => {
    dispatch(fetchNotifications({ types: notificationType, reversed: false, pageLength: length }));
  }, [dispatch, length, isIncarnated, notificationType, unreadNotifications]);

  return (
    <Page staticElements={
      <ControlsWrapper >
        <Flex width='100%' gap='20' justify='space-between' breakPoint={{ size: 'phablet', value: 'flex-direction:column; align-items: flex-end;' }}>
          <SearchWrapper>
            <SearchBar placeholder='Search' handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          {notificationType === '0' && <Button icon='Archive' onClick={() => archiveNotifications('markRead')}>{t('notifications.archiveAll')}</Button>}
          {notificationType === '4' && <Button icon='Archive' onClick={() => archiveNotifications('markUnread')}>{t('notifications.unarchiveAll')}</Button>}
        </Flex>
        <Flex width='100%' direction='column' align='flex-start' gap='20'>
          <Filter info={filters} handleFilter={handleNotificationType} selected={notificationType} />
          <Flex direction='column' align='flex-start' gap='5'>
            <Typo.Info>{t('notifications.archiveForNoEmail')}</Typo.Info>
            <Typo.Info>
              <Trans t={t} i18nKey={'notifications.switch'} components={[<a href='/profile/notifications' />]} />
            </Typo.Info>
          </Flex>
        </Flex>
      </ControlsWrapper >
    }>
      {filteredItems?.length ? (
        <NotificationsList>
          {filteredItems.map((item) =>
            <NotificationItem key={item.id} info={item} selectClick={(e) => handleSelected(item, e)} />
          )}
        </NotificationsList >
      ) : (
        notificationsStatus !== STATUS_LOADING && <Typo.ContentSmallHeader>{t('notifications.noMatch')}</Typo.ContentSmallHeader>
      )}

      { filteredItems?.length >= 25 && <Button onClick={loadMore}>{t('notifications.loadMore')}</Button>}
    </Page >
  );
};

export default Notifications;
