import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectGA } from '../features/webshop/globalSlice';

// window.dataLayer = window.dataLayer || [];
// function gtag() { window.dataLayer.push(arguments); }

export default () => {
  const ga = useSelector(selectGA);

  useEffect(() => {
    if (ga && ga.hasOwnProperty('id')) {
      localStorage.setItem('storeGa', JSON.stringify(ga.id));
      window.gtag('config', ga.id);
    }
  }, [])
}


export const viewItemGa = (data) => {
  const gaTrackingId = JSON.parse(localStorage.getItem('storeGa'))
  console.log(gaTrackingId);
  window.gtag('event', 'view_item', {items: [data], 'send_to': gaTrackingId})
}

 export const checkoutGa = (data) => {
   const gaTrackingId = JSON.parse(localStorage.getItem('storeGa'))
   window.gtag('event', 'begin_checkout', {items: [data], 'send_to': gaTrackingId})
}

 export const addItemGa = (data) => {
   const gaTrackingId = JSON.parse(localStorage.getItem('storeGa'))
   window.gtag('event', 'add_to_cart', {items: [data], 'send_to': gaTrackingId})
}

export const addTransactionGa = (data) => {
  const gaTrackingId = JSON.parse(localStorage.getItem('storeGa'))
  window.gtag('event', 'purchase', { ...data, 'send_to': gaTrackingId })
}

