import React, { useState } from 'react';
import { Page, Typo, PageHeaderLogo, Flex, Figure, VideoPlayer } from '../../ui';
import coursioNext from '../../ui/assets/Coursio_next_white.png'

import HowToUpdateSettings from './HowToUpdateSettings';
import HowToUpdateStore from './HowToUpdateStore';
import storeOwnersEditDemo from './assets/storeOwnersEditDemo.mp4'

const Faq = () => {

  const [expanded, setExpanded] = useState('howToUpdateStore1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page staticElements={<PageHeaderLogo lightBackground />}>
      <Flex direction='column' gap='50'>

        <Typo.PageHeader>Important update for store Owners</Typo.PageHeader>
        <Typo.Text>With the upcoming release, your store will benefit from a fresh, modern design. While these changes are incredibly exciting, it also means that most of your existing information in the store and course description pages will be reset to blank.</Typo.Text>
        <Typo.Text>It's essential that you read the instructions we've provided on how to edit your store and course description pages. This is your chance to update and personalize your content, making sure everything is in line with your brand and vision.</Typo.Text>
        <Figure.Video>
          <VideoPlayer posterImage={coursioNext} previewContent={storeOwnersEditDemo} width="100%" height="auto" />
        </Figure.Video>

        <Flex direction='column'>
          <HowToUpdateStore handleChange={handleChange} expanded={expanded} />
          <HowToUpdateSettings handleChange={handleChange} expanded={expanded} />
        </Flex>
        <Flex direction='column' align='flex-start' width='100%'>
          <Typo.ContentBigHeader $marginBottom='10px'>Time is of the Essence:</Typo.ContentBigHeader>
          <Typo.Text $marginBottom='50px'>We encourage you to log in and make these changes before the release date to ensure your store looks fantastic from day one.</Typo.Text>
        </Flex>

      </Flex>
    </Page>
  );
};

export default Faq;
