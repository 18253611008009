import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components/macro'
import Papa from 'papaparse';
import { Flex, Icon, Modal, theme, Toggler, Typo, Button } from '../../../ui';
import { fetchCoursesByGroupId, fetchEditableCourses, selectCourses } from '../../courses/coursesSlice';
import { addUserToGroup, fetchReaders, fetchUsersByGroupId, selectUsers } from '../../users/usersSlice';
import { fetchEditableGroups, selectGroups, addGroupToCourse } from '../../groups/groupsSlice';
import { selectCourseDurations } from '../../course/courseSlice';
import { selectIsInvitesModalOpen, selectModalProps, sendInvitations, setInviteModalIsOpen } from '../invitesSlice';
import UcpPeriodSelector from '../../../components/UcpPeriodSelector';
import SearchSelect from '../../../ui/SearchSelect';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  padding: 1rem 0;
`

const SendInvite = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [duration, setDuration] = useState({});
  const [contentValid, setContentValid] = useState(false);

  const courseDurations = useSelector(selectCourseDurations);
  const courses = useSelector(selectCourses)
  const users = useSelector(selectUsers)
  const groups = useSelector(selectGroups)
  const isModalOpen = useSelector(selectIsInvitesModalOpen)
  const modalProps = useSelector(selectModalProps)

  const { group, course, select, callbackFunc } = modalProps

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchEditableCourses({}))
      dispatch(fetchEditableGroups({ disableCounts: true }))
      dispatch(fetchReaders())
    }
  }, [dispatch, isModalOpen])

  useEffect(() => {
    let isValid = false
    if (!group && !course)
      isValid = !!duration?.id && !!selectedCourses.length && !!(selectedGroups.length || selectedUsers.length);
    else if (select === 'courses') isValid = !!duration?.id && !!selectedCourses.length
    else isValid = selectedUsers.length

    setContentValid(isValid);
  }, [duration, selectedGroups, selectedCourses, select, selectedUsers, course, group])

  const handleGroupOrUser = useCallback((e) => {
    setIsGroup(e.target.checked)
  }, [])

  const updateLists = useCallback(() => {
    if (callbackFunc === 'UsersByGroupId')
      dispatch(fetchUsersByGroupId({ circleId: group.id }))
    else if (callbackFunc === 'CoursesByGroupId')
      dispatch(fetchCoursesByGroupId({ circleId: group.id }))
  }, [callbackFunc, dispatch, group])

  const clearState = useCallback(() => {
    setSelectedGroups([])
    setSelectedCourses([])
    setSelectedUsers([])
    setIsGroup(false)
    setDuration({})
    setContentValid(false)
  }, [])

  const closeDialog = useCallback(() => {
    dispatch(setInviteModalIsOpen(false))
    clearState();
  }, [dispatch, clearState])

  const sendInvitation = useCallback(() => {
    const expires = duration.id !== 0 ? dayjs().add(duration.id, 'month').format('YYYY-MM-DD') : '0000-00-00';
    const courseIds = selectedCourses?.map((course) => course.id)
    const groupsById = selectedGroups.map((group) => group.id)
    const usersById = selectedUsers.map((user) => user.id).filter(Boolean)
    const emails = selectedUsers.map((user) => user.inputValue || (!user.id && (user.email || user))).filter(Boolean)

    if (group?.id) {
      select === 'users' && usersById.length && dispatch(addUserToGroup({ userId: usersById, circleId: group?.id })).then(() => !!callbackFunc && updateLists())
      select === 'courses' && courseIds.length && dispatch(addGroupToCourse({ ucpPeriod: expires, circleId: group.id, courseIds: courseIds })).then(() => !!callbackFunc && updateLists())
      emails.length && dispatch(sendInvitations({ expires, byWhat: 'emails', withWhat: emails, circleId: group.id })).then(() => !!callbackFunc && updateLists())
    } else {
      const coursesToBeInvited = course?.id ? [course?.id] : courseIds
      groupsById.length && isGroup && dispatch(sendInvitations({ expires, byWhat: 'groups', withWhat: groupsById, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
      usersById.length && !isGroup && dispatch(sendInvitations({ expires, byWhat: 'users', withWhat: usersById, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
      emails.length && dispatch(sendInvitations({ expires, byWhat: 'emails', withWhat: emails, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
    }

    closeDialog()
  }, [duration, selectedGroups, selectedCourses, select, selectedUsers, dispatch, isGroup, closeDialog, course, group, callbackFunc, updateLists])

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      transformHeader: function (header) {
        return header.toLocaleLowerCase()
      },
      complete: function (results) {
        const selectedUsersList = [];
        if (results.data.length) {
          results.data.forEach(user => {
            selectedUsersList.push({
              email: user.email.trim(),
              name: user.hasOwnProperty('name') ? user.name.trim() : user.email.trim()
            })
          })
        } else {
          return;
        }
        setSelectedUsers(selectedUsersList)
      },
    });
  };

  const chooseFile = useCallback(() => {
    document.getElementById('inviteUpload').value = null;
    document.getElementById('inviteUpload').click();
  }, []);

  return (
    <Modal
      title={t('invites.sentTitle')}
      onClose={closeDialog}
      onAccept={sendInvitation}
      showActions
      open={isModalOpen}
      acceptLabel={t('globals.send')}
      acceptIsDisabled={!contentValid}
    >
      <>
        {course?.id &&
          <Wrapper>
            <Flex gap='10'>
              <Icon.Course color={theme.colors.NEUTRAL_60} />
              <Typo.Text>{t('invites.invCourse', { courseName: course.name })}</Typo.Text>
            </Flex>
          </Wrapper>}
        {!!group?.id ?
          <Wrapper>
            <Flex gap='10'>
              <Icon.Groups />
              <Typo.Text>{t('invites.invGroup', { groupName: group.name })}</Typo.Text>
            </Flex>
          </Wrapper>
          : <Wrapper>
            <Typo.Text>{t('invites.invUserOrGroup')}</Typo.Text>
            <Flex width='50%'><Toggler leftText={t('globals.users')} rightText={t('globals.groups')} onClick={handleGroupOrUser} /></Flex>
          </Wrapper>}
        {isGroup ?
          <Wrapper>
            <SearchSelect
              multiple={true}
              setValue={setSelectedGroups}
              selectedOptions={selectedGroups}
              id='groups'
              options={groups.map((item) => ({ name: item.name, id: item.id }))}
              label={t('invites.selectGroups')}
            />
          </Wrapper>
          : select !== 'courses' &&
          <Wrapper>
            <Typo.SmallText>
              {t('invites.addUserIfDoesntExist')}

            </Typo.SmallText>
            <SearchSelect
              creatable
              multiple={true}
              setValue={setSelectedUsers}
              selectedOptions={selectedUsers}
              id='users'
              options={users}
              label={t('invites.addUsers')}
              renderOption={(props, option) => {
                return <li {...props} key={option.id || option}>
                  <div >
                    <Typo.Text >{option.name}</Typo.Text>
                    <Typo.Info >{option.email}</Typo.Info>
                  </div>
                </li>
              }}
            />
            <Typo.Text>{t('invites.invCsv')}</Typo.Text>
            <Typo.SmallText $marginBottom='5px'>{t('invites.csvInstruction')}</Typo.SmallText>
            <Button icon='Upload' onClick={chooseFile}>{t('invites.csvUpload')}</Button>
            <input
              id='inviteUpload'
              type='file'
              name='file'
              accept='.csv'
              onChange={changeHandler}
              style={{ display: 'none' }}
            />
          </Wrapper>
        }
        {(select !== 'users' && !course) && <>
          <Wrapper>
            <Typo.Text>{t('invites.courseSelect')}</Typo.Text>
            <SearchSelect
              multiple={true}
              setValue={setSelectedCourses}
              selectedOptions={selectedCourses}
              id='courses'
              label={t('invites.courseInstruction')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={courses.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
            />
          </Wrapper></>}
        {/* this should be presented with course is present not for ading users to groups */}
        { !(select === 'users' && group?.id) && <Wrapper>
          <UcpPeriodSelector
            setDuration={setDuration}
            duration={duration}
            options={courseDurations}
          />
        </Wrapper>}
      </>
    </Modal>
  );
}

export default SendInvite;
