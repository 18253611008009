import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { priceFormater } from '../../../../helpers/formatNumbers';
import { selectStoreName } from '../../globalSlice';
import { removeItemFromCart, setCartVisible } from '../../cartSlice';
import { Typo, IconAsButton, Flex, Avatar } from '../../../../ui';

const CartItem = ({ info, hideVAT, noDelete }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName);

  const removeItem = useCallback((item) => {
    dispatch(removeItemFromCart(item.id))
  }, [dispatch]);

  const goToCourse = useCallback((item) => {
    navigate(`/store/${storeName}/${item.slug}`);
    dispatch(setCartVisible(false));
  }, [navigate, dispatch, storeName])

  const { price, coverImage, currency, name } = info

  return (
    <Flex direction='column' width='100%' gap='10'>
      <Flex width='100%' justify='flex-start' gap='16'>
        {coverImage && coverImage[0]?.url && <Avatar url={coverImage[0].url} name={name} />}
        {!noDelete ? <Flex justify='space-between' width='100%'>
          <Typo.TextTitle onClick={() => goToCourse(info)}>{name}</Typo.TextTitle>
          <IconAsButton tooltipLabel={t('webshop.cart.remove')} iconName='Delete' size='small' clickHandle={() => removeItem(info)} />
        </Flex>
          : <Typo.TextTitle onClick={() => goToCourse(info)}>{name}</Typo.TextTitle>
        }
      </Flex>
      <Flex width='100%' direction='column' align='flex-end'>
        <Typo.TextTitle>{`${priceFormater(price, hideVAT)} ${currency}`}</Typo.TextTitle>
        {hideVAT && price.gross > 0 && <Flex width='50%' justify='space-between'>
          <Typo.SmallText>{t('globals.vat')} </Typo.SmallText>
          <Typo.SmallText>{(price.gross - price.net).toFixed(2)} {currency}</Typo.SmallText>
        </Flex>
        }
      </Flex>
    </Flex>
  )
};

export default CartItem;
