import React from 'react';
import { SubMenu, SubMenuWrapper } from '../../../ui';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';

const BuildHome = () => {
  const { t } = useTranslation();

  const navigationItems = [
    { label: t('webshop.build.coursesInStore'), value: 'courses' },
    { label: t('globals.discounts'), value: 'discounts' },
    { label: t('webshop.build.storeFrontContent'), value: 'content' },
  ]

  return (
    <>
      <SubMenuWrapper>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={4} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default BuildHome;
