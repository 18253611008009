import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Flex, SearchBar, Typo, Page, ListTable, IconAsButton } from '../../ui';
import { fetchTransactions, resendReceipt, selectTransactions } from './transactionsSlice';
import debounce from '../../helpers/debounce';
import { API_STATUS } from '../../utils/constants';
import { selectIsIncarnated } from '../auth/authSlice';
import { media } from '../../utils/media';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const Controls = styled.div`
  width: 700px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    margin-bottom: 0;
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const Transactions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const searchRef = useRef();
  const isIncarnated = useSelector(selectIsIncarnated);
  const [searchQuery, setSearchQuery] = useState('');
  const [localTransactions, setLocalTransactions] = useState(null);
  const transactions = useSelector(selectTransactions)

  const resend = useCallback((id) => {
    dispatch(resendReceipt(id))
  }, [dispatch])

  const [tableInfo, setTableInfo] = useState([
    { label: t('transactions.buyerName'), hasSorting: { sortedMethod: 'string', propVal: 'buyer' }, data: (item) => item.buyer },
    { label: t('globals.course'), hasSorting: { sortedMethod: 'string', propVal: 'caption' }, data: (item) => item.caption },
    { label: t('transactions.amount'), data: (item) => `${item.amount} ${item.currency}` },
    { label: t('globals.date'), hasSorting: { sortedMethod: 'date', propVal: 'date' }, data: (item) => item.date && item.date.split(' ')[0] },
    // { label: 'where' },
    {
      label: t('globals.status'), hasSorting: { sortedMethod: 'number', propVal: 'status' }, data: (item) => item.status === API_STATUS.STATUS_REFUND
        ? t('transactions.refunded')
        : item.status === API_STATUS.STATUS_PAID || API_STATUS.STATUS_GOOD
          ? t('transactions.paid')
          : item.status === API_STATUS.STATUS_ERROR
            ? t('globals.error')
            : '-'
    },
    { label: t('transactions.resendReceipt'), data: (item) => <IconAsButton iconName='Receipt' tooltipLabel={t('transactions.resendReceipt')} clickHandle={() => resend(item.id)} /> },
  ]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch, isIncarnated])

  useEffect(() => {
    if (transactions?.length) {
      setLocalTransactions(transactions)
    }
  }, [transactions])

  const handleSearch = debounce((e) => {
    setLocalTransactions(transactions.filter((el) => el.buyer.includes(e.target.value)))
    setSearchQuery(e.target.value)
  }, 1000);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
    transactions?.length && setLocalTransactions(transactions);
  }, [setSearchQuery, transactions]);

  return (
    <Page staticElements={
      <Header>
        <Flex justify={'space-between'} align={'flex-start'}>
          <Typo.ContentSmallHeader>{t('transactions.allTransactions')}</Typo.ContentSmallHeader>
          {/* <Count number={localTransactions?.length} /> */}
        </Flex>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('transactions.search')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
        </Controls>
      </Header>
    }>
      {transactions.length ?
        <ListTable
          tableInfo={tableInfo}
          data={localTransactions}
          tableName={'transactions'}
          // resultSetter={setLocalTransactions}
          tableSetter={setTableInfo}
        />
        : <Typo.TextTitle>{t('transactions.noTransactions')}</Typo.TextTitle>}
    </Page>
  );
};

export default Transactions;
