import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro'
import debounce from 'lodash.debounce';
import { FormControlLabel, Checkbox } from '@mui/material';
import {
  selectPrivacyAcceptance,
  setPrivacyAcceptance,
  setPaymentEmail,
  selectPaymentEmail,
} from '../../paymentsSlice';
import { setLoginModal } from '../../../../features/global/globalSlice';
import { Textinput, Typo, Flex } from '../../../../ui';
import emailReg from '../../../../helpers/emailValidation';


const Header = styled(Flex)`
  padding-bottom: 5px;
  span {
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
  }
`;

const NotLoggedInInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const privacyAcceptance = useSelector(selectPrivacyAcceptance);
  const paymentEmail = useSelector(selectPaymentEmail);
  const emailRef = useRef();

  const handleChange = (event) => {
    dispatch(setPrivacyAcceptance(event.target.checked));
  };

  const handleDebouncedEmail = useCallback(debounce(() => {
    const isValidEmail = emailRef.current && emailReg.test(emailRef.current.value)
    isValidEmail && dispatch(setPaymentEmail(emailRef.current.value))
  }, 1500)
    , [dispatch, emailRef]);

  const openLoginModal = useCallback(() => {
    dispatch(setLoginModal(true))
  }, [dispatch]);

  return (
    <>
      <Header justify='space-between'>
        <Typo.TextTitle>{t('webshop.checkout.contactInfo')}</Typo.TextTitle>
        <Typo.Info>{t('webshop.checkout.AlreadyHaveAccount')}<span onClick={openLoginModal}> {t('auth.login')}</span></Typo.Info>
      </Header>
      <div>
        <Textinput
          defaultValue={paymentEmail}
          placeholder='E-mail'
          variant='outlined'
          innerRef={emailRef}
          onChange={handleDebouncedEmail}
        />
        <FormControlLabel
          control={<Checkbox checked={privacyAcceptance} onChange={handleChange} name="checkedA" />}
          label={<Typo.Info>{t('webshop.checkout.agreeWith')} <a href="https://coursio.com/gdpr" target="_blank" rel="noopener noreferrer">{t('webshop.checkout.privacyPolice')}</a></Typo.Info>}
        />
      </div>
    </>
  );
};

export default NotLoggedInInfo;

