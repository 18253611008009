import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import InfoIconSection from '../../../components/InfoIconSection';
import ListCourses from '../../../components/ListCourses';
import CourseContent from '../../../components/CourseContent';
import Teacher from '../../../components/Teacher';
import HighlightedInfo from '../../../components/HighlightedInfo';

import { Section, theme, Typo } from '../../../ui';
import { TOAST_ERROR } from '../../../utils/constants';

import { selectLayout } from '../courseSlice';
import { selectCourses } from '../../webshop/coursesSlice';
import { addItemToCart, selectCartItemsIds } from '../cartSlice';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// import Testimonies from '../../../components/Testimonies';
import CtaButton from './CtaButton';
import ShowTermsAndConditions from './ShowTermsAndConditions';
import ShowAccessTime from './ShowAccessTime';
import { selectStoreName } from '../globalSlice';

const ViewCourse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const course = useSelector(selectLayout)
  const courses = useSelector(selectCourses)
  const cartItems = useSelector(selectCartItemsIds);
  const storeName = useSelector(selectStoreName);


  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item))
      navigate(`/store/${storeName}/checkout`);

    }
  }, [dispatch, cartItems, t]);

  const excludeActive = useCallback(() => courses.filter(item => item.id !== course.id && !!item.public), [courses, course])();

  const {
    infoIcons,
    infoPoints,
    introduction,
    teacher,
    highlightedInfo,
    courseToc,
    // testimonies,
  } = course;

  if (course.status !== 'succeeded' || !course) return null

  return (
    <>
      <Section noMarginBottom>
        <Typo.PageHeader>{introduction?.title}</Typo.PageHeader>
      </Section>
      <Section noMarginBottom>
        {!Object.keys(highlightedInfo).length ? <>
          <ShowTermsAndConditions />
          <ShowAccessTime ucpPeriod={course.ucpPeriod} />
          <CtaButton courseId={course.id} />
        </> :
          <HighlightedInfo info={highlightedInfo} ucpPeriod={course.ucpPeriod} hasBottomMargin infoIcons={infoIcons} courseId={course.id} />
        }
      </Section>
      {!!infoPoints.length && <Section title={t('webshop.course.infoPointsTitle')} align='center' >
        <InfoIconSection items={infoPoints} />
      </Section>}
      <Teacher teacherInfo={teacher} />
      {!!courseToc?.length &&
        <Section variant={theme.colors.NEUTRAL_LIGHT} title={t('webshop.course.courseContentTitle')}><CourseContent tocData={courseToc} /></Section>}
      {(teacher || courseToc) && !!excludeActive.length && <CtaButton courseId={course.id} />}
      {/*{!!testimonies?.length && <Testimonies items={testimonies} />}*/}
      {!!excludeActive.length &&
        <Section title={t('webshop.course.relatedCourses')}>
          <ListCourses showProgress={false} showButtons={true} items={excludeActive} addItem={addItem} />
        </Section>
      }
      <CtaButton courseId={course.id} />
    </>
  );
};

export default ViewCourse;
