import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsIncarnated } from '../../auth/authSlice';
import { useTranslation } from 'react-i18next';
import { fetchReaders, selectUsers, selectStatus, removeUserFromStore } from '../usersSlice';
import debounce from 'lodash.debounce';
import { setInviteModalIsOpen } from '../../invites/invitesSlice';
import UserModal from '../components/UserModal';
import { ListTable, SearchBar, Flex, Typo, SubPage, Button } from '../../../ui';
import { STATUS_LOADING } from '../../../utils/constants';
import Loading from '../../../ui/Loading';
import { media } from '../../../utils/media';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const Controls = styled.div`
  width: 800px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
    margin-bottom: 0;
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;

const UsersList = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const users = useSelector(selectUsers);
  const isIncarnated = useSelector(selectIsIncarnated);
  const loadStatus = useSelector(selectStatus);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortMode, setSortMode] = useState('asc');
  const [userToDelete, setUserToDelete] = useState(null);
  const searchRef = useRef();
  // const [length, setLength] = useState(25)
  const length = 1000
  const navigate = useNavigate();

  const openUser = useCallback((obj) => {
    navigate(`/users/all/${obj.id}`)
  }, [navigate]);

  const handleSearch = debounce((e) => {
    setSearchQuery(e.target.value)
  }, 1000);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
  }, [setSearchQuery]);

  const handleSort = useCallback((val) => {
    setSortBy(val);
    if (sortBy === val) {
      setSortMode('desc')
    }
    if (sortMode === 'desc') {
      setSortMode('asc')
    }
  }, [setSortBy, sortBy, setSortMode, sortMode]);

  //Add 25 more items to api call
  // const loadMore = useCallback(() => {
  //   setLength((prevLength) => prevLength + 25)
  // }, [setLength]);

  useEffect(() => {
    dispatch(fetchReaders({ pageLength: length, searchText: searchQuery, sortBy: sortBy, sortMode: sortMode }));
  }, [dispatch, isIncarnated, searchQuery, sortBy, sortMode, length])

  const openDeleteConfirmation = (item) => {
    setUserToDelete(item)
  }
  const handleDeleteUser = () => {
    dispatch(removeUserFromStore({ userId: userToDelete.id }))
    setUserToDelete(null)
  }

  const tableInfo = [
    { label: t('globals.name'), handleClick: () => handleSort('name'), data: (item) => item.name },
    // { label: 'Role' },
    // { label: 'Last active' },
    { label: t('globals.email'), handleClick: () => handleSort('email'), data: (item) => item.email },
    { label: t('globals.groups'), data: (item) => item.circles?.length },
    { label: t('globals.courses'), data: (item) => item.courses?.length },
    {
      label: '', menuOptions: () => [
        { label: t('globals.userInfo'), onClick: openUser },
        { label: t('globals.deleteUser'), onClick: (item) => openDeleteConfirmation(item) },
      ]
    },
  ]

  const openInviteDialog = () => {
    dispatch(setInviteModalIsOpen(true))
  }

  return (
    <SubPage staticElements={
      <Header>
        <Flex justify={'space-between'} align={'flex-start'}>
          <Typo.ContentSmallHeader>{t('course.allUsers')}</Typo.ContentSmallHeader>
        </Flex>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('course.searchUser')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          <Button icon='Invite' onClick={openInviteDialog}>{t('invites.inviteUser')}</Button>
        </Controls>
      </Header>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={users}
          onRowClick={openUser}
          tableName={'users'}
        />}
      <UserModal open={!!modal} />
      <ConfirmDelete
        onClose={() => setUserToDelete(null)}
        onAcceptDelete={handleDeleteUser}
        open={!!userToDelete}
        description={t('users.removeTotallyFromAccount', { userName: userToDelete?.name })}
      />
    </SubPage>
  );
};

export default UsersList;
