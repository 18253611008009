import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro'
import { Typo, Flex, Icon, theme } from '../../../../ui';
import { selectIsAGift, setIsAGift } from '../../paymentsSlice';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Flex)`
  margin: 1rem 0;   
  border: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  padding: 1rem;
  background-color:${(props) => props.selected && props.theme.colors.NEUTRAL_60};
  cursor:pointer;
  
  p {  margin-left:1rem; }

  span { margin:0; }

  i {
    margin:0.3rem;
    align-self:flex-start;
  }
`

const BuyAsGift = ({ isAGift, afterPayment }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleBuyAsGift = () => {
    dispatch(setIsAGift(!isAGift))
  }

  const imOfferingText = afterPayment ? t('webshop.checkout.imOfferingAfter') : t('webshop.checkout.imOffering')
  const emailMeCodeText = afterPayment ? t('webshop.checkout.emailMeCodeAfter') : t('webshop.checkout.emailMeCode')

  return (
    <Wrapper direction='column' align='left' selected={isAGift} onClick={handleBuyAsGift}>
      <Flex >
        <Icon.Gift color={isAGift ? theme.colors.NEUTRAL_0 : null} />
        <div>
          <Typo.TextTitle color={isAGift ? theme.colors.NEUTRAL_0 : null}>
            {!isAGift ? t('webshop.checkout.iWantToOffer') : imOfferingText}
          </Typo.TextTitle>
          {isAGift && <Typo.Info color={isAGift ? theme.colors.NEUTRAL_0 : null} >{emailMeCodeText}</Typo.Info>}
        </div>
      </Flex>
    </Wrapper>

  );
};

export default BuyAsGift;
