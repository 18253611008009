export const currencyFormatter = (value, options) => {

  const defaultOptions = {
    significantDigits: 0,
    thousandsSeparator: ' ',
    decimalSeparator: '.',
    symbol: 'Kr'
  }
  if (typeof value !== 'number') value = 0.0

  options = { ...defaultOptions, ...options }

  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')

  const number = currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )

  const decimalPart = options.significantDigits !== 0 ? `${options.decimalSeparator}${decimal}` : ''

  return `${number}${decimalPart} ${options.symbol}`
}

export const percentageFormat = (value) => {

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value)

}

export const fractionsFormat = (value) => {

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value)

}

const convertNetPriceToObject = (price) => {
  const gross = price * 1.25
  return { gross, net: price, discounted: gross }
}

export const priceFormater = (price, noVAT) => {

  const priceAsObject = !price.hasOwnProperty('gross') ? convertNetPriceToObject(price) : price
  const priceToUse = noVAT ? priceAsObject?.net : priceAsObject?.gross
  return priceToUse % 1 !== 0 ? priceToUse?.toFixed(2) : priceToUse
}

