import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect, Flex, Typo } from '../../ui';

const BuildSuggestedCourse = ({
  allCourses,
  setSuggested,
  suggestedLocal
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.suggestedCourseTitle')}</Typo.ContentSmallHeader>
      <SearchSelect
        id="promote-course"
        options={allCourses}
        selectedOptions={suggestedLocal}
        label={t('globals.courses')}
        setValue={setSuggested}
      />
    </Flex>
  );
};

export default BuildSuggestedCourse;
