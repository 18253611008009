import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { IconAsButton, Typo, Flex, ListTable, Page, SearchBar, Icon, theme, Button } from '../../ui';

import { selectIsIncarnated } from '../auth/authSlice';
import { fetchInvitations, fetchInviteById, selectInvitations, setInviteModalIsOpen, resendInvite, selectStatus, deleteInvite } from './invitesSlice';
import { useNavigate, useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack'
import { STATUS_LOADING, TOAST_SUCCESS } from '../../utils/constants';
import InviteDetails from './components/InviteDetails';
import Loading from '../../ui/Loading';
import { useTranslation } from 'react-i18next';
import { media } from '../../utils/media';
import ConfirmDelete from '../../ui/Modal/ConfirmDelete';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const Controls = styled.div`
  width: 800px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
    margin-bottom: 0;
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const Invites = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { inviteId } = useParams();

  const isIncarnated = useSelector(selectIsIncarnated);
  const invitations = useSelector(selectInvitations);
  const loadStatus = useSelector(selectStatus);
  const searchRef = useRef();

  const [searchQuery, setSearchQuery] = useState('');
  const [invitationToDelete, setInvitationToDelete] = useState(null);

  // TODO:
  // add sorting (by email and by status?) ?? we dont have sort in server so ?
  // filter by group and course
  // resend all invites or just selected ones


  useEffect(() => {
    if (inviteId) {
      dispatch(fetchInviteById({ id: inviteId }))
    }
  }, [dispatch, inviteId]);

  const resendInviteHandler = useCallback((e, invite) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(resendInvite(invite.id))
  }, [dispatch])

  const handleDeleteInvitation = useCallback(() => {
    dispatch(deleteInvite(invitationToDelete.id)).then(() => setInvitationToDelete(null))
  }, [dispatch, invitationToDelete])

  const copyLink = useCallback((e, invite) => {
    e.preventDefault()
    e.stopPropagation()

    navigator.clipboard.writeText(invite.url)
    enqueueSnackbar(t('invites.linkCopied'), { variant: TOAST_SUCCESS })
  }, [t])

  const tableInfo = [
    { label: t('globals.email'), data: (item) => item.email },
    // when user click in course details objects get updated and course comes as object
    { label: t('globals.course'), data: (item) => item.course?.hasOwnProperty('name') ? item.course.name : item.course },
    {
      label: t('invites.sentDate'), data: (item) => !!item.flags.includes('sent') ?
        <Typo.Info $textAlign='center'>{item.sent}</Typo.Info>
        : '-'
    },
    {
      label: t('invites.inviteLink'), data: (item) => <Flex justify='center'>
        {!item.hasExpired ?
          <Flex justify='space-between' width='70px'>
            <Icon.Valid size='small' color={theme.colors.SUCCESS_GREEN} />
            <IconAsButton iconName='Copy' tooltipLabel={t('invites.copyLink')} size='small' clickHandle={(e) => copyLink(e, item)} />
          </Flex>
          : <Flex justify='left' width='70px'>
            <Icon.NotValid size='small' />
          </Flex>}

      </Flex>
    },
    {
      label: t('invites.resendInvitation'), data: (item) =>
        <IconAsButton iconName='ByInvitation' tooltipLabel={t('invites.resendInvitation')} size='small' clickHandle={(e) => {
          resendInviteHandler(e, item)
        }} />
    },
    {
      label: t('globals.delete'), data: (item) =>
        <IconAsButton iconName='Delete' tooltipLabel={t('globals.delete')} size='small' clickHandle={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setInvitationToDelete(item)
        }} />
    },
  ];

  const handleSearch = debounce((e) => {
    setSearchQuery(e.target.value)
  }, 1000);

  const openDialog = useCallback(() => {
    dispatch(setInviteModalIsOpen(true))
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchInvitations({ searchText: searchQuery }));
  }, [dispatch, isIncarnated, searchQuery])

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
  }, [setSearchQuery]);

  const openInvite = useCallback((obj) => {
    navigate(`${obj.id}`)
  }, [navigate]);

  return (
    <Page gap={30} staticElements={
      <Header>
        <Flex justify={'space-between'} align={'flex-start'}>
          <Typo.ContentSmallHeader>{t('invites.pendingInvitations')}</Typo.ContentSmallHeader>
        </Flex>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={t('invites.searchPlaceholder')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper>
          <Button icon='Invite' onClick={openDialog}>{t('invites.inviteUser')}</Button>
        </Controls>
      </Header>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <>
          <ListTable
            tableInfo={tableInfo}
            data={invitations.toReversed()}
            onRowClick={openInvite}
            tableName={'invitations'}
          />
          <InviteDetails
            resendInviteHandler={resendInviteHandler}
            copyLink={copyLink}
            inviteId={inviteId}
          />
        </>}
      <ConfirmDelete
        onClose={setInvitationToDelete}
        onAcceptDelete={handleDeleteInvitation}
        open={!!invitationToDelete?.id}
        description={t('invites.confirmDeleteInvitation', { userEmail: invitationToDelete?.email })}
      />
    </Page>
  );
}

export default Invites;
