import React from 'react';
import styled from 'styled-components';
import Typo from '../Typo';

const TogglerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;  
  width: 100%;
  background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT};
  padding: 10px;
  border-radius: 50px;
  position: relative;

  p {
    position: relative;
    z-index: 99;
    pointer-events: none;
    width: 50%;
    text-align: center;
  }
`;

const Switch = styled.label`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50px;
  overflow: hidden;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span:before {
    transform: translate(calc(100% - 10px), -50%);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .25s linear;
  
  &:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 80%;
    left: 5px;
    top: 50%;
    background-color: ${(props) => props.theme.colors.NEUTRAL_20};
    border-radius: 50px;
    transform: translateY(-50%);
    transition: all .15s cubic-bezier(1, 0.06, 0.58, 1.2);
  }
`;

//Variant = (text or button)
const Toggler = ({ onClick, leftText, rightText, label, checked, innerRef }) => {

  return (
    <>
      {label && <Typo.Text>{label}</Typo.Text>}
      <TogglerContainer>
        <Typo.Info>{leftText}</Typo.Info>
        <Switch>
          <Checkbox ref={innerRef} onChange={onClick} checked={checked} />
          <Slider />
        </Switch>
        <Typo.Info>{rightText}</Typo.Info>
      </TogglerContainer>
    </>
  )
}

Toggler.displayName = 'Toggler';

export default Toggler;
