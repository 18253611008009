import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro'
import theme from '../theme';

const Progress = styled(CircularProgress)`
  svg {
    color:${theme.colors.PRIMARY}
  }
  position: ${props => props.$type && 'absolute'};
  top: ${props => props.$type && `50%`};
  z-index: ${props => props.$type && `1000`};;
`

const Container = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
  align-items:center;
`

const Background = styled.div`
  position: absolute;
  background: ${theme.colors.NEUTRAL_60};
  opacity: 0.5;
  z-index: 999;
  min-height: calc(100% + 100px);
  width: 100%;
  top: -100px;
`

const Percentages = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  color: ${theme.colors.PRIMARY};
  font-size: 0.75rem;
`

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 7;
`

const components = {
  fullscreen: FullScreen,
  empty: React.Fragment,
}

export default function Loading(prop) {
  const IsFullScreen = prop.fullscreen ? components.fullscreen : components.empty;

  return (
    <Container>
      <IsFullScreen>
        {prop.type === 'page' && <Background />}
        <Progress $type={prop.type} />
        {prop.loadingType === 'withProgress' && <Percentages>{prop.value}%</Percentages>}
      </IsFullScreen>
    </Container>
  );
}
