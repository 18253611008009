import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SearchBar, Typo, Flex, ListTable, SubPage } from '../../ui';
import { selectIsIncarnated } from '../auth/authSlice';
import debounce from '../../helpers/debounce';
import DiscountEdit from './components/DiscountEdit';
import { createDiscount, deleteDiscount, fetchDiscounts, selectDiscounts, updateDiscount } from './discountsSlice';
import { Checkbox } from '@mui/material';
import { selectCurrency } from '../global/globalSlice';
import { media } from '../../utils/media';

const Controls = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;

const Discounts = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const discountsInfo = useSelector(selectDiscounts);
  const isIncarnated = useSelector(selectIsIncarnated);
  const currency = useSelector(selectCurrency)
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortMode, setSortMode] = useState('asc');
  const searchRef = useRef();
  // const [length, setLength] = useState(25)
  const length = 25
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);

  // const handleSearch = debounce((e) => {
  //   setSearchQuery(e.target.value)
  // }, 1000);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchRef.current.value = '';
  }, [setSearchQuery]);

  const handleSort = useCallback((val) => {
    setSortBy(val);
    if (sortBy === val) {
      setSortMode('desc')
    }
    if (sortMode === 'desc') {
      setSortMode('asc')
    }
  }, [setSortBy, sortBy, setSortMode, sortMode]);

  //Add 25 more items to api call
  // const loadMore = useCallback(() => {
  //   setLength((prevLength) => prevLength + 25)
  // }, [setLength]);

  useEffect(() => {
    dispatch(fetchDiscounts({ pageLength: length }));
  }, [dispatch, isIncarnated, searchQuery, sortBy, sortMode, length])

  const tableInfo = [
    { label: t('globals.name'), handleClick: () => handleSort('code'), data: (item) => item.code },
    { label: t('settings.value'), data: (item) => `${item.discount} ${item.type === 2 ? currency : '%'}` },
    { label: t('settings.limitedUse'), data: (item) => `${item.count} of ${!!item.maxCount && item.maxCount > 0 ? item.maxCount : 'unlimited'}` },
    { label: t('globals.startDate'), data: (item) => item.startDate === '0000-00-00 00:00:00' ? null : item.startDate },
    { label: t('globals.endDate'), data: (item) => item.endDate === '0000-00-00 00:00:00' ? null : item.endDate },
    {
      label: t('settings.active'), data: (item) => <Checkbox
        edge="start"
        checked={item.active}
        disableRipple
        inputProps={{ 'aria-labelledby': item.id }}
      />
    },
    {
      label: '', menuOptions: () => [
        { label: t('globals.details'), onClick: (item) => openDiscountDialog(item, 'edit') },
        { label: t('globals.changeActive'), onClick: (item) => changeActiveDiscount(item) },
        { label: t('globals.delete'), onClick: (item) => dispatch(deleteDiscount(item.id)) },
      ]
    },
  ]

  const onSaveDiscount = useCallback((discountData, isCreate) => {
    if (isCreate) {
      dispatch(createDiscount({ discountData }))
    } else {
      dispatch(updateDiscount({ discountData }))
    }
  }, [dispatch]);

  const changeActiveDiscount = useCallback((discount) => {
    const discountData = { ...discount, active: !discount.active };

    dispatch(updateDiscount({ discountData }));
  }, [dispatch])

  const openDiscountDialog = useCallback((item, type) => {
    setIsModalOpen(true);
    if (type === 'create') {
      return;
    }
    setSelectedDiscount(item.id);
  }, [])

  const closeDiscountDialog = useCallback(() => {
    setIsModalOpen(false);
    setSelectedDiscount(null)
  }, [])

  return (
    <SubPage>
      <Flex width='100%' direction='column' gap='20'>
        <Flex width='100%' justify={'space-between'} align={'flex-start'}>
          <Typo.ContentSmallHeader>{t('settings.allDiscounts')}</Typo.ContentSmallHeader>
        </Flex>
        <Controls>
          {/* <SearchWrapper>
            <SearchBar placeholder={t('globals.search')} handleSearch={handleSearch} handleClear={clearSearch} disableClear={!searchQuery} inputRef={searchRef} />
          </SearchWrapper> */}
          <Button icon='Edit' onClick={(e) => openDiscountDialog(e, 'create')}>{t('settings.newDiscountCode')}</Button>
        </Controls>
        <ListTable
          tableInfo={tableInfo}
          data={discountsInfo}
          tableName={'discounts'}
          onRowClick={openDiscountDialog}
        />
        <DiscountEdit open={isModalOpen} discountId={selectedDiscount} closeDialog={closeDiscountDialog} onSave={onSaveDiscount} />
      </ Flex>
    </SubPage>
  );
};

export default Discounts;
