import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStore, selectDiscountCode, selectStoreName } from '../../globalSlice';
import styled from 'styled-components/macro'
import { Button, Textinput, Typo } from '../../../../ui';
import theme from '../../../../ui/theme';
import { useTranslation } from 'react-i18next';
import { resetDiscountValidation, selectIsCodeValid, validateDiscount } from "../../../discounts/discountsSlice";

const DiscountWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 2rem;

  &.error {
    fieldset {
      border-color: ${(props) => props.theme.colors.ACTION};
    } 
  }

  div, button {
    width: 100%;
  }
`;

const Discount = ({ clearDiscount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeName = useSelector(selectStoreName);
  const discountCode = useSelector(selectDiscountCode);
  const isValidDiscount = useSelector(selectIsCodeValid);
  const [typedDiscount, setCurrentDiscount] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isValidDiscount && discountCode === typedDiscount) setCurrentDiscount('');
  }, [isValidDiscount, typedDiscount, discountCode])

  const setDiscount = useCallback((e) => {
    e.preventDefault();
    dispatch(validateDiscount(typedDiscount)).then(res => {
      if (res.payload) {
        dispatch(fetchStore({ store: storeName, discountCode: typedDiscount }));
      }
    })
  }, [dispatch, storeName, typedDiscount]);

  const isDiscountError = !isValidDiscount && !!typedDiscount?.length;

  const handleDiscountChange = useCallback((e) => {
    setCurrentDiscount(e.target.value);
    setDisabled(!e.target.value);
    if (isValidDiscount) {
      dispatch(resetDiscountValidation());
    }
  }, [dispatch, isValidDiscount]);

  useEffect(() => {
    if (typedDiscount.length === 0 && isDiscountError) clearDiscount();
  }, [typedDiscount, clearDiscount, isDiscountError])

  return (
    <DiscountWrapper className={isDiscountError ? 'error' : ''} onSubmit={setDiscount}>
      {isDiscountError && <Typo.Info color={theme.colors.Error}>{t('webshop.checkout.invalidDiscount')}</Typo.Info>}
      <Textinput
        placeholder={'Discount Code'}
        onChange={handleDiscountChange}
        value={typedDiscount}
      />
      <Button $variant='primary' disabled={disabled}>{t('webshop.checkout.activateCode')}</Button>
    </DiscountWrapper>
  )
};

export default Discount;
