import React, { useCallback } from 'react';
import { Checkbox } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PillTag from '../Pilltag';
import Typo from '../Typo';
import Textinput from '../Textinput';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../InfoIconCard/InfoIcon';
import Flex from '../Flex';

const filter = createFilterOptions();

const SearchSelect = ({ multiple = false, dontCloseOnSelect = false, ownIcon, creatable, setValue, selectedOptions, id, options, label, optionLabel = 'name', translationLabel, ...props }) => {
  const { t } = useTranslation();

  const translateItOrNot = (stringToTranslate) =>
    translationLabel ? t(`${translationLabel}.${stringToTranslate}`) : stringToTranslate;

  const handleChange = (newValue) => {
    setValue(newValue)
  };

  const onInputChange = useCallback((e) => {
    if (multiple && id === 'users') {
      const inputValue = e.target.value;
      if (inputValue.includes(',')) {
        const result = inputValue.split(',')[0];
        setValue([...selectedOptions, { inputValue: result, name: result, email: result }])
      }
    }
  }, [selectedOptions, id, multiple, setValue])

  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      disableCloseOnSelect={multiple}
      onChange={(e, newValue) => handleChange(newValue)}
      isOptionEqualToValue={(option, value) => value && option[optionLabel] === value[optionLabel]}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (creatable) {
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `${t('globals.add')} "${params.inputValue}"`,
              email: params.inputValue,
            });
          }
        }
        return filtered;
      }}
      value={selectedOptions || []}
      id={id}
      options={options}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') return translateItOrNot(option);
        if (option.inputValue) return translateItOrNot(option.inputValue);
        return option && translateItOrNot(option[optionLabel]);
      }}
      selectOnFocus
      clearOnBlur
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <PillTag label={option[optionLabel]} {...getTagProps({ index })} key={option.id || `pill-${id}-${index}`} />
        ))
      }
      renderOption={(props, option, { selected }) => {
        return <li {...props} key={option.id || option}>
          {
            multiple ? <>
              <Checkbox edge="start" checked={selected} disableRipple size="small" />
              <Typo.Text>{option[optionLabel]}</Typo.Text>
            </>
              : ownIcon ? <Flex>
                <InfoIcon size="medium" iconName={option.iconName} />
                <Typo.Text >{translateItOrNot(option[optionLabel])}</Typo.Text>
              </Flex>
                : <>
                  <Typo.Text >{translateItOrNot(option[optionLabel] || option)}</Typo.Text>
                </>
          }
        </li>
      }}
      renderInput={(params) =>
        <Textinput onChange={onInputChange} {...params} label={label} />}
      {...props}
    />
  );
};

export default SearchSelect;
